import { RowClassParams } from "@ag-grid-community/core";
import { AxiosResponse } from "axios";
import jslinq from "jslinq";
import jwt_decode from "jwt-decode";
import { cloneDeep, isEmpty, isEqual, uniq } from "lodash";
import moment, { Moment } from "moment";
import { v4 as uuidv4 } from "uuid";

import { ApiRequest } from "API";
import ApiUrl from "ApiUrl";
import { Resolution } from "Constants/actions";
import { SignPDF } from "Constants/agent_functions";
import { PREVIOUS_PATHS, Space } from "Constants/common";
import { ADDITIONAL_ROW_COUNT_IN_TABLES, DEFAULT_ROW_COUNT_IN_TABLES } from "Constants/environments";
import { ALL_DATE_CHILD_FORMATS, All_FILTER_FIELDS_KEYS } from "Constants/filterFieldKeys";
import { DottedDateFormat } from "Constants/settings";
import { EnumIdentityRoleType } from "Enums";
import i18n from "I18n";
import quickRouteLinks from "LocaleData/quickRouteLinks";
import {
  TMDocumentCreationEnum,
  TMDocumentStatusEnum,
  TMDocumentTypeEnum,
  TMEnumStaffingTableStatus,
  TMGroupMemberTypeEnum,
  TMMemberTypeEnum,
  TMMessageStatusEnum,
  TMStateEnum,
} from "Models/Enums";
import { TMDepartment, TMEnvironment } from "Models/index";
import { TDocumentMembers, TMBaseDocument, TMDocumentWithFileViewModel } from "Models/ModelBaseDocument";
import { TTranslations, TUsersWithPermissionToViewAccessToFile } from "Models/ModelCommon";
import { TMResolution } from "Models/ModelResolution";
import { TMDictionaryItem } from "Models/NetworkModels/TMDictionaryService";
import { TMDocumentMember, TMEnumRequestStatus } from "Models/NetworkModels/TMDocumentService";
import { TMFile } from "Models/NetworkModels/TMFileService";
import { closeConfirmationModal, setConfirmationModal } from "Redux/global/globalSlice";
import { setLoading } from "Redux/settings/settingsSlice";
import { store } from "Redux/store";
import { Modify, TFieldNames, TLocale, TQuickRouteLink, TQuickRouteLinkSearch, TStaffRoleAction } from "Types";

import LocaleSystemConfig, { LANGUAGE_RU } from "../config/settings-config";

export function PromiseWithTimeout<T>(timeout: number, executor: (resolve: (value: T) => void, reject: (error: any) => void) => void): Promise<T> {
  return new Promise((resolve, reject) => {
    // Set up the timeout
    const timer = setTimeout(() => {
      reject(`Promise timed out after ${timeout} ms`);
    }, timeout);

    // Set up the real work
    executor(
      (value: T) => {
        clearTimeout(timer);
        resolve(value);
      },
      (error: any) => {
        clearTimeout(timer);
        reject(error);
      }
    );
  });
}

export function arrayToTree(array: any[] = [], id = "id", parentId = "parentId", children = "children") {
  const result: any[] = [];
  const hash: any = {};
  array.forEach((item: any, index: number) => {
    hash[array[index][id]] = array[index];
  });

  array.forEach((item: any) => {
    const hashVP = hash[item[parentId]];
    if (hashVP) {
      !hashVP[children] && (hashVP[children] = []);
      hashVP[children].push(item);
    } else {
      result.push(item);
    }
  });
  return result;
}

export function arrayToFloat(data: any[], result: any[], key = "children") {
  data?.map((l) => {
    result.push(l);
    if (l[key]?.length > 0) {
      arrayToFloat(l[key], result, key);
    }
    l[key] = [];
  });
  return result;
}

export const getRowDocumentClass = (params: RowClassParams) => {
  let rowClass = "";
  params?.data?.readDate === null && (rowClass += "row-item-not-read");
  return rowClass;
};

export function firstLower(str: string) {
  return str.replace(/(?:^|\s)\S/g, function (a) {
    return a.toLowerCase();
  });
}

export function findObjectIndexInArray(arr: any[], key: string, value: any): number {
  for (let index = 0; index < arr?.length; index++) {
    if (arr[index][key] === value) {
      return index;
    }
  }
  return 0;
}

export function checkToCaseButtonForView(document: TMDocumentWithFileViewModel, resolution?: TMResolution, ownResolutions?: TMResolution[]) {
  const { user } = store.getState();

  function checkForResponsibleMember() {
    if (document?.status == TMDocumentStatusEnum.Done)
      if (resolution)
        return resolution?.blocks?.some((l) =>
          l?.controlPoints?.some(
            (c) =>
              c.status === TMMessageStatusEnum.Done &&
              c?.members?.some(
                (m) =>
                  m?.staffId === user?.userInfo?.staffId && m.type === TMMemberTypeEnum.Performer && m.isResponsible && m.isLastResponsiblePerformer
              )
          )
        );
      else if (ownResolutions) {
        return ownResolutions?.some((i) =>
          i?.blocks?.some((l) =>
            l?.controlPoints?.some(
              (c) =>
                c.status === TMMessageStatusEnum.Done &&
                c?.members?.some(
                  (m: any) =>
                    m?.staffId === user?.userInfo?.staffId && m.type === TMMemberTypeEnum.Performer && m.isResponsible && m.isLastResponsiblePerformer
                )
            )
          )
        );
      }
    return false;
  }

  if (document?.creationType === TMDocumentCreationEnum?.InsideSystem) {
    if (document?.type === TMDocumentTypeEnum.Outgoing)
      return (
        document?.status === TMDocumentStatusEnum.Created &&
        document?.members?.some((i) => i.type == TMMemberTypeEnum.Author && i.staffId === user?.userInfo?.staffId)
      );
    if (document.type === TMDocumentTypeEnum?.Inner || document.type === TMDocumentTypeEnum?.Regulatory) return checkForResponsibleMember();
  }
  if (
    document?.type === TMDocumentTypeEnum?.Inner ||
    document?.type === TMDocumentTypeEnum?.Incoming ||
    document?.type === TMDocumentTypeEnum?.Complaint
  ) {
    return checkForResponsibleMember();
  }
  return false;
}

export function mergeLanguage(locales: TLocale[], languages: TMDictionaryItem[]) {
  if (languages?.length && locales?.length) {
    const localeCodesSet: Set<string> = new Set(locales?.map((localeItem) => localeItem.code));
    return languages.filter((langItem) => localeCodesSet.has(langItem.identifier));
  }
  return [];
}

export function letterConvertToLatin(word: any) {
  let answer = "";
  const key: any = {};
  key["A"] = "a";
  key["B"] = "b";
  key["C"] = "c";
  key["D"] = "d";
  key["E"] = "e";
  key["F"] = "f";
  key["G"] = "g";
  key["H"] = "h";
  key["I"] = "i";
  key["J"] = "j";
  key["K"] = "k";
  key["L"] = "l";
  key["M"] = "m";
  key["N"] = "n";
  key["O"] = "o";
  key["P"] = "p";
  key["Q"] = "q";
  key["R"] = "r";
  key["S"] = "s";
  key["T"] = "t";
  key["U"] = "u";
  key["V"] = "v";
  key["W"] = "w";
  key["X"] = "x";
  key["Y"] = "y";
  key["Z"] = "z";
  key["a"] = "a";
  key["b"] = "b";
  key["c"] = "c";
  key["d"] = "d";
  key["e"] = "e";
  key["f"] = "f";
  key["g"] = "g";
  key["h"] = "h";
  key["i"] = "i";
  key["j"] = "j";
  key["k"] = "k";
  key["l"] = "l";
  key["m"] = "m";
  key["n"] = "n";
  key["o"] = "o";
  key["p"] = "p";
  key["q"] = "q";
  key["r"] = "r";
  key["s"] = "s";
  key["t"] = "t";
  key["u"] = "u";
  key["v"] = "v";
  key["w"] = "w";
  key["x"] = "x";
  key["y"] = "y";
  key["z"] = "z";
  key["Й"] = "i";
  key["Ц"] = "ts";
  key["У"] = "u";
  key["К"] = "k";
  key["Е"] = "e";
  key["Н"] = "n";
  key["Г"] = "g";
  key["Ш"] = "sh";
  key["Щ"] = "sch";
  key["З"] = "z";
  key["Х"] = "h";
  key["ё"] = "yo";
  key["й"] = "i";
  key["ц"] = "ts";
  key["у"] = "u";
  key["к"] = "k";
  key["е"] = "e";
  key["н"] = "n";
  key["г"] = "g";
  key["ш"] = "sh";
  key["щ"] = "sch";
  key["з"] = "z";
  key["х"] = "h";
  key["Ф"] = "f";
  key["Ы"] = "i";
  key["В"] = "v";
  key["А"] = "a";
  key["П"] = "p";
  key["Р"] = "r";
  key["О"] = "o";
  key["Л"] = "l";
  key["Д"] = "d";
  key["Ж"] = "zh";
  key["Э"] = "e";
  key["ф"] = "f";
  key["ы"] = "i";
  key["в"] = "v";
  key["а"] = "a";
  key["п"] = "p";
  key["р"] = "r";
  key["о"] = "o";
  key["л"] = "l";
  key["д"] = "d";
  key["ж"] = "zh";
  key["э"] = "e";
  key["Я"] = "ya";
  key["Ч"] = "ch";
  key["С"] = "s";
  key["М"] = "m";
  key["И"] = "i";
  key["Т"] = "t";
  key["Б"] = "b";
  key["Ю"] = "yu";
  key["я"] = "ya";
  key["ч"] = "ch";
  key["с"] = "s";
  key["м"] = "m";
  key["и"] = "i";
  key["т"] = "t";
  key["б"] = "b";
  key["ю"] = "yu";
  key["-"] = "-";
  key["_"] = "_";
  key[" "] = "_";
  key["0"] = "0";
  key["1"] = "1";
  key["2"] = "2";
  key["3"] = "3";
  key["4"] = "4";
  key["5"] = "5";
  key["6"] = "6";
  key["7"] = "7";
  key["8"] = "8";
  key["9"] = "9";
  for (const index in word) {
    const i = parseInt(index);
    if (Object.prototype.hasOwnProperty.call(word, i)) {
      if (key[word[i]] !== undefined) {
        if (word[i] === "-" && i !== 0 && i + 1 !== word.length && word[i - 1] !== "-" && word[i - 1] !== " ") {
          answer += key[word[i]];
        }
        if (word[i] === " " && i !== 0 && i + 1 !== word.length && word[i - 1] !== "-" && word[i - 1] !== " ") {
          answer += key[word[i]];
        }
        if (word[i] !== "-" && word[i] !== " ") {
          answer += key[word[i]];
        }
      }
    }
  }
  return answer;
}

export function onSelectedKey(list: any[], key: string) {
  if (!list?.length) return [];
  const queryObj: any = jslinq(list);
  return queryObj
    .select(function (el: any) {
      return el[key];
    })
    .toList();
}

export function dateFormat(date: string, dateFormat: string = DottedDateFormat) {
  if (!date) return null;
  return moment(new Date(date), dateFormat);
}

export const saveToLocalStorage = (key: string, value: any) => {
  try {
    const serializedState = JSON.stringify(value);
    localStorage.setItem(key, serializedState);
  } catch {
    //
  }
};

export function loadFromLocalStorage(key: string) {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
}

export const CanDo = (menuIdentifier = "germes", actionIdentifiers: string[], some = true) => {
  const { user } = store.getState();
  const actions: TStaffRoleAction[] = user?.userInfo?.staffRoleActions || [];
  //actionIdentifiers.includes(Resolution) bu shart vaqtincha hali product teamdan actionlar berilmaganligi uchun vaqtincha yozib qo'yildi.
  if (actionIdentifiers.includes(Resolution)) {
    return true;
  }
  if (some) {
    return actionIdentifiers?.some((s: string) => actions.some((i) => i.menuIdentifier == menuIdentifier && i.actionIdentifier == s && i.value));
  } else {
    return actionIdentifiers?.every((s: string) => actions.some((i) => i.menuIdentifier == menuIdentifier && i.actionIdentifier == s && i.value));
  }
};

export const getEnvironmentValue = (environments: TMEnvironment[], identifier: string): any => {
  if (environments?.length) {
    for (let i = 0; i < environments?.length; i++) {
      if (environments[i].identifier === identifier) return environments[i].environmentValue;
    }
  }
  if (identifier === DEFAULT_ROW_COUNT_IN_TABLES) {
    return 20;
  }
  if (identifier === ADDITIONAL_ROW_COUNT_IN_TABLES) {
    return 0;
  }
  return undefined;
};

export function onlyNumber(value: string) {
  return value.replace(/[^0-9]+/g, "");
}

export function getCookies(key: string) {
  const allCookies = decodeURIComponent(document.cookie).split(";");
  return allCookies.some((item) => item.trim().startsWith(`${key}=`));
}

export function onSetCookies(key: string, dayCount = 1) {
  const d = new Date();
  d.setTime(d.getTime() + dayCount * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = key + "=" + true + ";" + expires;
}

export const disablerDateAfterToday = (current: Moment) => {
  const today = new Date();
  return moment(current).isAfter(today);
};

export const disablerDateAfterGivenDay = (current: Moment, start: Date) => {
  if (!start) {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    return moment(current).isBefore(today);
  }
  return moment(current).isBefore(start);
};

export const disablerDateTodayAndBeforeToday = (current: any) => {
  const today = new Date();
  return moment(current).isSameOrBefore(today);
};

export const disablerDateBeforeToday = (current: Moment) => {
  const today = new Date();
  today.setDate(today.getDate() - 1);
  return moment(current).isBefore(today);
};

export const disablerDateBeforeDay = (current: Moment, start: Date) => {
  return moment(current).isBefore(start);
};

export const checkFilterValuesToAll = (filters: any, keys: string[]) => {
  keys.map((key) => filters[key] === "all" && (filters[key] = null));
  return filters;
};

export const getFileSize = (size: number) => {
  // size comes in bytes
  return size < 1000000 ? `${parseFloat(String(size / 1024)).toFixed(1)} KB` : `${parseFloat(String(size / 1024 / 1024)).toFixed(2)} MB`;
};

export const jwtTokenDecrypt = (token: string): any => {
  if (!token) return undefined;
  return jwt_decode(token);
};

export const getX500Val = (s: any, f: string) => {
  try {
    const res = s.splitKeep(/,[A-Za-z]+=/g, true);
    for (const i in res) {
      const n = res[i].search(f + "=");
      if (n !== -1) return res[i].slice(n + f.length + 1);
    }
  } catch (ex) {
    console.log(ex);
  }
  return "";
};

export const getTokenFromUrl = () => {
  const queries = window.location?.search;
  const url = new URLSearchParams(queries);
  if (url.has("token")) {
    return url.get("token");
  }
  return "";
};

export const getQueryString = (key: string) => {
  const queries = window.location?.search;
  const url = new URLSearchParams(queries);
  if (url.has(key)) {
    return url.get(key);
  }
  return "";
};

export const clearHtmlCodeFormText = (htmlCode: string = "<p></p>") => {
  return htmlCode
    ?.replace(/<\/?[^>]+(>|$)/g, "")
    .replace(/\s\s*/g, " ")
    .trim();
};

export const closeSelectDropdownWhenParentScroll = () => {
  const elements: any = document.getElementsByClassName("ant-select-selection-search-input");
  for (const item of elements) {
    item.blur();
  }
};

export const sortData = (data: Modify<TMDepartment[], any[]>) => {
  if (!data?.length) return [];
  return data.sort((a: Modify<TMDepartment, any>, b: Modify<TMDepartment, any>) => a.sequence - b.sequence);
};

export const dataBase64ConvertToJson = (data: any) => {
  const base64ToString = Buffer.from(data.dataBase64, "base64").toString();
  if (data.dataType === "json" && base64ToString?.length) {
    return JSON.parse(base64ToString);
  }
};

export const distinctArray = (data: any[], key: string = "id", firstValue = true) => {
  if (!data.length) return [];
  const cloneData = cloneDeep(data);
  return data.reduce((acc: any[], cv) => {
    if (firstValue) {
      if (!acc.some((cd) => cd[key] === cv[key])) {
        acc.push(cv);
      }
    } else {
      cloneData.shift();
      if (!cloneData.some((cd) => cd[key] === cv[key])) {
        acc.push(cv);
      }
    }
    return acc;
  }, []);
};

export const momentLocaleDetector = () => {
  const systemLanguage = store.getState().settings.locale;
  const locale = LocaleSystemConfig.locales.find((loc) => loc.code === systemLanguage);
  return locale?.momentLocale || LocaleSystemConfig.locales[0].momentLocale;
};

export const onChangePhoneNumber = (v: any, form: any, key = "phoneNumber") => {
  const formValue = form.getFieldValue(key);
  const value: string = v.target.value;
  if (/[0-9*+)( -]+/.test(value.charAt(value.length - 1))) form.setFieldsValue({ phoneNumber: value });
  else form.setFieldsValue({ phoneNumber: formValue.slice(0, -1) });
};

export const getFileFormDataAndFileInfo = (fileList: any[]) => {
  // eslint-disable-next-line no-unsafe-optional-chaining
  const { config } = store.getState()?.settings;
  const files: any = [fileList];
  const fileInfo = {
    extension: files[0].name?.split(".").pop().toLowerCase(),
    fileId: uuidv4(),
    isEncrypted: config.isEncrypted,
    mimeType: files[0].type,
    name: files[0].name,
    size: files[0].size,
  };
  const fileFormData = new FormData();
  fileFormData.append("file", files[0]);
  fileFormData.append("name", fileInfo.name);
  fileFormData.append("extension", fileInfo.extension);
  fileFormData.append("size", fileInfo.size);
  fileFormData.append("mimeType", fileInfo.mimeType);
  fileFormData.append("isEncrypted", String(fileInfo.isEncrypted));
  return { fileFormData, fileInfo };
};

export const isSelectorEqual = (left: any, right: any, list: string[]) => {
  if (isEmpty(left) || isEmpty(right) || isEmpty(list)) return false;
  let value = true;
  for (let i = 0; i < list?.length; i++) {
    if (!isEqual(left[list[i]], right[list[i]])) {
      value = false;
      break;
    }
  }
  return value;
};
export const getMemberByType = (members: TMDocumentMember[], type: TMMemberTypeEnum): any => {
  return members?.find((member) => member.type === type);
};
export const getMembersByType = (members: TDocumentMembers[], type: TMMemberTypeEnum): any => {
  return members?.filter((member) => member.type === type);
};

export const personNameFormation = (
  obj:
    | ({
        fatherName: null | string | TTranslations;
        firstName: null | string | TTranslations;
        lastName: null | string | TTranslations;
      } & {
        [key: string]: any;
      })
    | null
    | undefined,
  type: number = 1
) => {
  /* ************ Nurlan Nishonboyev Bohodirovich ************
   * [1] Nishonboyev N. B.
   * [2] Nurlan N. B.
   * [3] Nishonboyev Nurlan Bohodirovich
   * [4] N.N.B
   */
  if (isEmpty(obj)) return "";
  let result = "";
  const checkFirstTwoLetters = (word: null | string | undefined) => {
    if (word) {
      return ["ch", "sh", "ya", "ye", "yo", "yu"].includes(word.substring(0, 2)?.toLowerCase())
        ? word.charAt(0)?.toUpperCase() + word.charAt(1)
        : word.charAt(0).toUpperCase();
    } else {
      return undefined;
    }
  };
  const locale = localStorage?.getItem("locale") || LANGUAGE_RU;
  const firstName = typeof obj?.firstName == "object" ? obj?.firstName?.[locale] : obj?.firstName;
  const lastName = typeof obj?.lastName == "object" ? obj?.lastName?.[locale] : obj?.lastName;
  const fatherName = typeof obj?.fatherName == "object" ? obj?.fatherName?.[locale] : obj?.fatherName;
  switch (type) {
    case 1:
      result += `${lastName} ${checkFirstTwoLetters(firstName)}. ${checkFirstTwoLetters(fatherName)}.`;
      break;
    case 2:
      result += `${firstName} ${checkFirstTwoLetters(lastName)}. ${checkFirstTwoLetters(fatherName)}.`;
      break;
    case 3:
      result += `${lastName} ${obj?.firstName} ${obj?.fatherName || ""}`;
      break;
    case 4:
      result += `${checkFirstTwoLetters(firstName)}.${checkFirstTwoLetters(lastName)}.${checkFirstTwoLetters(fatherName)}.`;
      break;
  }
  return result?.replace(/undefined.|undefined/g, "");
};

export const recepientObjectGenerator = (obj: any, type: TMGroupMemberTypeEnum) => {
  let result = {};
  if (isEmpty(obj)) return result;
  switch (type) {
    case TMGroupMemberTypeEnum.HrStaff:
      result = {
        altText: obj.department?.departmentName,
        description: obj.post?.postName,
        entityId: obj.staffId,
        entityType: TMGroupMemberTypeEnum.HrStaff,
        id: `${TMGroupMemberTypeEnum.HrStaff}|${obj.staffId}`,
        text: personNameFormation(obj.person, 1),
      };
      break;
    default:
      break;
  }
  return result;
};

export const customCapitalize = (value = "") => {
  if (!value) return "";
  return value[0].toUpperCase() + value.slice(1);
};

export const customIsEmpty = (value: any) => {
  if (typeof value === "number" || value === true) return false;
  return isEmpty(value);
};

export const numberSeparator = (value: number) => {
  return String(value).replace(/(.)(?=(\d{3})+$)/g, "$1 ");
};

export const takePreviousPaths = () => {
  const paths = localStorage.getItem(PREVIOUS_PATHS);
  if (paths) {
    return JSON.parse(paths);
  }
  return [];
};

export const isValidDate = (year: number | string, month: number | string, day: number | string) => {
  const d = new Date(Number(year), Number(month), Number(day));
  return d.getFullYear() == year && d.getMonth() == month && d.getDate() == day;
};

export const isThisSymbol = (v: string) => {
  return ["_", "-", ",", ":", ".", "/", "\\", Space].includes(v);
};

export const replaceSearchText = (text: string) => (text ? text.trim() : "");

export const hasActiveItem = (params: any) => (params.data?.entityState === TMEnumStaffingTableStatus.Inactive ? "opacity-40" : "");

export const isAlreadyReadDocument = (members: any[], staffId = "") => {
  return members?.some((m: any) => m.staffId === staffId && m.type === TMMemberTypeEnum.Familiarized);
};

export const collectReceiversAsString = (languageIdentifier: string, receivers: any[] = []) => {
  let text = "";
  receivers.map((rec: any, index: number) => {
    if (rec.text) {
      const nameByLanguage = rec.translations?.text?.[languageIdentifier] || rec.text;
      if (text.length === 0) {
        text = nameByLanguage;
      } else if (index < 3) {
        text = text + `\n ${nameByLanguage}`;
      } else if (index === 3) {
        text = text + `\n (${i18n.t("seeMoreInList")})`;
      }
    }
  });
  return text;
};

export const collectReceiversAndRanksAsString = (languageIdentifier: string, receivers: any[] = []) => {
  let text = "";
  receivers.map((rec: any, index: number) => {
    if (rec.text) {
      let nameByLanguage = "";
      if (rec?.description?.length > 0 || rec?.translations?.description?.length > 0) {
        nameByLanguage += `${rec?.translations?.description?.[languageIdentifier] || rec?.description}`;
      }
      if (rec?.staff?.militaryRank?.name && rec?.staffId) {
        nameByLanguage += `\n${rec?.staff?.militaryRank?.translations?.name?.[languageIdentifier] || rec?.staff?.militaryRank?.name}`;
      }
      if (nameByLanguage !== "") {
        nameByLanguage += "\n";
      }
      nameByLanguage += rec.translations?.text?.[languageIdentifier] || rec.text;
      if (text.length === 0) {
        text = nameByLanguage;
      } else if (index < 3) {
        text = text + `\n \n ${nameByLanguage}`;
      } else if (index === 3) {
        text = text + `\n \n (${i18n.t("seeMoreInList")})`;
      }
    }
  });
  return text;
};

export const checkUsingDigitalSignature = () => {
  // eslint-disable-next-line no-unsafe-optional-chaining
  const { config } = store.getState()?.settings;
  return config.useDigitalSignature;
};

export const hasAdmin = (): boolean => {
  const user = store.getState()?.user?.userInfo;
  return user?.roles?.some((role) => role.name === EnumIdentityRoleType.Administrator)!;
};

export const checkAgentSigningFunctionAvailability = (): boolean => {
  const { agentFunctions, useDigitalSignature } = {
    agentFunctions: store.getState()?.settings?.agentFunctions,
    useDigitalSignature: store.getState()?.settings?.config?.useDigitalSignature,
  };
  return useDigitalSignature && agentFunctions.includes(SignPDF);
};

export const checkAgentSigningFunctionAvailabilityForResolution = (): boolean => {
  const { agentFunctions, useDigitalSignatureForResolution } = {
    agentFunctions: store.getState()?.settings?.agentFunctions,
    useDigitalSignatureForResolution: store.getState()?.settings?.config?.useDigitalSignatureForResolution,
  };
  return useDigitalSignatureForResolution && agentFunctions.includes(SignPDF);
};

export const runSigningFunction = (resolveAction?: () => void) => {
  const useDigitalSignature = store.getState()?.settings?.config?.useDigitalSignature && resolveAction;
  if (!useDigitalSignature && resolveAction) {
    resolveAction();
  } else if (checkUsingDigitalSignature() && !checkAgentSigningFunctionAvailability()) {
    alert(i18n.t("agentIsNotBeingUsedForSigningYouShouldInstallOrExecuteAgent"));
  } else if (resolveAction) {
    resolveAction();
  }
};

export const handleQuickRouteLink = (obj: TQuickRouteLink, searchObj: TQuickRouteLinkSearch): string => {
  if (isEmpty(obj)) return "";
  let link = obj.path;
  const combinedObj = Object.assign(obj.search, searchObj);
  if (obj.path === "/documents/on-control") {
    delete combinedObj.documentId;
    delete combinedObj.type;
    delete combinedObj.resolutionBlockId;
    combinedObj.controlPointId = combinedObj?.controlPoint;
    delete combinedObj.controlPoint;
  }
  if (obj.path === "documents/acceptance-documents/information-about-documents") {
    delete combinedObj.documentId;
    delete combinedObj.type;
    delete combinedObj.resolutionBlockId;
    delete combinedObj.controlPoint;
  }
  if (obj.path.includes("for-execution")) {
    if (searchObj.controlPoint) {
      delete combinedObj.documentId;
      delete combinedObj.type;
      delete combinedObj.resolutionBlockId;
      combinedObj.controlPointId = combinedObj?.controlPoint;
      delete combinedObj.controlPoint;
    }
  }
  if (obj.path === quickRouteLinks.sentRequests.path || obj.path === quickRouteLinks.requestsReceived.path) {
    link = link.replace("id", searchObj.caseAccessRequestId || "");
    combinedObj.crumb = `${i18n.t("requestAccess")} № ${combinedObj?.caseAccessRequestNumber || "-"}`;
    delete combinedObj.caseAccessRequestId;
    delete combinedObj.caseAccessRequestNumber;
  }
  // removing properties which has not value
  (Object.keys(combinedObj) as Array<keyof typeof combinedObj>).forEach((key) => {
    if (!combinedObj[key] && typeof combinedObj[key] !== "boolean") {
      delete combinedObj[key];
    }
  });
  Object.keys(combinedObj).map((key, index) => {
    const value = combinedObj[key as keyof TQuickRouteLinkSearch];
    if (value) {
      link = link + `${index === 0 ? "?" : "&"}${key}=${value}`;
    }
  });

  return link;
};

export const necessaryProperty = (obj: any, keys: string[]) => {
  return Object.keys(obj)
    .filter((key) => keys.includes(key))
    .reduce((acc: any, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
};

export const getAccessRequestStatusString = (str: TMEnumRequestStatus) => {
  switch (str) {
    case TMEnumRequestStatus.Requested:
      return i18n.t("requestedRequestStatus");
    case TMEnumRequestStatus.Granted:
      return i18n.t("grantedRequestStatus");
    case TMEnumRequestStatus.Rejected:
      return i18n.t("revokedRequestStatus");
    case TMEnumRequestStatus.Completed:
      return i18n.t("accessEndedAt");
  }
};

export const downloadFile = (file: any) => {
  const { settings } = store.getState();
  if (file) {
    if (file?.isEncrypted) {
      const targetAddress = encodeURIComponent(`${settings?.config?.apiGatewayUrl + ApiUrl.apiGetFileViewFile}?fileId=${file.fileId}`);
      const url = `${settings?.config?.germesAgentUrl}${ApiUrl.apiGetGermesAgentDecryptFileAndDownload}?fileId=${
        file.fileId
      }&targetAddress=${targetAddress}&agentAppId=${store.getState()?.global?.appRegisterId}`;
      window.open(url, "_blank");
    } else {
      const url = `${settings?.config?.apiGatewayUrl}${ApiUrl.apiGetFileDownloadFile}?fileId=${file.fileId}`;
      window.open(url, "_blank");
    }
  } else {
    console.error("There isn't file this function", file);
  }
};

export const filterFilesForSignedSignedDocument = (document: TMDocumentWithFileViewModel): TMFile[] => {
  if (document?.status === TMDocumentStatusEnum.Created && document?.creationType === TMDocumentCreationEnum?.InsideSystem) {
    return document?.files?.filter((i: any) => i.isMain && i.extension !== ".docx");
  }
  return document?.files?.filter((i: any) => i.isMain);
};

export function distinctUserPermissions(res: TUsersWithPermissionToViewAccessToFile[]) {
  // distincts same objects
  return res.reduce((acc: TUsersWithPermissionToViewAccessToFile[] = [], cv) => {
    if (acc.find((a) => !a.organizationId && a.staffId === cv.staffId)) {
      // avoiding to add duplicate object
      acc.forEach((permission) => {
        // when we have two similar objects with create and delete state
        // it takes one of them and sets create state
        if (permission.staffId === cv.staffId && (permission.state === TMStateEnum.Create || cv.state === TMStateEnum.Create)) {
          permission.state = TMStateEnum.Create;
        }
      });
    } else {
      acc.push(cv);
    }
    return acc;
  }, []);
}

export const getStaffRelationsByIds = (
  usersWithPermissionToViewAccessToFile: TUsersWithPermissionToViewAccessToFile[]
): Promise<{ data: TUsersWithPermissionToViewAccessToFile[]; status: boolean }> => {
  // we should not send organization's id
  const staffIds = uniq(
    onSelectedKey(
      usersWithPermissionToViewAccessToFile.filter((u) => !u.organizationId && !!u.staffId),
      "staffId"
    )
  );
  return new Promise((resolve) => {
    ApiRequest.Post(ApiUrl.apiPostStaffManagementGetStaffRelationsByIds, staffIds)
      .then((res) => {
        if (res.data.success && res.data?.data) {
          const relatedStaffs: {
            staffId?: string;
            state: TMStateEnum;
          }[] = [];
          res.data?.data.map((item: any) => {
            // collecting assistants
            if (!isEmpty(item?.relations)) {
              item?.relations.map((r: any) => {
                // state of assistant depends on leader's state. It should take deleted if leader's state is deleted
                const leaderStateOfAssistant = usersWithPermissionToViewAccessToFile.find((user) => user.staffId === item?.staffId);
                relatedStaffs.push({
                  staffId: r.relatedStaffId,
                  state: leaderStateOfAssistant?.state || TMStateEnum.Create,
                });
              });
            }
          });
          // distincting users and organizations.
          // it leaves object with create state when we have two objects that first has create state and the second has deleted state with same id
          resolve({
            data: [...distinctUserPermissions([...usersWithPermissionToViewAccessToFile, ...relatedStaffs])],
            status: true,
          });
        }
      })
      .catch((err) => {
        console.error("Error with apiPostStaffManagementGetStaffRelationsByIds", err);
        resolve({ data: usersWithPermissionToViewAccessToFile, status: true });
      });
  });
};
//This function defines that option should be disabled or not according to option's hasDigitalSignature property and config's isEncrypted property
export const defineUsageEDSInSelectOptions = (hasDigitalSignature: boolean) => {
  const { settings } = store.getState();
  if (settings?.config?.isEncrypted) return hasDigitalSignature;
  return true;
};

export const isAllowedToCreateResolution = (baseDocument: TMBaseDocument, ownResolutions: TMResolution[]) => {
  // this func works for second and more level resolutions
  // there are two cases we should not allow to create resolution
  // first case: when the document status is done
  if (baseDocument.status === TMDocumentStatusEnum.Done) return false;
  // second case: own resolution has already been created
  return ownResolutions?.length <= 0;
};

export const generateFormLanguageValues = <T extends [] | string | string[]>(args: {
  arrayLength?: number;
  emptyValue?: boolean;
  isArray?: boolean;
  prefix?: string;
  suffix?: string;
}) => {
  // for more information follow how used in unit test
  const { arrayLength = 1, emptyValue = false, isArray = false, prefix = "", suffix = "" } = args;
  const obj = {} as { [key: string]: T };
  LocaleSystemConfig.formLanguages.map((lang) => {
    if (!isArray) {
      obj[lang] = emptyValue ? ("" as T) : ((prefix + lang + suffix) as T);
    } else {
      obj[lang] = [] as T;
      if (!emptyValue) {
        for (let i = 0; i < arrayLength; i++) {
          (obj[lang] as string[]).push(prefix + lang + suffix + i);
        }
      }
    }
  });
  return obj;
};

export const checkTranslationFieldNamesValue = (args: { fieldNames: TFieldNames; formData: any }) => {
  const locale = store.getState().settings.locale;

  const { fieldNames, formData } = args;
  if (!isEmpty(fieldNames)) {
    for (const fieldName in fieldNames) {
      if (
        fieldNames[fieldName].required &&
        (!formData.translations ||
          !formData.translations[fieldName] ||
          !formData.translations[fieldName][locale] ||
          (formData.translations[fieldName][locale] && formData.translations[fieldName][locale] !== formData[fieldName]))
      ) {
        fieldNames[fieldName].hasError = true;
      } else if (
        !fieldNames[fieldName].required &&
        formData[fieldName] &&
        (!formData.translations ||
          !formData.translations[fieldName] ||
          !formData.translations[fieldName][locale] ||
          (formData.translations[fieldName][locale] && formData.translations[fieldName][locale] !== formData[fieldName]))
      ) {
        fieldNames[fieldName].hasError = true;
      } else if (
        formData.translations &&
        formData.translations[fieldName] &&
        LocaleSystemConfig.formLanguages.every((flang) => !isEmpty(formData.translations[fieldName][flang])) &&
        formData.translations[fieldName][locale] &&
        formData.translations[fieldName][locale] === formData[fieldName]
      ) {
        fieldNames[fieldName].hasError = false;
      }
    }
  }
  return cloneDeep(fieldNames);
};

export const checkTranslationFieldNamesError = (fieldNames: TFieldNames) => {
  let result = false;
  for (const value of Object.values(fieldNames)) {
    if (value.hasError) {
      result = true;
      break;
    }
  }
  return result;
};

export const openConstructionModal = () => {
  store.dispatch(
    setConfirmationModal({
      modalType: "empty",
      onConfirm: () => store.dispatch(closeConfirmationModal()),
      title: i18n.t("thisFeatureIsUnderDevelopment"),
      visible: true,
    })
  );
};

export const filterFieldsParamController = (params: any) => {
  const { CONVICTION, IS_COLLECTIVE_COMPLAINT, IS_DEPUTY, ON_PROBATION, UNDER_PRESIDENTS_CONTROL } = All_FILTER_FIELDS_KEYS;
  const obj = cloneDeep(params);
  Object.keys(obj)?.map((key) => {
    if (ALL_DATE_CHILD_FORMATS.includes(key)) {
      if (customIsEmpty(obj[key])) {
        delete obj[key];
      } else {
        obj[key] && (obj[key] = obj[key].toISOString());
      }
    } else if (Array.isArray(obj[key])) {
      if (isEmpty(obj[key])) {
        delete obj[key];
      } else {
        obj[key] && (obj[key] = obj[key].join());
      }
    } else if ([CONVICTION, IS_COLLECTIVE_COMPLAINT, IS_DEPUTY, ON_PROBATION, UNDER_PRESIDENTS_CONTROL].includes(key as All_FILTER_FIELDS_KEYS)) {
      if (typeof obj[key] !== "boolean") {
        delete obj[key];
      }
    } else {
      if (customIsEmpty(obj[key])) {
        delete obj[key];
      }
    }
  });
  return obj;
};

export const convertObjectToParam = (obj: { [key: string]: any }) => {
  return Object.entries(obj).reduce((acc, cv) => {
    if (typeof cv[1] == "boolean" || !!cv[1]) {
      acc += `${cv[0]}=${cv[1]}`;
    }
    return acc;
  }, "");
};

export const downloadFileAsync = async (
  url: string,
  params: {
    [key: string]: string;
  }
): Promise<AxiosResponse<any, any>> => {
  store.dispatch(setLoading({ key: url, status: true }));
  const { config } = store.getState().settings;
  try {
    const res = await ApiRequest.Get(url, params);
    if (typeof res.data == "string") {
      const a = document.createElement("a");
      a.href = `${config.apiGatewayUrl + url}?${convertObjectToParam(params)}`;
      a.target = "_blank"; // Open in a new tab

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    return res;
  } finally {
    store.dispatch(setLoading({ key: url, status: false }));
  }
};

export const sendTokenToInvalidTokensList = () => {
  return ApiRequest.Post(ApiUrl.AddInvalidToken);
};

export const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    console.info("Notification permission:", permission);
  } catch (error) {
    console.error("Error requesting notification permission:", error);
  }
};

export const serviceWorkerPostMessage = (message: { payload?: any; type: string; userId: string }) => {
  if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage(message);
  } else {
    console.error("Service worker not available or not ready.");
  }
};

export const getMemberAttributes = (member: TMDocumentMember): TMDocumentMember => {
  function collectPersonNameForTranslation() {
    const locales = store.getState().settings.locales;
    return locales?.reduce((acc: TTranslations, cv) => {
      acc[cv?.code] = personNameFormation({
        fatherName: member?.staff?.person?.translations?.fatherName?.[cv?.code] || "",
        firstName: member?.staff?.person?.translations?.firstName?.[cv?.code] || "",
        lastName: member?.staff?.person?.translations?.lastName?.[cv?.code] || "",
      });
      return acc;
    }, {});
  }

  if (member?.staffId) {
    return {
      ...member,
      altText: member?.staff?.department?.departmentName || member?.staff?.department?.name,
      description: member?.staff?.post?.postName || member?.staff?.post?.name,
      id: member?.staff?.staffId || "",
      text: personNameFormation(member.staff?.person, 1) || "",
      translations: {
        altText: member?.staff?.department?.translations?.name,
        description: member?.staff?.post?.translations?.postName || member?.staff?.post?.translations?.name,
        text: collectPersonNameForTranslation(),
      },
    };
  }
  if (member?.personId) {
    return {
      ...member,
      id: member?.personId || "",
      text: personNameFormation(member.person, 1) || "",
    };
  }
  if (member?.organizationId) {
    return {
      ...member,
      id: member?.organizationId || "",
      text: member?.organization?.name || "",
      translations: {
        ...member?.organization?.translations,
        text: member?.organization?.translations?.name,
      },
    };
  }
  return {
    ...member,
    id: "no data",
    text: "no data",
  };
};
export const getMembersAttributes = (members: TMDocumentMember[]): TMDocumentMember[] => {
  return members.map((m) => {
    return getMemberAttributes(m);
  });
};

export const getMemberEntityType = (member: TMDocumentMember) => {
  // Ketma-ketlikni o'zgartirilmasin muhim!
  if (member.organizationId) return TMGroupMemberTypeEnum.Organization;
  else if (member.staffId) return TMGroupMemberTypeEnum.HrStaff;
  else if (member.departmentId) return TMGroupMemberTypeEnum.HrDepartment;
  else if (member.outStaffId) return TMGroupMemberTypeEnum.OutStaff;
  else if (member.groupId) return TMGroupMemberTypeEnum.HrGroup;
  return TMGroupMemberTypeEnum.HrPerson;
};

export const busyDetector = (startedDate: string, finishedDate: string, businessTrips: { finishedDate?: string; startedDate?: string }[]) => {
  if (!startedDate || !finishedDate || isEmpty(businessTrips)) return [false, 0];
  let busyIndex = 0;
  const start = moment(startedDate).startOf("day");
  const end = moment(finishedDate).startOf("day");
  const busyResult = businessTrips.some((bt, idx) => {
    const planStart = moment(bt.startedDate).startOf("day");
    const planEnd = moment(bt.finishedDate).startOf("day");
    const res = (planStart.isSameOrBefore(end) && planEnd.isSameOrAfter(start)) || planStart.isSame(start, "day") || planEnd.isSame(end, "day");
    if (res === true) {
      busyIndex = idx;
    }
    return res;
  });

  return [busyResult, busyIndex];
};

export function formatCounter(count?: number) {
  if (count === 0 || count === undefined) {
    return "";
  }

  if (count < 1000) {
    return count.toString();
  }

  if (count >= 1000 && count < 1000000) {
    const thousands = Math.floor(count / 1000);
    const remainder = count % 1000;

    if (remainder < 100) {
      return `${thousands}K+`;
    }

    const fraction = Math.floor(remainder / 100) / 10;
    return `${thousands + fraction}K+`;
  }

  const millions = Math.floor(count / 1000000);
  const remainderMillions = count % 1000000;

  if (remainderMillions < 100000) {
    return `${millions}M+`;
  }

  const fractionMillions = Math.floor(remainderMillions / 100000) / 10;
  return `${millions + fractionMillions}M+`;
}
