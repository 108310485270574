import { HubConnection } from "@microsoft/signalr";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TMNotificationMessage, TNotificationCountRead } from "Models/ModelNotification";

import { TNotificationsState } from "./types";

export const initialStateNotifications: TNotificationsState = {
  notificationConnection: null,
  notificationDrawerVisible: false,
  notifications: [],
};

export const notificationsSlice = createSlice({
  initialState: initialStateNotifications,
  name: "notifications",
  reducers: {
    addNewNotification: (state, action: PayloadAction<TMNotificationMessage>) => {
      const notification = action.payload;
      return {
        ...state,
        notifications: [notification, ...state.notifications],
      };
    },
    addNotifications: (state, action: PayloadAction<TMNotificationMessage[]>) => {
      return {
        ...state,
        notifications: [...state.notifications, ...action.payload],
      };
    },
    removeNotificationById: (state, action: PayloadAction<string>) => {
      const notificationId = action.payload;
      return {
        ...state,
        notificationCounts: {
          totalCount: state.notificationCounts?.totalCount ? state.notificationCounts.totalCount - 1 : 0,
          unreadCount: state.notificationCounts?.unreadCount || 0,
        },
        notifications: state.notifications.filter((not) => not.notificationId !== notificationId),
      };
    },
    setNotificationAsRead: (state, action: PayloadAction<string>) => {
      const notificationId = action.payload;
      return {
        ...state,
        notificationCounts: {
          ...(state.notificationCounts || {}),
          unreadCount: state.notificationCounts?.unreadCount ? state.notificationCounts.unreadCount - 1 : 0,
        },
        notifications: state.notifications.map((not) => (not.notificationId === notificationId ? { ...not, isRead: true } : not)),
      };
    },
    setNotificationConnection: (state, action: PayloadAction<HubConnection | null>) => {
      return { ...state, notificationConnection: action.payload };
    },
    setNotificationCounts: (state, action: PayloadAction<TNotificationCountRead>) => {
      return {
        ...state,
        notificationCounts: action.payload,
      };
    },
    setNotificationDrawerVisible: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        notificationDrawerVisible: action.payload,
      };
    },
    setNotificationFilters: (state, action: PayloadAction<{ hasNextData: boolean; references: null; take: number }>) => {
      return {
        ...state,
        notificationFilters: action.payload,
      };
    },
    setNotifications: (state, action: PayloadAction<TMNotificationMessage[]>) => {
      return {
        ...state,
        notifications: action.payload,
      };
    },
    setNotificationsAsRead: (state) => {
      return {
        ...state,
        notificationCounts: {
          ...(state.notificationCounts || {}),
          unreadCount: 0,
        },
        notifications: state.notifications.map((not) => ({
          ...not,
          isRead: true,
        })),
      };
    },
  },
});

export const {
  addNewNotification,
  addNotifications,
  removeNotificationById,
  setNotificationAsRead,
  setNotificationConnection,
  setNotificationCounts,
  setNotificationDrawerVisible,
  setNotificationFilters,
  setNotifications,
  setNotificationsAsRead,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
