// Note: Do not forget to fill all translation.json files inTranslation and enterValue property when you are adding new formLanguage

export const LANGUAGE_RU = "ru_RU";
export const LANGUAGE_UZ = "uz_UZ";
export const LANGUAGE_UZ_CYRL = "uz_Cyrl_UZ";

const LocaleSystemConfig: TConfig = {
  appFontSize: "12px",
  basename: "",
  defaultPath: "/",
  formLanguages: [LANGUAGE_RU, LANGUAGE_UZ_CYRL, LANGUAGE_UZ],
  locale: global.localStorage?.getItem("locale") || LANGUAGE_RU,
  locales: [
    {
      code: LANGUAGE_RU,
      flag: "flag-ru",
      id: LANGUAGE_RU,
      momentLocale: "ru",
      name: "Русский язык",
      shortName: "Ру",
    },
    {
      code: LANGUAGE_UZ_CYRL,
      flag: "flag-uz",
      id: LANGUAGE_UZ_CYRL,
      momentLocale: "uz",
      name: "Ўзбек тили",
      shortName: "Ўз",
    },
    {
      code: LANGUAGE_UZ,
      flag: "flag-lat",
      id: LANGUAGE_UZ,
      momentLocale: "uz-latn",
      name: "O’zbek tili",
      shortName: "O’z",
    },
  ],
  theme: "light",
};

type TConfig = {
  appFontSize: string;
  basename: string;
  defaultPath: string;
  formLanguages: string[];
  locale: string;
  locales: {
    code: string;
    flag: string;
    id: string;
    momentLocale: string;
    name: string;
    shortName: string;
  }[];
  theme: string;
};

export default LocaleSystemConfig;
