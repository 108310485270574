import { trackPromise } from "react-promise-tracker";

import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { store } from "Redux/store";
import AuthService, { IAuthService } from "Utils/AuthService";
import { getTokenFromUrl } from "Utils/Functions";

import toastify from "../components/Toastify";

const config = JSON.parse(localStorage.getItem("config") || JSON.stringify(""));

export const API = axios.create({
  baseURL: config.apiGatewayUrl,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    Errorcode: "",
  },
  responseType: "json",
});

const isHandlerEnabled = (config: TAxiosRequestConfig = {}) => {
  return !config.hasOwnProperty("handlerEnabled");
};

API.interceptors.request.use((request) => requestHandler(request));
API.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

const requestHandler = (request: TAxiosRequestConfig) => {
  if (isHandlerEnabled(request)) {
    request.headers && (request.headers["App"] = config?.projectName);
    request.headers && (request.headers["Accept-Language"] = store?.getState()?.settings?.locale?.replace(/_/g, "-") || "ru-RU");
    let token = "";
    let tokenType = "Bearer";
    let header = "";
    try {
      token = store?.getState()?.user.userProfile?.access_token || "";
      tokenType = store.getState().user.userProfile?.token_type || "";
      header = `${tokenType} ${token}`;
    } catch {
      /* empty */
    }

    request.headers && (request.headers.Authorization = header);
    API.defaults.headers.common["Authorization"] = header;
  }
  return request;
};
const errorHandler = (error: any) => {
  if (error.response && isHandlerEnabled(error.response.config)) {
    if (error.response.status === 401) {
      const authService: IAuthService = new AuthService();
      authService.logout();
    }
  }

  return Promise.reject(error.response);
};
const successHandler = (response: AxiosResponse) => {
  if (isHandlerEnabled(response.config)) {
    if (response.status !== 200 || response.data == null) {
      return Promise.reject(new Error("Нулевой результат! (null)"));
    }
  }
  return response;
};

export class ApiRequest {
  static Delete(url: string, data: any, options: TAxiosRequestConfig = { isToast: true }) {
    options.url = url;
    options.method = "POST";
    options.params = data;
    return this.Request(options);
  }

  static Get(url: string, data: any = {}, options: TAxiosRequestConfig = { isToast: true }) {
    const { headers, path, ...newData } = data;
    options.url = `${url}${path ? `/${path}` : ""}`;
    options.method = "GET";
    options.params = newData;
    return this.Request(options);
  }

  static Post(
    url: string,
    data: any = {},
    options: TAxiosRequestConfig = {
      isToast: true,
    }
  ) {
    options.url = url;
    options.method = "POST";
    options.data = data;
    return this.Request(options);
  }

  static Request(options: TAxiosRequestConfig) {
    const url = options.url ? options.url : "";
    const { data, method = "GET" } = options;
    const token_kanban: null | string = getTokenFromUrl();
    options.headers = {
      ...options.headers,
      token: token_kanban || "",
    };
    // options.headers = {
    //   token:
    //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJzdGFmZl9pZCI6MjMsInBlcnNvbl9pZCI6MiwicGVyc29uX25hbWUiOiLQodCw0LvQuNGF0L7QsiDQoi4gLiIsInBlcnNv
    let result: Promise<AxiosResponse<any>>;
    switch (method.toUpperCase()) {
      case "GET":
        result = API.get(url, options);
        break;
      case "POST":
        result = API.post(url, data, options);
        break;
      case "DELETE":
        result = API.post(url, data, options);
        break;
      default:
        result = API(options);
        break;
    }
    trackPromise(result).then((res) => {
      if (options.isToast && res && res.data && (options.method === "POST" || options.method === "DELETE")) {
        toastify.result(res.data.code);
      }
      if (options.isToast && options.method === "GET" && res && res.data && !res.data.success) {
        toastify.result(res.data.code);
      }
    });
    // .catch((error) => {
    //   if ([400, 401, 404, 415, 499, 500, 502].includes(error.status)) {
    //     toastify.error(i18n.t("request.error.entity"), i18n.t("request.error.errorHasOccurredContactToAdministrator"));
    //   }
    // });
    return result;
  }
}

interface TAxiosRequestConfig extends AxiosRequestConfig {
  isToast?: boolean;
}
