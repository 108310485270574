import { applyMiddleware, compose, configureStore, PreloadedState } from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import thunk from "redux-thunk";

import rootReducer from "./reducers";
import { RootState } from "./types";

export const history = createBrowserHistory();

export default function setupStore(preloadedState?: PreloadedState<RootState>) {
  const middleware = [thunk];
  return configureStore({
    devTools: process.env.NODE_ENV !== "production",
    enhancers: [compose(applyMiddleware(...middleware))],
    middleware,
    preloadedState,
    reducer: rootReducer,
  });
}

export const store = setupStore();
