export enum EnumAsideActionMenu {
  Agreement = "Agreement",
  Approvement = "Approvement",
  AttachedFiles = "AttachedFiles",
  Distribution = "Distribution",
  GeneralInformation = "GeneralInformation",
  History = "History",
  OwnResolution = "OwnResolution",
  RegistrationCard = "RegistrationCard",
  RelatedDocuments = "RelatedDocuments",
  Resolution = "Resolution",
}

export enum EnumEntityState {
  Active,
  Inactive,
  Delete,
}

export enum EnumFileMimeType {
  DOC = "application/msword",
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  JPEG = "image/jpeg",
  JPG = "image/jpg",
  PDF = "application/pdf",
  PNG = "image/png",
}

export enum EnumPageMode {
  Add = "add",
  Edit = "edit",
  View = "view",
}

export enum RequiredAlertMode {
  Default = "default",
  Error = "error",
  Success = "success",
}

export enum EnumExecutionProgress {
  Block = "Block", //Блок
  Comment = "Comment", //Комментарий
  ControlPoint = "ControlPoint", //Контрольный пункт
  ExecutionNote = "ExecutionNote", //Отметка об исполнении
  ForConsideration = "ForConsideration", //На рассмотрение
  ForExecution = "ForExecution", //На исполнении
  ForInformation = "ForInformation", //Для сведения
  ForRevision = "ForRevision", //На доработку
  Inner = "Inner", //Внутренний
  NotActual = "NotActual", //NotActual
  Outgoing = "Outgoing", //Исходящий
  Resolution = "Resolution", //Резолюция
  Revoke = "Revoke", //Отозвать
  TakeOff = "TakeOff", //Снять с контроля
}

export enum EnumIdentityRoleType {
  Administrator = "administrator", //Администратор
  SuperAdministrator = "super_administrator", //Суперадминистратор
  User = "user", //Пользователь
}

export enum JsonResponseDataType {
  Array = "array",
  Boolean = "boolean",
  Integer = "integer",
  Json = "json",
  JsonArray = "json-array",
  JsonObject = "json-object",
  Object = "object",
  String = "string",
}

export enum EnumDocumentCreationType {
  HrOrderInitiative = "HrOrderInitiative",
  HrOrderRelated = "HrOrderRelated",
  InnerInitiative = "InnerInitiative",
  InnerRelated = "InnerRelated",
  OutgoingInitiative = "OutgoingInitiative",
  OutgoingRelated = "OutgoingRelated",
  RegulatoryInitiative = "RegulatoryInitiative",
  RegulatoryRelated = "RegulatoryRelated",
}

export enum EnumFilterStatus {
  Invisible = "Invisible",
  Permanent = "Permanent",
  Temporary = "Temporary",
}

export enum EnumCreateItemAction {
  Drawer = "Drawer",
  Link = "Link",
  Modal = "Modal",
}

export enum EnumCreateItemType {
  Collapse = "Collapse",
  Group = "Group",
  Item = "Item",
}

export enum EnumContactTypeIdentifier {
  Email = "Email",
  Phone = "Phone",
}

export enum EnumOnlyOfficeTagNames {
  BusinessTripAccount = "BusinessTripAccount",
  BusinessTripAccountantContent = "BusinessTripAccountantContent",
  BusinessTripFinishedDate = "BusinessTripFinishedDate",
  BusinessTripHr = "BusinessTripHr",
  BusinessTripHrContent = "BusinessTripHrContent",
  BusinessTripPeriod = "BusinessTripPeriod",
  BusinessTripPurpose = "BusinessTripPurpose",
  BusinessTripStaff = "BusinessTripStaff",
  BusinessTripStartedDate = "BusinessTripStartedDate",
  Country = "Country",
  District = "District",
  DocumentView = "DocumentView",
  Executors = "Executors",
  Organization = "Organization",
  QRCode = "QRCode",
  Rank = "Rank",
  Recipients = "Recipients",
  RegDate = "RegDate",
  Region = "Region",
  RegNumber = "RegNumber",
  SecreceryLevel = "SecreceryLevel",
  ShortContentHtml = "ShortContentHtml",
  SignerName = "SignerName",
  SignerPostName = "SignerPostName",
  Title = "Title",
}

export enum EnumDocumentPageViewMode {
  BLOCK = "block",
  TABLE = "table",
}

export enum EnumDocumentsInCase {
  Created = "Created",
  Done = "Done",
  ForApproval = "ForApproval",
  InCase = "InCase",
  Rejected = "Rejected",
  SentToWork = "SentToWork",
}

export enum EnumDocumentListBlockMode {
  Default = "Default",
  InCase = "InCase",
}
