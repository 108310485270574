export enum TMDocumentFormEnum {
  Document = "Document", // Документ
  Draft = "Draft", // Черновик
  Project = "Project", // Проект
}

export enum TMDocumentTypeEnum {
  Complaint = "Complaint",
  HrOrder = "HrOrder",
  Incoming = "Incoming",
  Inner = "Inner",
  Order = "Order",
  Outgoing = "Outgoing",
  Regulatory = "Regulatory",
}

export enum TMRelatedEntityEnum {
  Document = "Document",
  DocumentMessage = "DocumentMessage",
  News = "News",
}

export enum TMCandidateStatus {
  FiredStaff = "FiredStaff",
  Person = "Person",
  Staff = "Staff",
}

export enum TMMessageFolderEnum {
  Inbox = "Inbox",
  Sent = "Sent",
}

export enum TMMessageDataEnum {
  Html = "Html",
  Json = "Json",
  Xml = "Xml",
}

export enum TMAgreementEntityTypeEnum {
  /// <summary>
  /// Общее
  /// </summary>
  Document = "Document",

  /// <summary>
  /// Документ
  /// </summary>
  DocumentMessage = "DocumentMessage",

  /// <summary>
  /// Сообщение
  /// </summary>
  General = "General",
}

export enum TMAgreementStageMemberStatusEnum {
  /// <summary>
  /// Без ответа
  /// </summary>
  Agree = "Agree",

  /// <summary>
  /// Согласен
  /// </summary>
  Disagree = "Disagree",

  /// <summary>
  /// Не согласен
  /// </summary>
  NoAnswer = "NoAnswer",
}

export enum TMAgreementStatusEnum {
  /// <summary>
  /// Черновик
  /// </summary>
  Aborted = "Aborted",

  /// <summary>
  /// На согласовании
  /// </summary>
  Agreed = "Agreed",

  /// <summary>
  /// Согласовано
  /// </summary>
  Denied = "Denied",

  /// <summary>
  /// Отказано
  /// </summary>
  Draft = "Draft",

  /// <summary>
  /// На утверждения
  /// </summary>
  OnAgreement = "OnAgreement",
}

export enum TMMemberTypeEnum {
  /// <summary>
  /// Рассматривающий
  AgreementMember = "AgreementMember",

  /// <summary>
  /// Исполнитель
  /// </summary>
  Assistant = "Assistant",

  /// <summary>
  /// Помощник
  /// </summary>
  Author = "Author",

  /// <summary>
  /// Исполнитель
  /// </summary>
  Controller = "Controller",

  /// <summary>
  /// Контроллер
  /// </summary>
  Delivery = "Delivery",

  /// <summary>
  /// Получатель
  /// </summary>
  Executer = "Executer",

  /// <summary>
  /// Автор
  /// </summary>
  Familiarized = "Familiarized",

  /// <summary>
  /// Регистратор
  /// </summary>
  Performer = "Performer",

  /// <summary>
  /// Подписант
  /// </summary>
  Recipient = "Recipient",

  /// </summary>
  Registrar = "Registrar",

  /// <summary>
  /// Ознакомленный
  /// </summary>
  Signer = "Signer",
}

export enum TMGroupMemberTypeEnum {
  All = "All",
  Favorites = "Favorites",
  HrDepartment = "HrDepartment",
  HrFilial = "HrFilial",
  HrGroup = "HrGroup",
  HrOutStaff = "HrOutStaff",
  HrPerson = "HrPerson",
  HrRole = "HrRole",
  HrStaff = "HrStaff",
  HrStaffingTablePost = "HrStaffingTablePost",
  //This for frontend
  HrVacant = "HrVacant",
  HrVacantDocumentsAvailable = "HrVacantDocumentsAvailable",
  Organization = "Organization",
  OutStaff = "OutStaff",
}

export enum TMDocumentStatusEnum {
  /// <summary>
  /// Создано
  /// </summary>
  All = "All",

  /// <summary>
  /// На подготовку
  /// </summary>
  Created = "Created",

  /// <summary>
  /// На рассмотрение
  /// </summary>
  Done = "Done",

  /// <summary>
  /// Просрочен
  /// </summary>
  Expired = "Expired",

  /// <summary>
  /// На исполнении
  /// </summary>
  ForAgree = "ForAgree",

  /// <summary>
  /// На доработку
  /// </summary>
  ForApproval = "ForApproval",

  /// <summary>
  /// На согласование
  /// </summary>
  ForConsideration = "ForConsideration",

  /// <summary>
  /// На утверждения
  /// </summary>
  ForExecution = "ForExecution",

  /// <summary>
  /// Готов к подписанию
  /// </summary>
  ForPreparing = "ForPreparing",

  /// <summary>
  /// На подписи
  /// </summary>
  ForRevision = "ForRevision",

  /// <summary>
  /// Отправлено
  /// </summary>
  ForSigning = "ForSigning",

  /// <summary>
  /// Выполнено
  /// </summary>
  InCase = "InCase",

  /// <summary>
  /// Documentning filteri uchun,
  /// Barcha document turini so'raganda
  /// </summary>
  ReadyForSigning = "ReadyForSigning",

  Rejected = "Rejected",

  /// <summary>
  /// В деле
  /// </summary>
  Sent = "Sent",

  Unregistered = "Unregistered",
}

export enum TMDocumentCreationEnum {
  InsideSystem = "InsideSystem",
  OnPaper = "OnPaper",
  OutsideSystem = "OutsideSystem",
}

export enum TMDocuemntTransferStatusEnum {
  Accepted = "Accepted",
  Rejected = "Rejected",
  Waiting = "Waiting",
}

export enum TMEventBusEnum {
  RabbitMQ = 1,
  AzureEventBus,
}

export enum TMDatabaseEnum {
  Postgre = 1,
  SqlServer,
  SqlServerLocal,
  Oracle,
}

export enum TMDocumentBaseTypeEnum {
  Complaint = "Complaint",
  Incoming = "Incoming",
  Inner = "Inner",
  Order = "Order",
  Outgoing = "Outgoing",
}

export enum TMApplicantTypeEnum {
  Organization = "Organization",
  Person = "Person",
}

export enum TMEntityStateEnum {
  Active = "Active", // Active entity (Inserted or Updated entity)
  Delete = "Delete", // Deleted entity (Deletes after running GarbageCollector)
  Inactive = "Inactive", // Temporary Inactive entity for Timeline Function (May be active in Future)
}

export enum TMDepartmentTypeEnum {
  Department = "Department",
  Filial = "Filial",
  Organization = "Organization",
  Post = "Post",
}

export enum TMStateEnum {
  Create = "Create",
  Created = "Created",
  Delete = "Delete",
  Update = "Update",
}

export enum TMContactTypeEnum {
  Custom = "Custom",
  Email = "Email",
  Phone = "Phone",
  SocialAccount = "SocialAccount",
}

export enum TMEntityNameEnum {
  Avatar = "Avatar",
  Education = "Education",
  LabourActivity = "LabourActivity",
  Militry = "Militry",
  OtherFiles = "OtherFiles",
  OtherInformation = "OtherInformation",
  Party = "Party",
  PersonIdentification = "PersonIdentification",
  ScientificActivity = "ScientificActivity",
  Training = "Training",
}

export enum TMActionTypeEnum {
  Fired = "Fired",
  FiredByTransfer = "FiredByTransfer",
  Hire = "Hire",
  Transfered = "Transfered",
  UpdateStaff = "UpdateStaff",
}

export enum TMEmailStatusEnum {
  //Новый
  New = "New",
  //Ознакомлен
  Read = "Read",
  //Получен
  Received = "Received",
  //Зарегистрировано
  Registered = "Registered",
  //Отправляется
  Sending = "Sending",
  //Отправлен
  Sent = "Sent",
  //Не зарегистрирован
  Unregistered = "Unregistered",
}

export enum TMNotificationStateEnum {
  Archived = "Archived",
  New = "New",
  Read = "Read",
  Received = "Received",
}

export enum TMMessageStatusEnum {
  Done = "Done",
  Draft = "Draft",
  Expired = "Expired",
  ForRevision = "ForRevision",
  ForSigning = "ForSigning",
  NotActual = "NotActual",
  Signed = "Signed",
}

export enum TMMessageTypeEnum {
  Block = "Block", //Блок
  Comment = "Comment", //Комментарий
  ControlPoint = "ControlPoint", //Контрольный пункт
  DocumentControlPoints = "DocumentControlPoints",
  ExecutionNote = "ExecutionNote", //Отметка об исполнении
  ForConsideration = "ForConsideration", //На рассмотрение
  ForExecution = "ForExecution", //На исполнении
  ForInformation = "ForInformation", //Для сведения
  ForRevision = "ForRevision", //На доработку
  Resolution = "Resolution", //Резолюция
  Revoke = "Revoke", //Отозвать
  TakeOff = "TakeOff", //Снять с контроля
}

export enum TMEnumStaffingTableStatus {
  Active = "Active",
  Approved = "Approved",
  Draft = "Draft",
  Inactive = "Inactive",
  Project = "Project",
}

export enum TMEnumOrganizationStructureStatus {
  Active = "Active",
  Approved = "Approved",
  Draft = "Draft",
  Inactive = "Inactive",
  Project = "Project",
}

export enum ReceptionType {
  Private = "Private",
  Public = "Public",
}

export enum TMDocumentRelationType {
  Duplicated = "Duplicated",
  Repeated = "Repeated",
  Replied = "Replied",
}

export enum TMEnterpriseEnum {
  Enterprise = "Enterprise",
  Filial = "Filial",
  Government = "Government",
}

export enum TMColumnPinEnum {
  Left = "Left",
  None = "None",
  Right = "Right",
}

export enum TMReasonTransfer {
  Fired = "Fired",
  NotChanged = "NotChanged",
  Return = "Return",
  Transfer = "Transfer",
  Update = "Update",
  Vacancy = "Vacancy",
}

export enum TMBusinessTripsEnum {
  Arrivals = "Arrivals",
  Dropouts = "Dropouts",
  Planned = "Planned",
}

export enum TMUserStatusEnum {
  Active = "Active",
  NoActive = "NoActive",
}

export enum TMHrDocumentViewIdentifier {
  BUSINESS_TRIP = "business_trip",
  FIRE_STAFF = "fire_staff",
  HIRE_STAFF = "hire_staff",
  HR_ORDER = "hr_order",
  TRANSFER_STAFF = "transfer_staff",
}

export enum TMDictionaryType {
  DictionaryItem = "DictionaryItem",
  DocumentJournal = "DocumentJournal",
  DocumentType = "DocumentType",
  DocumentView = "DocumentView",
  Organization = "Organization",
  Post = "Post",
}

export enum TMRecipientTypeEnum {
  Agreement = "Agreement",
  Controller = "Controller",
  Performer = "Performer", // Ijrochi
  Recipient = "Recipient", // Qabul qiluvchi
  Recipient_Acquaintance = "Recipient_Acquaintance", // кому ознокомления
  Signer = "Signer", // Imzoga
  ToWhom = "ToWhom", // кому рассмотрение
}
