import React from "react";

import i18n from "I18n";

import "./index.scss";

const Loader = () => {
  return (
    <div className="preloader-page-wrapper">
      <div className="preloader-wrapper">
        <div className="preloader">
          <div className="d-flex-center">
            <div className="block-blue" />
            <div className="l-h-16">
              <span className="icon icon-loading-arrow-left arrow-left" />
            </div>
          </div>
          <div className="d-flex-center">
            <div className="l-h-16">
              <span className="icon icon-loading-arrow-right arrow-right" />
            </div>
            <div className="block-grey" />
          </div>
        </div>
        <div className="preloader-text text-medium-small text-muted m-t-10">{i18n.t("downloadData")}...</div>
      </div>
    </div>
  );
};

export default Loader;
