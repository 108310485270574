export const DottedDateFormat = "DD.MM.YYYY";
export const DottedDateFormatWithTime = "DD.MM.YYYY, HH:mm";
export const DottedDateFormatWithTime2 = "DD.MM.YYYY HH:mm";
export const DottedHourFormat = "HH:mm";
export const DottedDayFormat = "DD MMMM";
export const DottedDayShortFormat = "DD MMM";
export const PublicPathsForAuthenticatedUser = [
  "/settings/user-settings/my-profile",
  "/documents/acceptance-documents",
  "/documents/acceptance-documents/information-about-documents",
];
