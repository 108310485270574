import React from "react";
import { toast } from "react-toastify";

import { Button } from "antd";

import i18n from "I18n";

import "./index.scss";

const success = (title?: string, description?: string) => {
  return toast.success(
    <div className="d-flex align-items-center">
      <div className="toast-icon">
        <span className="icon-fluent icon-checkmark-circle-32-filled f-s-36 icon-checkmark-36" />
      </div>
      <div className="context">
        {title && <h5>{title}</h5>}
        {description && <p>{description}</p>}
      </div>
    </div>,
    {
      icon: false,
      pauseOnFocusLoss: false,
      position: "bottom-right",
    }
  );
};

function error(title?: string, description?: string) {
  return toast.error(
    <div className="d-flex align-items-center">
      <div className="toast-icon">
        <span className="icon-fluent icon-warning-24-filled f-s-36 icon-warning-36" />
      </div>
      <div className="context">
        {title && <h5>{title}</h5>}
        {description && <p>{description}</p>}
      </div>
    </div>,
    {
      icon: false,
      pauseOnFocusLoss: false,
      position: "bottom-right",
    }
  );
}

function info(title?: string, description?: string) {
  return toast.info(
    <div className="d-flex align-items-center">
      <div className="toast-icon">
        <span className="icon-fluent icon-info-28-filled f-s-36 icon-info-36" />
      </div>
      <div className="context">
        {title && <h5>{title}</h5>}
        {description && <p>{description}</p>}
      </div>
    </div>,
    {
      icon: false,
      pauseOnFocusLoss: false,
      position: "bottom-right",
    }
  );
}

function warning(title?: string, description?: string) {
  return toast.warning(
    <div className="d-flex align-items-center">
      <div className="toast-icon">
        <span className="icon-fluent icon-warning-24-filled f-s-36 icon-error-36" />
      </div>
      <div className="context">
        {title && <h5>{title}</h5>}
        {description && <p>{description}</p>}
      </div>
    </div>,
    {
      icon: false,
      pauseOnFocusLoss: false,
      position: "bottom-right",
    }
  );
}

function reloaderBrawser(onClose: Function) {
  return toast.info(
    <div className="reloader-brawser">
      <span className="icon-fluent icon-info-20-filled f-s-20" />
      <div className="reloader-brawser-content">
        <div className="content-text">{i18n.t("changeLanguageAlert")}.</div>
        <Button className="content-button" icon={<span className="icon-fluent icon-arrow-clockwise-20-filled" />} onClick={() => onClose(true)}>
          {i18n.t("refresh")}
        </Button>
      </div>
    </div>,
    {
      icon: false,
      onClose: () => onClose(false),
      pauseOnFocusLoss: false,
      position: "bottom-right",
    }
  );
}

function result(code: any, ignoredCodeList?: string[]) {
  if (!ignoredCodeList?.includes(code)) {
    switch (
      code?.split("-")[0] //success
    ) {
      case "success":
        switch (code) {
          case "success-save-data":
            success(i18n.t("request.success.save-data"));
            break;
          case "success-add-data":
            success(i18n.t("request.success.add-data"));
            break;
          case "success-edit-data":
            success(i18n.t("request.success.edit-data"));
            break;
          case "success-delete-data":
            success(i18n.t("request.success.delete-data"));
            break;
          case "success-duplicate-data":
            success(i18n.t("request.success.duplicate-data"));
            break;
        }
        break;
      case "error":
        switch (code) {
          case "error-save-data":
            error(i18n.t("request.error.save-data"));
            break;
          case "error-add-data":
            error(i18n.t("request.error.add-data"));
            break;
          case "error-edit-data":
            error(i18n.t("request.error.edit-data"));
            break;
          case "error-delete-data":
            error(i18n.t("request.error.delete-data"));
            break;
          case "error-duplicate-data":
            error(i18n.t("request.error.duplicate-data"));
            break;
          case "error-duplicate-identifier":
            error(i18n.t("request.error.duplicate-identifier"));
            break;
          case "error-invalid-data":
            error(i18n.t("request.error.invalid-data"));
            break;
          case "error-change-not-allowed":
            error(i18n.t("request.error.change-not-allowed"));
            break;
          case "error-delete-not-allowed":
            error(i18n.t("request.error.change-not-allowed"));
            break;
          case "error-not-found-data":
            error(i18n.t("request.error.not-found-data"));
            break;
          case "error-dublicate-username":
            error(i18n.t("request.error.dublicate-username"));
            break;
          case "error-dublicate-account":
            error(i18n.t("request.error.dublicate-account"));
            break;
          case "error-authorization-failed":
            error(i18n.t("request.error.authorization-failed"));
            break;
          case "error-not-allowed-data":
            error(i18n.t("request.error.not-allowed-data"));
            break;
          case "error-licence-limit-exceeded":
            error(i18n.t("request.error.limit-exceeded"));
            break;
          case "error-prepare-resolution-to-sign":
            error("error-prepare-resolution-to-sign");
            break;
          case "error-sign-pdf-document-file-agent":
            error("error-sign-pdf-document-file-agent");
            break;
          case "error-sign-pdf-resolution-file-agent":
            error("error-sign-pdf-resolution-file-agent");
            break;
          case "error-prepare-document-to-sign":
            error("error-prepare-document-to-sign");
            break;
          case "error-prepare-to-sign-data-null":
            error("error-prepare-to-sign-data-null");
            break;
          case "error-prepare-to-sign-file-id-null":
            error("error-prepare-to-sign-file-id-null");
            break;
          case "error-prepare-to-sign-content-null":
            error("error-prepare-to-sign-content-null");
            break;
          case "error-connected-ui-connection-id-null":
            error("error-connected-ui-connection-id-null");
            break;
          case "error-sign-pdf":
            error("error-sign-pdf");
            break;
          default:
            error(i18n.t("request.error.entity"));
        }
        break;
    }
  }
}

export default {
  error: error,
  info: info,
  reloaderBrawser: reloaderBrawser,
  result: result,
  success: success,
  warning: warning,
};
