import { HubConnection } from "@microsoft/signalr";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TMEnvironment } from "Models/index";
import { TRelatedDictionaries } from "Types";
import { loadFromLocalStorage, saveToLocalStorage } from "Utils/Functions";

import settingsConfig from "../../config/settings-config";
import { SettingsState } from "./types";

export const initialStateSettings: SettingsState = {
  agentFunctions: [],
  appFontSize: settingsConfig.appFontSize,
  config: {},
  containerClassnames: loadFromLocalStorage("containerClassnames") || "sub-menu-hidden",
  dictionaryRelations: [],
  environments: [],
  formLanguages: settingsConfig.formLanguages,
  loading: [],
  locale: settingsConfig.locale,
  locales: settingsConfig.locales,
  navMenuType: loadFromLocalStorage("navMenuType") || "nav-menu",
  reloadDataByKey: [],
  theme: settingsConfig.theme,
  unsavedActions: [],
};

export const settingsSlice = createSlice({
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: initialStateSettings,
  name: "settings",
  reducers: {
    clearUnsavedActions: (state) => {
      if (!state.unsavedActions.length) return;
      return {
        ...state,
        unsavedActions: [],
      };
    },
    removeUnsavedActions: (state, action: PayloadAction<string[]>) => {
      if (!state.unsavedActions.some((usaction) => action.payload.includes(usaction))) return;
      return {
        ...state,
        unsavedActions: state.unsavedActions.filter((unaction) => {
          if (!action.payload.includes(unaction)) return unaction;
        }),
      };
    },
    setAgentFunction: (state, action: PayloadAction<{ key: string; status: boolean }>) => {
      if (action.payload.status) {
        return {
          ...state,
          agentFunctions: [...state.agentFunctions, action.payload.key],
        };
      }
      return {
        ...state,
        agentFunctions: state.agentFunctions && state.agentFunctions.filter((item) => item !== action.payload.key),
      };
    },
    setAppFontSize: (state, action: PayloadAction<string>) => {
      document.documentElement.style.fontSize = action.payload;
      saveToLocalStorage("appFontSize", action.payload);
      return { ...state, appFontSize: action.payload };
    },
    setConfig: (state, action: PayloadAction<any>) => {
      return { ...state, config: action.payload };
    },
    setContainerClassnames: (state, action: PayloadAction<string>) => {
      saveToLocalStorage("containerClassnames", action.payload);
      return { ...state, containerClassnames: action.payload };
    },
    setCreateButton: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        createButton: action.payload,
      };
    },
    setDictionaryRelations: (state, action: PayloadAction<TRelatedDictionaries[]>) => ({
      ...state,
      dictionaryRelations: action.payload,
    }),
    setEnvironments: (state, action: PayloadAction<TMEnvironment[]>) => {
      return { ...state, environments: action.payload };
    },
    setGermesAgentConnection: (state, action: PayloadAction<HubConnection>) => ({
      ...state,
      germesAgentConnection: action.payload,
    }),
    setLoading: (state, action: PayloadAction<{ key: string; status: boolean }>) => {
      if (action.payload.status) {
        return {
          ...state,
          loading: [...state.loading, action.payload.key],
        };
      }
      return {
        ...state,
        loading: state.loading && state.loading.filter((item) => item !== action.payload.key),
      };
    },
    setLocale: (state, action: PayloadAction<string>) => {
      return { ...state, locale: action.payload };
    },
    setReloadListByKey: (state, action: PayloadAction<string[]>) => ({
      ...state,
      reloadDataByKey: action.payload,
    }),
    setTheme: (state, action: PayloadAction<"dark" | "light">) => {
      document.documentElement.setAttribute("data-theme", action.payload);
      saveToLocalStorage("theme", action.payload);
      return { ...state, theme: action.payload };
    },
    setUnsavedActions: (state, action: PayloadAction<{ key: string; status: boolean }>) => {
      const { key, status } = action.payload;
      if (status && state.unsavedActions?.includes(key)) return;
      if (!status && !state.unsavedActions?.includes(key)) return;
      if (status) {
        return {
          ...state,
          unsavedActions: [...state.unsavedActions, key],
        };
      }
      return {
        ...state,
        unsavedActions: state.unsavedActions && state.unsavedActions.filter((item) => item !== key),
      };
    },
  },
});

export const {
  clearUnsavedActions,
  setAgentFunction,
  setAppFontSize,
  setConfig,
  setContainerClassnames,
  setCreateButton,
  setDictionaryRelations,
  setEnvironments,
  setGermesAgentConnection,
  setLoading,
  setLocale,
  setReloadListByKey,
  setTheme,
  setUnsavedActions,
} = settingsSlice.actions;

export default settingsSlice.reducer;
