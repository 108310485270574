import i18n from "I18n";

import { TQuickRouteLink } from "../types";

const links: { [key: string]: TQuickRouteLink } = {
  documentsInCase: {
    path: "/cases/documents_to_file",
    search: {
      pageViewMode: "block",
    },
  },
  drafts: {
    path: "/documents/drafts",
    search: {
      pageViewMode: "block",
    },
  },
  leaderMailLeaderComplaint: {
    path: "/documents/leader-mail/leader-complaint",
    search: {
      activeTab: "All",
      pageViewMode: "block",
    },
  },
  leaderMailLeaderForConsideration: {
    path: "/documents/leader-mail/leader-for-consideration",
    search: {
      activeTab: "All",
      pageViewMode: "block",
    },
  },
  leaderMailLeaderForExecution: {
    path: "/documents/leader-mail/leader_for_execution",
    search: {},
  },
  leaderMailLeaderOnApproval: {
    path: "/documents/leader-mail/leader-on-approval",
    search: {
      activeTab: "All",
      pageViewMode: "block",
    },
  },
  myResolutions: {
    path: "/documents/my-resolutions",
    search: {},
  },
  onControl: {
    path: "/documents/on-control",
    search: {},
  },
  positionsAndRoles: {
    path: "/settings/user-settings/users/positions-and-roles",
    search: {},
  },
  projects: {
    path: "/documents/projects",
    search: {
      pageViewMode: "block",
    },
  },
  receivedComplaint: {
    path: "/documents/received-documents/complaint",
    search: {
      activeTab: "All",
      pageViewMode: "block",
    },
  },
  receivedForConsideration: {
    path: "/documents/received-documents/for-consideration",
    search: {
      activeTab: "All",
      pageViewMode: "block",
    },
  },
  receivedForExecution: {
    path: "/documents/received-documents/for-execution",
    search: {
      activeTab: "All",
      pageViewMode: "block",
    },
  },
  receivedOnApproval: {
    path: "/documents/received-documents/on-approval",
    search: {
      activeTab: "All",
      pageViewMode: "block",
    },
  },
  requestsReceived: {
    path: "/cases/requests-received/id",
    search: {
      pageViewMode: "block",
    },
  },
  sent: {
    path: "/documents/sent",
    search: {
      pageViewMode: "block",
    },
  },
  sentRequests: {
    path: "/cases/sent-requests/id",
    search: {
      pageViewMode: "block",
    },
  },
  transferDocumentOnApproval: {
    path: "documents/acceptance-documents/information-about-documents",
    search: {
      crumb: i18n.t("informationAndDocumentation"),
    },
  },
};

export default links;
