import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep, isEmpty } from "lodash";

import { EnumCreateItemType } from "Enums";
import i18n from "I18n";
import { TMAdministratorUserInfo, TMUserInfo } from "Models/ModelUser";
import { TCreateItem } from "Types";

import { TMenus, TMUserProfile, TUserState } from "./types";

export const initialStateUser: TUserState = {
  activeCreateItems: [],
  createItems: [],
  menus: [],
  notifications: [],
  userInfo: null,
  userProfile: null,
};

export const userSlice = createSlice({
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: initialStateUser,
  name: "user",
  reducers: {
    addAcceptanceDocumentsMenu: (state) => {
      let updatedMenus = cloneDeep(state.menus);
      if (!isEmpty(updatedMenus)) {
        updatedMenus = updatedMenus.map((menu) => {
          if (menu.identifier === "documents" && !menu.children?.some((item) => item.menuId === "9e7c76f7-bfbc-4f3a-95c3-24f4f84e1458")) {
            menu.children?.push({
              children: [],
              identifier: "acceptance_documents",
              itemClasses: "nav-item",
              itemIcon: "document-add",
              itemType: "item",
              menuId: "9e7c76f7-bfbc-4f3a-95c3-24f4f84e1458",
              name: i18n.t("acceptanceDocuments"),
              parentMenuId: menu.menuId,
              path: "/documents/acceptance-documents",
              pathPart: "acceptance-documents",
              sequence: menu.children?.length,
              translations: {
                name: {
                  ru_RU: "Приём документов",
                  uz_Cyrl_UZ: "Хужжатларни қабул қилиш",
                  uz_UZ: "Xujjatlarni qabul qilish",
                },
              },
            });
          }
          return menu;
        });
      }
      return {
        ...state,
        menus: updatedMenus,
      };
    },
    addCreateItems: (state, action: PayloadAction<Array<TCreateItem>>) => {
      return { ...state, createItems: [...action.payload, ...state.createItems] };
    },
    removeAcceptanceDocumentsMenu: (state) => {
      let updatedMenus = cloneDeep(state.menus);
      if (!isEmpty(updatedMenus)) {
        updatedMenus = updatedMenus.map((menu) => {
          if (menu.identifier === "documents") {
            menu.children = menu.children?.filter((m) => m.identifier !== "acceptance_documents");
          }
          return menu;
        });
      }
      return {
        ...state,
        menus: updatedMenus,
      };
    },
    removeCreateItems: (state, action: PayloadAction<string[]>) => {
      const currentCreateItems: TCreateItem[] = current(state.createItems);
      const newCreateItems: any[] = [];
      const loop = (items: TCreateItem[], identifier: string) => {
        const list: TCreateItem[] = [];
        if (!isEmpty(items)) {
          items.map((item: TCreateItem) => {
            if (item.identifier !== identifier) {
              if (item.children && item.children.length !== 0) {
                item = { ...item, children: loop(item.children, identifier) };
              }
              list.push(item);
            }
          });
        }
        return list;
      };
      if (!isEmpty(currentCreateItems) && !isEmpty(action.payload)) {
        currentCreateItems.map((item) => {
          action.payload.map((identifier) => {
            if (item.identifier !== identifier && (item.type === EnumCreateItemType.Group || item.type === EnumCreateItemType.Collapse)) {
              if (item.children && item.children?.length !== 0) {
                item = { ...item, children: loop(item.children, identifier) };
              }
              newCreateItems.push(item);
            }
            if (item.identifier !== identifier && item.type === EnumCreateItemType.Item) {
              newCreateItems.push(item);
            }
          });
        });
      }
      return { ...state, createItems: [...newCreateItems] };
    },
    setActiveCreateItems: (state, action: PayloadAction<{ keys: string[]; status: boolean }>) => {
      if (action.payload.status) {
        return { ...state, activeCreateItems: [...state.activeCreateItems, ...action.payload.keys] };
      }
      return {
        ...state,
        activeCreateItems: state.activeCreateItems.filter((item) => action.payload.keys.some((key) => key !== item)),
      };
    },
    setCreateItems: (state, action: PayloadAction<TCreateItem[]>) => {
      return { ...state, createItems: action.payload };
    },
    setMenus: (state, action: PayloadAction<TMenus[]>) => {
      return { ...state, menus: action.payload };
    },
    setUserInfo: (state, action: PayloadAction<TMUserInfo & TMAdministratorUserInfo>) => {
      return { ...state, userInfo: action.payload };
    },
    setUserNotifications: (state, action: PayloadAction<any>) => {
      return { ...state, notifications: action.payload };
    },
    setUserProfile: (state, action: PayloadAction<TMUserProfile>) => {
      return {
        ...state,
        userProfile: action.payload,
      };
    },
  },
});

export const {
  addAcceptanceDocumentsMenu,
  addCreateItems,
  removeCreateItems,
  setActiveCreateItems,
  setCreateItems,
  setMenus,
  setUserInfo,
  setUserProfile,
  removeAcceptanceDocumentsMenu,
} = userSlice.actions;

export default userSlice.reducer;
