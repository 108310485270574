import { Moment } from "moment";

import { TMStaffWithBusinessTrip } from "Components/DocumentControls/ProjectDocuments/BusinessTripOrderProject/types";
import { EnumDocumentsInCase, EnumExecutionProgress } from "Enums";
import {
  TMApplicantTypeEnum,
  TMDocumentBaseTypeEnum,
  TMDocumentCreationEnum,
  TMDocumentFormEnum,
  TMDocumentTypeEnum,
  TMGroupMemberTypeEnum,
  TMMemberTypeEnum,
  TMStateEnum,
} from "Models/Enums";
import { TMessage } from "Models/ModelApplication";
import { TMBaseDocument } from "Models/ModelBaseDocument";
import { TMFileModel, TMPartialViewModel, TMRecipientShortViewModel, TTranslations } from "Models/ModelCommon";
import { TMDocumentJournal } from "Models/ModelDocumentJournal";
import { TMMessage } from "Models/ModelMessage";
import { TNomenclatureCaseModel, TResponsibleStaff } from "Models/ModelNomenclature";
import { TMMemberViewModel } from "Models/ModelResolution";
import { TMEntityStateEnum, TMEnumState } from "Models/NetworkModels/index";
import { TMAgreement } from "Models/NetworkModels/TMAgreementService";
import { Modify, TMember } from "Types";

import { TMSecrecyLevelDictionary } from "./TMSecurityService";

/*---TMDocumentService Enums---*/

export enum TMEnumRouteType {
  /// <summary>
  /// На рассмотрение
  /// </summary>
  ForConsideration = "ForConsideration",

  /// <summary>
  /// На исполнении
  /// </summary>
  ForExecution = "ForExecution",
}

/*---TMDocumentService Enums---*/

export enum TMEnumRequestStatus {
  Completed = "Completed",
  Granted = "Granted",
  Rejected = "Rejected",
  Requested = "Requested",
}

export enum TMEnumDocumentRelationType {
  Duplicated = "Duplicated",
  Repeated = "Repeated",
  Replied = "Replied",
}

export enum TMEnumDocumentAction {
  /// <summary>
  /// Someone создал(а) документ
  /// </summary>
  AgreementAgreed = "AgreementAgreed",

  /// <summary>
  /// Someone зарегистрировал(а) документ
  /// </summary>
  AgreementDenied = "AgreementDenied",

  /// <summary>
  /// Someone отправил(а) документ на согласование
  /// </summary>
  ApprovementAgreed = "ApprovementAgreed",

  /// <summary>
  /// Someone отправил(а) документ на визирование
  /// </summary>
  ApprovementDenied = "ApprovementDenied",

  /// <summary>
  /// Someone отправил(а) документ на доработку
  /// </summary>
  CaseLinkCreated = "CaseLinkCreated",

  /// <summary>
  /// Someone отправил(а) документ в номенклатуру дел
  /// </summary>
  CaseLinkRevoked = "CaseLinkRevoked",

  /// <summary>
  /// Someone отправил(а) документ на подписание
  /// </summary>
  CreateExecutionMark = "CreateExecutionMark",

  /// <summary>
  /// Someone подписал(а) документ
  /// </summary>
  DocumentCreated = "DocumentCreated",

  /// <summary>
  /// Someone создал(а) проект резолюции
  /// </summary>
  DocumentDone = "DocumentDone",

  /// <summary>
  /// Someone подписал(а) резолюцию
  /// </summary>
  DocumentRegistered = "DocumentRegistered",

  /// <summary>
  /// Someone Отправил(а) проект резолюции на подпись someone
  /// </summary>
  DocumentSentForAgreement = "DocumentSentForAgreement",

  /// <summary>
  /// Someone Отправил(а) проект резолюции на доработку someone
  /// </summary>
  DocumentSentForApproval = "DocumentSentForApproval",

  /// <summary>
  /// Someone Создал(а) отметку об исполнении
  /// </summary>
  DocumentSentForRevision = "DocumentSentForRevision",

  /// <summary>
  /// Someone Снял(а) с контроля пункт
  /// </summary>
  DocumentSentForSigning = "DocumentSentForSigning",

  /// <summary>
  /// Someone согласовал(а) документ
  /// </summary>
  DocumentSentToCase = "DocumentSentToCase",

  /// <summary>
  /// Someone отказал(а) согласование документа
  /// </summary>
  DocumentSigned = "DocumentSigned",

  /// <summary>
  /// Someone визировал(а) документ
  /// </summary>
  ResolutionCreated = "ResolutionCreated",

  /// <summary>
  /// Someone отказал(а) визирование документа
  /// </summary>
  ResolutionForRevision = "ResolutionForRevision",

  /// <summary>
  /// Someone отозвал(а) документ от подписания
  /// </summary>
  ResolutionSentForSign = "ResolutionSentForSign",

  /// <summary>
  /// Someone отозвал(а) документ от согласования
  /// </summary>
  ResolutionSigned = "ResolutionSigned",

  /// <summary>
  /// Someone отозвал(а) документ от визирования
  /// </summary>
  RevokedForAgreementDocument = "RevokedForAgreementDocument",

  /// <summary>
  /// Someone исполнил(а) документ
  /// </summary>
  RevokedForApprovalDocument = "RevokedForApprovalDocument",

  /// <summary>
  /// Someone отправил(а) документ в номенклатуру дел
  /// </summary>
  RevokedForSigningDocument = "RevokedForSigningDocument",

  /// <summary>
  /// Someone отозвал(а) документ от номенклатура дел
  /// </summary>
  TakeOffControlPoint = "TakeOffControlPoint",
}

export enum TMDocumentStatusEnum {
  /// <summary>
  /// Создано
  /// </summary>
  All = "All",

  /// <summary>
  /// На подготовку
  /// </summary>
  Created = "Created",

  /// <summary>
  /// На рассмотрение
  /// </summary>
  Done = "Done",

  /// <summary>
  /// На исполнении
  /// </summary>
  ForAgree = "ForAgree",

  /// <summary>
  /// На доработку
  /// </summary>
  ForApproval = "ForApproval",

  /// <summary>
  /// На согласование
  /// </summary>
  ForConsideration = "ForConsideration",

  /// <summary>
  /// На утверждения
  /// </summary>
  ForExecution = "ForExecution",

  /// <summary>
  /// Готов к подписанию
  /// </summary>
  ForPreparing = "ForPreparing",

  /// <summary>
  /// На подписи
  /// </summary>
  ForRevision = "ForRevision",

  /// <summary>
  /// Отправлено
  /// </summary>
  ForSigning = "ForSigning",

  /// <summary>
  /// Выполнено
  /// </summary>
  ReadyForSigning = "ReadyForSigning",

  /// <summary>
  /// Documentning filteri uchun,
  /// Barcha document turini so'raganda
  /// </summary>
  Sent = "Sent",

  Unregistered = "Unregistered",
}

enum TMEnumEntityType {
  Document = "Document",
  Message = "Message",
}

/*---TMDocumentService Types---*/

export type TMCaseLinkData = {
  case: Modify<TNomenclatureCaseModel, { responsibleStaff: Partial<TResponsibleStaff> }>;
  caseLinkId: string;
  createDate: string;
  leaderReadDate?: string;
  leaderStaff?: TResponsibleStaff;
  leaderStaffNote?: string;
  performerReadDate?: string;
  performerStaff?: TResponsibleStaff;
  readDate: string;
  responsibleReadDate?: string;
  responsibleStaff: TResponsibleStaff;
  responsibleStaffNote?: string;
  status: EnumDocumentsInCase;
};

export type TMBaseRequest = {
  case: TMCaseForCaseAccess;
  caseAccessRequestId: string;
  createDate: string;
  document: TMDocumentInCase;
  number: string;
  requestedByStaffId: string;
  requestedByStaffInfo: null | TMStaff;
  responsibleStaffReadDate: null | string;
  status: TMEnumRequestStatus;
};

export type TMDocumentHistory = {
  action: TMEnumDocumentAction;
  actionAuthor: TMStaff;
  actionAuthorId: string;
  actionDate: string;
  actionToWhom?: TMStaff;
  actionToWhomId: string;
  author: TMStaff;
  authorId: string;
  createDate: string;
  deadline?: string;
  entityId?: string;
  entityState?: TMEntityStateEnum;
  entityType?: TMEnumEntityType;
  message?: TMMessage;
  modifyDate?: string;
  organizationDatabaseId?: string;
  sender?: TMStaff;
  senderId?: string;
  signer?: TMStaff;
  signerId?: string;
};

type TDocumentView = {
  createDate: string;
  defaultIsControlPoint: boolean;
  description: null | string;
  documentTypeRelationWithViews: null;
  documentViewId: string;
  entityState: TMEntityStateEnum;
  identifier: string;
  modifyDate: null | string;
  name: string;
  organizationDatabaseId: string;
  sequence: null | number;
  shortName: null | string;
  translations: { name: TTranslations };
};

export type TDocumentTag = {
  createDate?: string;
  entityState?: TMEntityStateEnum;
  modifyDate?: null | string;
  name?: string;
  organizationDatabaseId?: null;
  state: TMEnumState;
  tagId: string;
};

export type TMDocumentWithFilesViewModel = {
  actionPlanNumber: null | number;
  additionalSubdivision: null | string;
  administrationHeadResolution: null | string;
  agreementId: null | string;
  agreementMembers: TMStaff[];
  agreements?: TMAgreement[];
  anotherSystemDocumentId: null | string;
  applicantAddress: null | string;
  applicantDistrict?: null | TMDictionaryItem;
  applicantDistrictId: null | string;
  applicantDistrictText: null | string;
  applicantEmail: null | string;
  applicantFullName: null | string;
  applicantInfo?: {
    address: null | string;
    district: null | string;
    email: null | string;
    name: null | string;
    phoneNumber: null | string;
    region: null | string;
  };
  applicantPhoneNumber: null | string;
  applicantRegion?: null | TMDictionaryItem;
  applicantRegionId: null | string;
  applicantRegionText: null | string;
  applicantsCount: number;
  applicantType?: null | TMApplicantTypeEnum;
  applicationsCount: null | number;
  applicationsSheetsCount: null | number;
  applicationSubmissionType?: null | TMDictionaryItem;
  applicationSubmissionTypeId: null | string;
  approvalDate: null | string;
  arrivalDate: null | string;
  authorPhoneNumber: null | string;
  baseType?: TMDocumentTypeEnum;
  basisReasonId: null | string;
  basisReasonName: null | string;
  blankNumber: null | string;
  blankType?: null | TMDictionaryItem;
  blankTypeId: null | string;
  boxId: string;
  cabinetMinistersOrder: null | string;
  caseLinkStatus: EnumDocumentsInCase | null;
  caseNumber: null | string;
  changesToDocument: null | string;
  complaintSubject?: null | TMDictionaryItem;
  complaintSubjectId: null | string;
  complaintSubmissionTypeId: null | string;
  complaintTypeId: null | string;
  content: null | string;
  contentHtml?: string;
  controllerId: null | string;
  controllerName: null | string;
  controllers?: null | TMMemberViewModel;
  copiesCount: null | number;
  copyNumber: null | number;
  correspondent: any;
  correspondentAdditionalInfo: null | string;
  correspondentId: null | string;
  correspondentName: null | string;
  correspondentPhoneNumber: null | string;
  count: number;
  createDate: string;
  creationType?: TMDocumentCreationEnum;
  deadline: null | string;
  department: null | TMDepartment;
  departmentId: null | string;
  distinctiveMark?: null | TMDictionaryItem;
  distinctiveMarksId: null | string;
  documentDate: string;
  documentFolderId: string;
  documentId: string;
  documentNumber: string;
  documentNumberRelatedToExpired: null | string;
  documentRelationCreateDate: string;
  documentRelationId: string;
  documentSubject?: null | TMDictionaryItem;
  documentSubjectId: null | string;
  documentType: string;
  documentView: TDocumentView;
  documentViewId: string;
  entityState: TMEntityStateEnum;
  executionNote: null | string;
  executorPhoneNumber: null | string;
  expirationDate: null | string;
  expired: null | string;
  favourites: false;
  files: [];
  filesIds: null | string[];
  forApproval: boolean | null;
  form?: TMDocumentFormEnum;
  globalDocumentId: string;
  hasResolution: boolean;
  higherAuthorityOrder: null | string;
  importanceFactor: null | number;
  internalDate: null | string;
  internalDocumentNumber: null | string;
  isCollectiveComplaint: boolean;
  isInCase: boolean;
  isRelatedDocument: boolean;
  journal?: null | TMDocumentJournal;
  journalId: string;
  language?: null | TMDictionaryItem;
  languageId: string;
  liters?: null | TMDictionaryItem;
  litersId: null | string;
  memberId: null | string;
  members: TMember[];
  messageId: null | string;
  messages: TMessage[];
  methodOfReceiving?: null | TMDictionaryItem;
  methodOfReceivingId: null | string;
  modifyDate: string;
  name: null | string;
  note: null | string;
  organizationDatabaseId: string;
  organizations: any;
  outDate: null | string;
  outNumber: null | string;
  parentDocumentMessageId: null | string;
  performers?: null | TMMemberViewModel;
  personalComputingMachineNumber: null | string;
  projectNumber: null | string;
  projectRegDate: null | string;
  readDate: null | string;
  receivedDate: null | string;
  receptionStaff?: null | TMStaff;
  receptionStaffId: null | string;
  receptionType: number;
  recipients?: TMRecipientShortViewModel[];
  reComplaint: null | string;
  regDate: string;
  region?: null | TMDictionaryItem;
  regionId: null | string;
  regNumber: string;
  relations?: null | TMDocumentRelation;
  repliedId: null | string;
  responsiblePerformer: null | string;
  secrecyLevel: {
    id: string;
    level: number;
    name: string;
  };
  secrecyLevelId: string;
  sendMethod?: null | TMDictionaryItem;
  sendMethodId: null | string;
  sheetsCount: null | number;
  shortContent: string;
  shortContentHtml: string;
  status: EnumExecutionProgress | null;
  structuralDepartmentName: null | string;
  tags: TDocumentTag[];
  takeOff: null | string;
  type?: TMDocumentTypeEnum;
  typeName: null | string;
  underPresidentsControl: boolean;
  urgencyCategory: null | TMPartialViewModel;
  urgencyCategoryId: null | string;
  version: number;
  versionMainId: string;
  versionPreviousId: null | string;
};

type TMDocumentRelation = {
  documentId: string;
  documentRelationId: string;
  relatedDocumentId: string;
  relationType: TMEnumDocumentRelationType;
};

type TMDocumentInCase = {
  documentId: string;
  documentView: any;
  modifyDate: string;
  note: string;
  outDate: string;
  outNumber: string;
  regDate: string;
  regNumber: string;
  secrecyLevel: TMDictionaryItem;
  shortContent: string;
  subject: null | TMDictionaryItem;
  toSentCaseDate: string;
  type: TMDocumentTypeEnum;
};
type TMCaseForCaseAccess = {
  caseId: string;
  isHideContent: boolean;
  name: string;
  number: string;
  responsibleStaff: null | TMStaff;
  secrecyLevel: null | TMDictionaryItem;
  translations: {
    name: TTranslations;
  };
};

export type TMCaseAccessRequest = {
  caseAccessRequestId: string;
  createDate: string;
  deadline: string;
  description: string;
  documentFiles: TMFileModel[];
  documentId: string;
  hasActiveAccessRequest: boolean;
  issuedDate: string;
  number: string;
  rejectedDate: string;
  rejectNote: string;
  requestedByStaffId: string;
  requestedByStaffInfo: {
    fatherName: string;
    firstName: string;
    lastName: string;
  };
  revokedDate: string;
  status: TMEnumRequestStatus;
};

export type TMOutgoingDocument = {
  author: TMStaff;
  createDate: string;
  documentId: string;
  entityState: string;
  globalDocumentId: string;

  messageId: string;
  onControl: boolean;
  organizationDatabaseId: "bb5ec787-3c08-4ea8-922e-7104505f9369";
  staffId: string;
  status: string;
  type: string;
};

type TMDictionaryItem = {
  id: string;
  identifier?: string;
  name: string;
  sequence?: string;
};

export type TMReceivers = {
  additionalInfo?: string;
  altText?: string;
  createDate?: string;
  description: string;
  eMail?: string;
  entityId: string;
  entityType: TMGroupMemberTypeEnum;
  groupMemberId?: string;
  id: string;
  imgUrl?: string;
  isFavorite?: boolean;
  memberId?: string;
  messageId?: string;
  messageMemberId?: string;
  phoneNumber?: string;
  readDate?: string;
  receivedDate?: string;
  sendDate?: string;
  staffId?: string;
  state: TMStateEnum;
  subText: string;
  text: string;
  type: string;
};

export type TMDocumentVersions = {
  applications: TMApplications[];
  documentId: string;
  form: string;
  mainFile: any[];
  modifyDate: string;
  status: string;
  version: number;
};
export type TMApplications = {
  author: any;
  authorId: string;
  content: any;
  createDate: string;
  entityState: any;
  extension: string;
  fileId: string;
  isMain: false;
  mimeType: string;
  modifyDate: string;
  name: string;
  organizationDatabaseId: string;
  size: number;
  state: string;
  translations: string;
  translationsJson: any;
};

export interface TMRecipient {
  address?: string;
  altText?: string;
  authorId: string;
  department?: TMDepartment;
  departmentId?: string;
  description?: string;
  eMail?: string;
  groupId?: string;
  groupName?: string;
  id?: string;
  isLastResponsiblePerformer?: boolean;
  isResponsible?: boolean;
  methodOfReceivingId?: string;
  myStaffRelations?: string[];
  name: string;
  organization?: TMOrganization;
  organizationId?: string;
  outStaffId?: string;
  person?: TMPerson;
  personId?: string;
  phoneNumber?: string;
  post?: TMPost;
  relatedMemberId?: string;
  relatedStaffsToMe?: string[];
  sequenceNumber?: number;
  staff?: TMStaff;
  staffId?: string;
  status?: TMStateEnum;
  text?: string;
  translations: {
    altText?: TTranslations;
    description?: TTranslations;
    text?: TTranslations;
  };
}
export interface TMDocumentMember extends TMRecipient {
  createDate?: string;
  deadline?: string;
  documentId?: string;
  memberId: string;
  message?: any;
  messageId?: string;
  readDate?: string;
  receivedDate?: string;
  sendDate?: string;
  sentDate?: string;
  state?: TMStateEnum;
  type: TMMemberTypeEnum;
}
type TMDepartment = {
  departmentId: string;
  departmentName: string;
  name: string;
  translations: { name: TTranslations; shortName?: TTranslations };
};
type TMPost = {
  name?: string;
  postId: string;
  postName?: string;
  translations: { name: TTranslations; postName?: TTranslations };
};

type TMOrganization = {
  name: string;
  organizationId: string;
  translations: { name: TTranslations };
};
export type TMRole = {
  name: string;
  roleId: string;
};

export type TMStaff = {
  avatarFileId?: string;
  department?: TMDepartment;
  organization?: TMOrganization;
  person: TMPerson;
  personnelNumber?: string;
  post?: TMPost;
  roles?: TMRole[];
  staffId: string;
};

type TMPerson = {
  fatherName: string;
  firstName: string;
  lastName: string;
  personId: string;
  translations: {
    address?: TTranslations;
    fatherName?: TTranslations;
    firstName: TTranslations;
    lastName: TTranslations;
    militaryMarkOfRemovalRegistration?: TTranslations;
    militaryNameOfCommissariat?: TTranslations;
    militaryTeamNumber?: TTranslations;
    placeOfBirth?: TTranslations;
  };
};

export interface TMHrBusinessTripDocument extends Omit<TMBaseDocument, "secrecyLevel"> {
  businessTrips: {
    businessTripId: string;
    country?: {
      id: string;
      name: string;
      translations: {
        name: TTranslations;
      };
    };
    countryId: string;
    diffDays?: number;
    district?: {
      id: string;
      name: string;
      translations: {
        name: TTranslations;
      };
    };
    districtId: string;
    documentId?: string;
    finishedDate: Moment | null | string;
    organization: string;
    purpose: string;
    region?: {
      id: string;
      name: string;
      translations: {
        name: TTranslations;
      };
    };
    regionId: string;
    replacementStaff?: TMStaffWithBusinessTrip;
    replacementStaffId: string;
    staff?: TMStaffWithBusinessTrip;
    staffId: string;
    startedDate: Moment | null | string;
  }[];
  plannedJournal: {
    documentJournalId: string;
    name: string;
  };
  plannedJournalId: string;
  secrecyLevel: TMSecrecyLevelDictionary;
}
export type TMBusinessTrip = {
  businessTripId: string;
  country: {
    id: string;
    identifier: string;
    name: string;
    translations: {
      name: TTranslations;
    };
  };
  countryId: string;
  createDate: string;
  district: {
    id: string;
    identifier: string;
    name: string;
    translations: {
      name: TTranslations;
    };
  };
  districtId: string;
  document: {
    documentId: string;
    members: [];
    regDate: string;
    regNumber: string;
  };
  documentId: string;
  finishedDate: string;
  organization: string;
  purpose: string;
  region: {
    id: string;
    identifier: string;
    name: string;
    translations: {
      name: TTranslations;
    };
  };
  regionId: string;
  replacementStaff: TMStaff;
  replacementStaffId: string;
  staff: TMStaff;
  staffId: string;
  startedDate: string;
};

export type TMDocumentStatistics = {
  all: number;
  done: number;
  expired: number;
  untouched: number;
};

export type TMDocumentType = {
  baseType?: TMDocumentBaseTypeEnum;
  createDate?: string;
  description: string;
  documentTypeId: string;
  entityState?: string;
  identifier: string;
  name: string;
  sequence?: number;
  shortName?: string;
  translations: { name: TTranslations };
  type?: string;
};

export type TMDocumentTransfer = {
  createDate?: string;
  documentCount: number;
  fromDepartmentId: string;
  fromPostId: string;
  fromStaffId: string;
  fromStaffInfo?: {
    createDate?: string;
    department?: TMDepartment;
    isActive: boolean;
    person?: {
      fatherName: string;
      firstName: string;
      lastName: string;
    };
    post?: TMPost;
    postName?: TTranslations;
  };
  status: string;
  toDepartmentId: string;
  toPostId: string;
  toStaffId: string;
  toStaffInfo?: {
    createDate?: string;
    department?: TMDepartment;
    isActive: boolean;
    person?: {
      fatherName: string;
      firstName: string;
      lastName: string;
    };
    post?: TMPost;
    postName?: TTranslations;
  };
  transferDocumentId: string;
};

export type TMDocumentTransferByTransferDocumentId = {
  createDate: string;
  documentCount: number;
  entityState: TMEntityStateEnum;
  fromDepartmentId: string;
  fromPostId: string;
  fromStaffId: string;
  fromStaffInfo: {
    createDate: string;
    department: null | TMDepartment;
    isActive: boolean;
    modifyDate: string;
    organizationDatabaseId: string;
    person: {
      fatherName: string;
      firstName: string;
      lastName: string;
    } | null;
    post: null | TMPost;
    postName: TTranslations;
  };
  modifyDate: string;
  organizationDatabaseId: string;
  note?: string;
  status: string;
  toDepartmentId: string;
  toPostId: string;
  toStaffId: string;
  toStaffInfo: {
    createDate: string;
    department: null | TMDepartment;
    isActive: boolean;
    person: {
      fatherName: string;
      firstName: string;
      lastName: string;
    } | null;
    post: null | TMPost;
    postName: TTranslations;
  };
  transferDocumentId: string;
};
