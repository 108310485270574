import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { store } from "Redux/store";

import Initializer from "./hoc/Initializer";

ReactDOM.render(
  <Provider store={store}>
    <Initializer />
  </Provider>,
  document.getElementById("root") || document.createElement("div") // for testing purposes
);
