import { useCallback, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { debounce } from "lodash";

import { ApiRequest } from "API";
import { SCROLLABLE_TABLE_LOADING } from "Components/Tables/AgGrid/ListDataScroll/constants";
import { PREVIOUS_PATHS } from "Constants/common";
import i18n from "I18n";
import { closeConfirmationModal, setConfirmationModal } from "Redux/global/globalSlice";
import { setLoading } from "Redux/settings/settingsSlice";
import { AppDispatch, RootState } from "Redux/types";
import { takePreviousPaths } from "Utils/Functions";

export const useAppDispatch: () => AppDispatch = useDispatch;

export function useAppSelector<TSelected>(selector: (state: RootState) => TSelected): TSelected {
  return useSelector(selector, shallowEqual);
}

export const useForceUpdate = () => {
  const [_, setRenderComponent] = useState(false);
  return () => setRenderComponent((renderComponent) => !renderComponent);
};
export const useDebounce = (callback: any, delay: 500) => useCallback(debounce(callback, 500), []);

export function useInterval(callback: any, delay: any) {
  const savedCallback: any = useRef();
  //Remember the last callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  //Set up then Interval
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [callback, delay]);
}

export const useDidUpdateOnce = (callback: Function, dependencies: any[]) => {
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const isWorked = useRef(false);
  return useEffect(() => {
    if (isWorked.current) return;
    if (isFirstLoading) {
      setIsFirstLoading(false);
      return;
    }
    isWorked.current = true;
    callback();
  }, dependencies);
};

export const useEffectDidUpdate = (callback: Function, dependencies: any[]) => {
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  return useEffect(() => {
    if (isFirstLoading) {
      setIsFirstLoading(false);
      return;
    }
    callback();
  }, dependencies);
};

export const useUnload = (showModal: boolean) => {
  const active = useCallback((e) => {
    e.preventDefault();
    e.returnValue = "";
  }, []);

  useEffect(() => {
    if (showModal) {
      window.onbeforeunload = active;
    } else {
      window.onbeforeunload = null;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [showModal]);
};

export const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref: any = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useBrowserPreviosPathsKeeper = () => {
  const history = useHistory();

  useEffect(() => {
    history.listen((location, action) => {
      const paths = takePreviousPaths();
      switch (action) {
        case "POP":
          localStorage.setItem(PREVIOUS_PATHS, JSON.stringify(paths.slice(0, paths.length - 1)));
          break;
        case "PUSH":
          if (paths.length > 9) {
            paths.shift();
          }
          localStorage.setItem(PREVIOUS_PATHS, JSON.stringify([...paths, window.location.href]));
          break;
        case "REPLACE":
          localStorage.setItem(PREVIOUS_PATHS, JSON.stringify([...paths.slice(0, paths.length - 1), window.location.href]));
          break;
      }
    });
  }, [history]);
};

export const useAccessForDocument = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const checkAccess = ({
    accessed = true,
    apiEndpoint,
    callback,
    params,
    redirectPath,
  }: {
    accessed?: boolean;
    apiEndpoint: string;
    callback?: () => void;
    params?: object;
    redirectPath?: string;
  }) => {
    if (accessed) {
      dispatch(setLoading({ key: apiEndpoint, status: true }));
      dispatch(setLoading({ key: SCROLLABLE_TABLE_LOADING, status: true }));
      return ApiRequest.Get(apiEndpoint, params)
        .then((res) => {
          if (res.data.success) {
            if (res.data?.data) {
              redirectPath && history.push(redirectPath);
              callback && callback();
            } else {
              dispatch(
                setConfirmationModal({
                  confirmText: i18n.t("clear"),
                  hideSubTitle: true,
                  modalType: "warning",
                  onConfirm: () => dispatch(closeConfirmationModal()),
                  title: i18n.t("youHaveNotAccessToThisDocument"),
                  visible: true,
                })
              );
            }
          }
        })
        .finally(() => {
          dispatch(setLoading({ key: SCROLLABLE_TABLE_LOADING, status: false }));
          dispatch(setLoading({ key: apiEndpoint, status: false }));
        });
    } else {
      redirectPath && history.push(redirectPath);
      callback && callback();
    }
  };
  return { checkAccess };
};
