export const RELOAD = "reload";
export const SOUND = "sound";
export const SCROLL_PARAM = "scroll";
export const MODAL_SCROLL_PARAM = "modal_scroll";
export const VACANCY_INFO = "vacancy_info";
export const SELECTED_ROW_POSITION_AFTER_GO_BACK = "middle";

export const DOCUMENT_VIEW_IDENTIFIER_HR_ORDER = "hr_order";
export const DOCUMENT_VIEW_IDENTIFIER_TRANSFER_STAFF = "transfer_staff";
export const DOCUMENT_VIEW_IDENTIFIER_HIRE_STAFF = "hire_staff";
export const DOCUMENT_VIEW_IDENTIFIER_FIRE_STAFF = "fire_staff";
export const PREVIOUS_PATHS = "previous_paths";

export const NATIONAL_PASSPORT = "national_passport";
export const DRIVERS_LICENSE = "driver_license";
export const ID_CARD = "id_card";

export const FETCHING_DATA_BY_ID = "FETCHING_DATA_BY_ID";
export const SUBMITING_FORM_LOADING = "submiting-form-loading";
export const RELOAD_LIST_KEY_DRAFT_TABLE = "relaod-list-key-draft-table";
export const RELOAD_LIST_KEY_PROJECT_TABLE = "relaod-list-key-project-table";

export const PAGE_VIEW_MODE = "pageViewMode";
export const ACTIVE_TAB = "activeTab";
export const DOCUMENT_ID = "documentId";
export const TEXT_AREA_MODAL_IS_LOADING = "text-area-modal-is-loading";
export const TYPE = "type";
export const DOCUMENT_VIEW_IDENTIFIER = "documentViewIdentifier";
export const DOCUMENT_IS_FETCHING = "document-is-fetching";
export const MAKE_RELATION_MODAL_KEY_NAME = "makeRelationModal";
export const ACCESS_VIEW_RELATED_DOCUMENT = "access-view-related-document";
export const DELETE_RELATED_DOCUMENT = "delete-related-document";
export const FETCH_RELATED_DOCUMENTS = "fetch-related-documents";
export const APPLY_FILTER = "apply-filter";
export const RESET_FILTER = "reset-filter";
export const MINIMIZED_MODALS_STORE = "minimized-modals-store";
export const ERROR_STAFF_IN_BUSINESSTRIP = "error-staff-in-businesstrip";

export const COMMON_IDENTIFIERS = {
  application_submission_types: "application_submission_types",
  blank_types: "blank_types",
  case_number: "case_number",
  complaint: "complaint",
  countries: "countries",
  distinctive_marks: "distinctive_marks",
  districts_and_cities: "districts_and_cities",
  document_languages: "document_languages",
  document_subjects: "document_subjects",
  documentjournal: "documentjournal",
  documentview: "documentview",
  organization: "organization",
  regions: "regions",
  sending_and_receiving_methods: "sending_and_receiving_methods",
  tags: "tags",
  urgency_category: "urgency_category",
};

export const Space = "␣";
