import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TDataType, TGlobalDocumentState } from "Redux/globalDocument/types";

export const initialStateGlobalDocument: TGlobalDocumentState = {
  documentHistoriesById: {},
  relatedDocumentsById: {},
};

export const globalDocumentSlice = createSlice({
  initialState: initialStateGlobalDocument,
  name: "globalDocument",
  reducers: {
    addDataById: (
      state: TGlobalDocumentState,
      action: PayloadAction<{
        data: TDataType;
        id: string;
        key: keyof TGlobalDocumentState;
      }>
    ) => {
      const { data, id, key } = action.payload;
      return { ...state, [key]: { ...state[key], [id]: data } };
    },

    removeDataById: (state, action: PayloadAction<{ id: number | string; key: keyof TGlobalDocumentState }>) => {
      const { id, key } = action.payload;
      const newUpdatedData = { ...state[key as keyof TGlobalDocumentState] };
      delete newUpdatedData[id];
      return { ...state, [key]: newUpdatedData };
    },
  },
});

export const { addDataById, removeDataById } = globalDocumentSlice.actions;

export default globalDocumentSlice.reducer;
