import React, { FC, Suspense, useEffect, useState } from "react";

import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css";
import "antd/dist/antd.css";
import axios from "axios";
import "cropperjs/dist/cropper.css";
import { isEmpty } from "lodash";
import "react-perfect-scrollbar/dist/css/styles.css";

import Loader from "Components/Loader";
import { useAppDispatch, useAppSelector } from "Hooks";
import { setConfig } from "Redux/settings/settingsSlice";

/**main.css always be at the end!!!.*/
import "../assets/scss/main.scss";

const App = React.lazy(() => import("../App"));

const Initializer: FC<any> = () => {
  const config = useAppSelector(({ settings }) => settings.config);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getConfig();
  }, []);

  const getConfig = () => {
    axios.get(`${window.location.origin}/configs/config.json`).then((res) => {
      if (res.status === 200) {
        const keys = ["appUrl", "identityUrl", "apiGatewayUrl", "documentEditorScriptUrl", "germesAgentUrl"];
        for (const i in res.data) {
          if (keys.some((k) => k === i)) {
            res.data[i] = res.data[i].replace("{location.protocol}", window.location.protocol);
            res.data[i] = res.data[i].replace("{location.host}", window.location.host);
            res.data[i] = res.data[i].replace("{location.hostname}", window.location.hostname);
            res.data[i] = res.data[i].replace("{location.port}", window.location.port);
            res.data[i] = res.data[i].replace("{location.origin}", window.location.origin);
          }
        }
        dispatch(setConfig(res.data));
        localStorage.setItem("config", JSON.stringify(res.data));
        setLoading(false);
      }
    });
  };

  if (!loading && !isEmpty(config) && config?.apiGatewayUrl) {
    const url = new URL(config?.apiGatewayUrl);
    try {
      if (url.protocol === "https:" && config.notificationWithServiceWorker) {
        if ("serviceWorker" in navigator) {
          window.addEventListener("load", () => {
            navigator.serviceWorker
              .register("/sw.js")
              .then((registration) => {
                console.log("Service Worker registered with scope:", registration.scope);
              })
              .catch((error) => {
                console.log("Service Worker registration failed:", error);
              });
          });
        } else {
          // Service workers are not supported
          console.log("Service workers are not supported in this browser.");
          // You can add additional fallback behavior here
        }
      }
    } catch (error) {
      console.error("Invalid URL:", error);
    }
  }

  if (loading && isEmpty(config) && isEmpty(localStorage.getItem("config"))) return null;

  return (
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>
  );
};

export default Initializer;
