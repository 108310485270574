/**
 * prefix  api
 * method  Get | Post | Put | Delete
 * service name
 * action  name
 * examplePath  api-Get-Document-GetIncomingDocuments  // Document/Documents/GetIncomingDocuments
 */
const ApiUrl = {
  // Agreement/Agreements
  apiGetAgreementGetAgreement: "Agreement/Agreements/GetAgreement",
  apiGetAgreementGetAgreementsFilters: "Agreement/Agreements/GetAgreementsFilters",
  apiGetAgreementGetAgreementsOfLeadersFilters: "Agreement/Agreements/GetAgreementsOfLeadersFilters",
  apiGetAgreementGetAgreementStatusBlock: "Agreement/Agreements/GetAgreementStatusBlock",
  apiPostAgreementDeleteAgreement: "Agreement/Agreements/DeleteAgreement",
  apiPostAgreementSaveAgreement: "Agreement/Agreements/SaveAgreement",

  // Agreement/AgreementTemplates
  apiGetAgreementGetAgreementTemplate: "Agreement/AgreementTemplates/GetAgreementTemplate",
  apiGetAgreementGetAgreementTemplates: "Agreement/AgreementTemplates/GetAgreementTemplates",
  apiPostAgreementDeleteAgreementTemplate: "Agreement/AgreementTemplates/DeleteAgreementTemplate",
  apiPostAgreementSaveAgreementTemplate: "Agreement/AgreementTemplates/SaveAgreementTemplate",

  // Dictionary/DictionaryItem
  apiGetDictionaryByTextWithFilter: "Dictionary/DictionaryItem/GetDictionaryItemsByTextWithFilter",
  apiGetDictionaryGetAllDictionaryItemsByDictionaryIdentifier: "Dictionary/DictionaryItem/GetAllDictionaryItemsByDictionaryIdentifier",
  apiGetDictionaryGetAllDictionaryItemsByDictionaryIdentifierWithPagination:
    "Dictionary/DictionaryItem/GetAllDictionaryItemsByDictionaryIdentifierWithPagination",
  apiGetDictionaryGetDictionaryItemByDictionaryItemIdentifier: "Dictionary/DictionaryItem/GetDictionaryItemByDictionaryItemIdentifier",
  apiGetDictionaryGetDictionaryItemById: "Dictionary/DictionaryItem/GetDictionaryItemById",
  apiGetDictionaryGetDictionaryItemByIdentifier: "Dictionary/DictionaryItem/GetDictionaryItemByIdentifier",
  apiGetDictionaryGetDictionaryItemIdentifiersByDictionaryIdentifier: "Dictionary/DictionaryItem/GetDictionaryItemIdentifiersByDictionaryIdentifier",
  apiGetDictionaryGetDictionaryItems: "Dictionary/DictionaryItem/GetDictionaryItems",
  apiGetDictionaryGetDictionaryItemsByDictionaryIdentifier: "Dictionary/DictionaryItem/GetDictionaryItemsByDictionaryIdentifier",
  apiGetDictionaryGetDictionaryItemsByParentIdOrIdentifier: "Dictionary/DictionaryItem/GetDictionaryItemsByParentIdOrIdentifier",
  apiGetDictionaryGetDictionaryItemsWithTranslationsByDictionaryIdentifier:
    "Dictionary/DictionaryItem/GetDictionaryItemsWithTranslationsByDictionaryIdentifier",
  apiGetDictionaryGetDictionaryItemTranslationName: "Dictionary/DictionaryItem/GetDictionaryItemTranslationName",
  apiGetDictionaryGetDocumentLanguagesWithTranslations: "Dictionary/DictionaryItem/GetDocumentLanguagesWithTranslations",
  apiGetDictionaryItemsGetDocumentLanguages: "Dictionary/DictionaryItem/GetDocumentLanguages",
  apiGetDictionaryUpdateDictionaryItemSequence: "Dictionary/DictionaryItem/UpdateDictionaryItemSequence",
  apiPostDictionaryAddDictionaryItem: "Dictionary/DictionaryItem/AddDictionaryItem",
  apiPostDictionaryDeleteDictionaryItem: "Dictionary/DictionaryItem/DeleteDictionaryItem",
  apiPostDictionaryGetDictionaryItemsById: "Dictionary/DictionaryItem/GetDictionaryItemsById",
  apiPostDictionaryItemUpdateDictionaryItemSequence: "Dictionary/DictionaryItem/UpdateDictionaryItemSequence",
  apiPostDictionaryUpdateDictionaryItem: "Dictionary/DictionaryItem/UpdateDictionaryItem",
  apiPostUpdateDictionaryItemEntityState: "Dictionary/DictionaryItem/UpdateDictionaryItemEntityState",

  // Dictionary/DictionaryRelation
  apiGetDictionaryGetAllDictionaryRelation: "Dictionary/DictionaryRelation/GetAllDictionaryRelation",
  apiGetDictionaryRelationAddDictionaryRelation: "Dictionary/DictionaryRelation/AddDictionaryRelation",
  apiGetDictionaryRelationDeleteDictionaryRelation: "Dictionary/DictionaryRelation/DeleteDictionaryRelation",
  apiGetDictionaryRelationGetDictionaries: "Dictionary/DictionaryRelation/GetDictionaries",
  apiGetDictionaryRelationGetEntitiesByDictionaryId: "Dictionary/DictionaryRelation/GetEntitiesByDictionaryId",
  apiGetDictionaryRelationGetRelatedDictionaries: "Dictionary/DictionaryRelation/GetRelatedDictionaries",

  // Dictionary/Dictionary
  apiGetDictionaryGetDictionaryGroupItems: "Dictionary/Dictionary/GetDictionaryGroupItems",
  apiGetDictionaryGetDictionaryIdentifiers: "/Dictionary/Dictionary/GetDictionaryIdentifiers",
  apiGetDictionaryGetAllDictionaries: "Dictionary/Dictionary/GetAllDictionaries",
  apiPostDictionaryAddDictionary: "Dictionary/Dictionary/AddDictionary",
  apiPostDictionaryDeleteDictionary: "Dictionary/Dictionary/DeleteDictionary",
  apiPostDictionaryUpdateDictionary: "Dictionary/Dictionary/UpdateDictionary",

  // Document/Converters
  apiGetDocumentGetAgreementFile: "Document/Converters/GetAgreementFile",

  // Document/Setups
  apiGetDocumentFillMemberAttributes: "Document/Setups/FillMemberAttributes",
  apiPostDocumentDeleteDocument: "Document/Setups/DeleteDocument",
  apiPostDocumentDeleteResolution: "Document/Setups/DeleteResolution",
  apiPostDocumentGenerateResolutionFile: "Document/Setups/GenerateResolutionFile",

  // Document/Cases
  apiGetDocumentCasesGetDocument: "Document/Cases/GetDocument",
  apiGetDocumentCheckStaffHasAccessForDocumentInCase: "Document/Cases/CheckStaffHasAccessForDocumentInCase",
  apiGetDocumentGetCase: "Document/Cases/GetCase",
  apiGetDocumentGetCaseAccessRequest: "Document/Cases/GetCaseAccessRequest",
  apiGetDocumentGetCaseAccessRequestRelatedData: "Document/Cases/GetCaseAccessRequestRelatedData",
  apiGetDocumentGetCaseCount: "Document/Cases/GetCaseCount",
  apiGetDocumentGetCaseDocuments: "Document/Cases/GetCaseDocuments",
  apiGetDocumentGetCaseLink: "Document/Cases/GetCaseLink",
  apiGetDocumentGetCases: "Document/Cases/GetCases",
  apiGetDocumentGetCasesForDocument: "Document/Cases/GetCasesForDocument",
  apiGetDocumentGetCasesForFiltering: "Document/Cases/GetCasesForFiltering",
  apiGetDocumentGetCasesNames: "Document/Cases/GetCasesNames",
  apiGetDocumentGetDocumentInCase: "Document/Cases/GetDocumentInCase",
  apiGetDocumentGetReadyForCaseDocuments: "Document/Cases/GetReadyForCaseDocuments",
  apiGetDocumentGetDocumentsInCase: "Document/Cases/GetDocumentsInCase",
  apiGetDocumentGetMembersOfCaseDocument: "Document/Cases/GetMembersOfCaseDocument",
  apiGetDocumentGetMyCases: "Document/Cases/MyCases",
  apiGetDocumentsMyCase: "Document/Cases/GetCaseDocuments",
  apiGetReceivedCaseAccesses: "Document/Cases/GetReceivedCaseAccessRequests",
  apiGetSentCaseAccesses: "Document/Cases/GetSentCaseAccessRequests",
  apiPostChangeAccessForContentInCaseDocuments: "Document/Cases/ChangeAccessForContentInCaseDocuments",
  apiPostDocumentAddCaseAccessRequest: "Document/Cases/AddCaseAccessRequest",
  apiPostDocumentApproveCaseLink: "Document/Cases/ApproveCaseLink",
  apiPostDocumentCloseAccessToDocument: "Document/Cases/CloseAccessToDocument",
  apiPostDocumentConfirmCaseAccessRequest: "Document/Cases/ConfirmCaseAccessRequest",
  apiPostDocumentPostCasesAddCaseLink: "Document/Cases/AddCaseLink",
  apiPostDocumentRejectCaseAccessRequest: "Document/Cases/RejectCaseAccessRequest",
  apiPostDocumentRevokeCaseLink: "Document/Cases/RevokeCaseLink",
  apiPostDocumentSaveAcceptDocumentInCaseLink: "Document/Cases/AcceptDocumentInCaseLink",
  apiPostDocumentSaveCases: "Document/Cases/SaveCases",
  apiPostDocumentSaveRevokeCaseLink: "Document/Cases/RevokeCaseLink",
  apiPostDocumentSetCaseAccessRequestAsRead: "Document/Cases/SetCaseAccessRequestAsRead",
  apiPostDocumentSetCaseLinkMemberReadDate: "Document/Cases/SetCaseLinkMemberReadDate",

  // Document/Documents
  apiGetDocumentGetRemovedFromControlFilters: "Document/Documents/GetRemovedFromControlFilters",
  apiGetDocumentGetControlPointsRemovedFromControl: "Document/Documents/GetControlPointsRemovedFromControl",
  apiGetDocumentGetDocumentsRemovedFromControl: "Document/Documents/GetDocumentsRemovedFromControl",
  apiGetDocumentCheckAccessForCaseDocument: "Document/Documents/CheckAccessForCaseDocument",
  apiGetDocumentCheckDocumentAccess: "Document/Documents/CheckDocumentAccess",
  apiGetDocumentDeleteDocumentRecipient: "Document/Documents/DeleteDocumentRecipient",
  apiGetDocumentDocumentsGetDocument: "Document/Documents/GetDocument",
  apiGetDocumentGetAgreementDocuments: "Document/Documents/GetAgreementDocuments",
  apiGetDocumentGetAgreementDocumentsOfLeader: "Document/Documents/GetAgreementDocumentsOfLeader",
  apiGetDocumentGetComplaintDocument: "Document/Documents/GetComplaintDocument",
  apiGetDocumentGetComplaintDocuments: "Document/Documents/GetComplaintDocuments",
  apiGetDocumentGetComplaintDocumentsFilters: "Document/Documents/GetComplaintDocumentsFilters",
  apiGetDocumentGetComplaintDocumentsOfLeaders: "Document/Documents/GetComplaintDocumentsOfLeaders",
  apiGetDocumentGetComplaintDocumentsOfLeadersFilters: "Document/Documents/GetComplaintDocumentsOfLeadersFilters",
  apiGetDocumentGetComplaintJournalDocuments: "Document/Documents/GetComplaintJournalDocuments",
  apiGetDocumentGetComplaintJournalDocumentsFilters: "Document/Documents/GetComplaintJournalDocumentsFilters",
  apiGetDocumentGetControlDocumentControlPoints: "Document/Documents/GetControlDocumentControlPoints",
  apiGetDocumentGetControlDocuments: "Document/Documents/GetControlDocuments",
  apiGetDocumentGetControlDocumentsFilters: "Document/Documents/GetControlDocumentsFilters",
  apiGetDocumentGetControlPoint: "Document/Documents/GetControlPoint",
  apiGetDocumentGetDocumentByParentDocumentMessageId: "Document/Documents/GetDocumentByParentDocumentMessageId",
  apiGetDocumentGetDocumentHistory: "Document/Documents/GetDocumentHistory",
  apiGetDocumentGetDocumentHistoryFile: "Document/Documents/GetDocumentHistoryFile",
  apiGetDocumentGetDocumentRow: "Document/Documents/GetDocumentRow",
  apiGetDocumentGetDocumentVersions: "Document/Documents/GetDocumentVersions",
  apiGetDocumentGetDocumentsForRelation: "Document/Documents/GetDocumentsForRelation",
  apiGetDocumentGetDraftDocuments: "Document/Documents/GetDraftDocuments",
  apiGetDocumentGetDraftDocumentsFilters: "Document/Documents/GetDraftDocumentsFilters",
  apiGetDocumentGetExecutionProgressOfControlPoint: "Document/Documents/GetExecutionProgressOfControlPoint",
  apiGetDocumentGetFailedDocuments: "Document/Documents/GetFailedDocuments",
  apiGetDocumentGetForConsiderationDocuments: "Document/Documents/GetForConsiderationDocuments",
  apiGetDocumentGetForConsiderationDocumentsOfLeaders: "Document/Documents/GetForConsiderationDocumentsOfLeaders",
  apiGetDocumentGetForConsiderationDocumentsOfLeadersFilters: "Document/Documents/GetForConsiderationDocumentsOfLeadersFilters",
  apiGetDocumentGetForControlControlPointResolution: "Document/Documents/GetForControlResolution",
  apiGetDocumentGetForControlDocuments: "Document/Documents/GetForControlDocuments",
  apiGetDocumentGetForControlFilters: "Document/Documents/GetForControlFilters",
  apiGetDocumentGetForControlPoints: "Document/Documents/GetForControlPoints",
  apiGetDocumentGetForExecutionControlPoints: "Document/Documents/GetForExecutionControlPoints",
  apiGetDocumentGetForExecutionControlPointsOfLeaders: "Document/Documents/GetForExecutionControlPointsOfLeaders",
  apiGetDocumentGetForExecutionDocuments: "Document/Documents/GetForExecutionDocuments",
  apiGetDocumentGetForExecutionDocumentsOfLeaders: "Document/Documents/GetForExecutionDocumentsOfLeaders",
  apiGetDocumentGetForExecutionDocumentsOfLeadersFilter: "Document/Documents/GetForExecutionDocumentsOfLeadersFilter",
  apiGetDocumentGetForExecutionOfLeadersFilters: "Document/Documents/GetForExecutionOfLeadersFilters",
  apiGetDocumentGetForExecutionsDocumentsFilters: "Document/Documents/GetForExecutionsDocumentsFilters",
  apiGetDocumentGetForExecutionsFilters: "Document/Documents/GetForExecutionsFilters",
  apiGetDocumentGetForRevisionInformation: "Document/Documents/GetForRevisionInformation",
  apiGetDocumentGetHrDocuments: "Document/Documents/GetHrDocuments",
  apiGetDocumentGetHrProjectDocumentsFilters: "Document/Documents/GetHrProjectDocumentsFilters",
  apiGetDocumentGetHrOrderDocument: "Document/Documents/GetHrOrderDocument",
  apiGetDocumentGetHrOrderJournalDocuments: "Document/Documents/GetHrOrderJournalDocuments",
  apiGetDocumentGetInboxDocument: "Document/Documents/GetInboxDocument",
  apiGetDocumentGetInboxDocuments: "Document/Documents/GetInboxDocuments",
  apiGetDocumentGetIncomingDocument: "Document/Documents/GetIncomingDocument",
  apiGetDocumentGetIncomingJournalDocuments: "Document/Documents/GetIncomingJournalDocuments",
  apiGetDocumentGetInnerDocument: "Document/Documents/GetInnerDocument",
  apiGetDocumentGetInnerDocuments: "Document/Documents/GetInnerDocuments",
  apiGetDocumentGetInnerJournalDocuments: "Document/Documents/GetInnerJournalDocuments",
  apiGetDocumentGetIntegrationReceivedResolution: "Document/Documents/GetIntegrationReceivedResolution",
  apiGetDocumentGetOutboxDocuments: "Document/Documents/GetOutboxDocuments",
  apiGetDocumentGetOutgoingDocument: "Document/Documents/GetOutgoingDocument",
  apiGetDocumentGetOutgoingDocuments: "Document/Documents/GetOutgoingDocuments",
  apiGetDocumentGetOutgoingJournalDocuments: "Document/Documents/GetOutgoingJournalDocuments",
  apiGetDocumentGetOwnResolution: "Document/Documents/GetOwnresolution",
  apiGetDocumentGetOwnResolutionByMessageId: "Document/Documents/GetOwnResolutionByMessageId",
  apiGetDocumentGetOwnResolutionControlPoints: "Document/Documents/GetOwnResolutionControlPoints",
  apiGetDocumentGetOwnResolutionDocuments: "Document/Documents/GetOwnResolutionDocuments",
  apiGetDocumentGetOwnResolutions: "Document/Documents/GetOwnResolutions",
  apiGetDocumentGetOwnResolutionsDocuments: "Document/Documents/GetOwnResolutionsDocuments",
  apiGetDocumentGetOwnResolutionsFilters: "Document/Documents/GetOwnResolutionsFilters",
  apiGetDocumentGetProjectDocuments: "Document/Documents/GetProjectDocuments",
  apiGetDocumentGetProjectDocumentsFilters: "Document/Documents/GetProjectDocumentsFilters",
  apiGetDocumentGetReceivedDocuments: "Document/Documents/GetReceivedDocuments",
  apiGetDocumentGetRegisteredComplaintDocument: "Document/Documents/GetRegisteredComplaintDocument",
  apiGetDocumentGetRegisteredHrOrder: "Document/Documents/GetRegisteredHrOrder",
  apiGetDocumentGetRegisteredIncomingDocument: "Document/Documents/GetRegisteredIncomingDocument",
  apiGetDocumentGetRegisteredInnerDocument: "Document/Documents/GetRegisteredInnerDocument",
  apiGetDocumentGetRegisteredOutgoingDocument: "Document/Documents/GetRegisteredOutgoingDocument",
  apiGetDocumentGetHighestResolution: "Document/Documents/GetHighestResolution",
  apiGetDocumentGetRegulatoryDocument: "Document/Documents/GetRegulatoryDocument",
  apiGetDocumentGetRegisteredRegulatoryDocument: "Document/Documents/GetRegisteredRegulatoryDocument",
  apiGetDocumentGetRegulatoryJournalDocuments: "Document/Documents/GetRegulatoryJournalDocuments",
  apiGetDocumentGetRelatedDocuments: "Document/Documents/GetRelatedDocuments",
  apiGetDocumentGetResolution: "Document/Documents/GetResolution",
  apiGetDocumentGetResolutionAsController: "Document/Documents/GetResolutionAsController",
  apiGetDocumentGetResolutionAsPerformer: "Document/Documents/GetResolutionAsPerformer",
  apiGetDocumentGetResolutionAsPerformerAssistant: "Document/Documents/GetResolutionAsPerformerAssistant",
  apiGetDocumentGetResolutionByMessageId: "Document/Documents/GetResolutionByMessageId",
  apiGetDocumentGetResolutionForControl: "Document/Documents/GetResolutionForControl",
  apiGetDocumentGetResolutionsByDocumentId: "Document/Documents/GetResolutionsByDocumentId",
  apiGetDocumentGetResolutionSettings: "Document/Documents/GetResolutionSettings",
  apiGetDocumentGetResolutionsOfLeaders: "Document/Documents/GetResolutionsOfLeaders",
  apiGetDocumentGetSentDocuments: "Document/Documents/GetSentDocuments",
  apiGetDocumentGetSignersForResolutionByMember: "Document/Documents/GetSignersForResolutionByMember",
  apiGetDocumentGetSignersForResolutionByParentMessage: "Document/Documents/GetSignersForResolutionByParentMessage",
  apiGetDocumentRevokeResolution: "Document/Documents/RevokeResolution",
  apiGetDocumentSendDocumentControlPointsForExecution: "Document/Documents/SendDocumentControlPointsForExecution",
  apiGetDocumentSendHrOrderDocumentForSigning: "Document/Documents/SendHrOrderDocumentForSigning",
  apiGetDocumentSendInnerDocumentForSigning: "Document/Documents/SendInnerDocumentForSigning",
  apiGetDocumentSendOutgoingDocumentForSigning: "Document/Documents/SendOutgoingDocumentForSigning",
  apiGetDocumentSendRegulatoryDocumentForSigning: "Document/Documents/SendRegulatoryDocumentForSigning",
  apiGetDocumentSetDocumentAsRead: "Document/Documents/SetDocumentAsRead",
  apiGetDocumentSetMemberAsRead: "Document/Documents/SetMemberAsRead",
  apiGetDocumentExportDocuments: "Document/Documents/ExportDocuments",
  apiGetDocumentExportControlPoints: "Document/Documents/ExportControlPoints",
  apiGetDocumentGetDocumentOnControlExportDocuments: "Document/Documents/GetDocumentOnControlExportDocuments",
  apiGetDocumentGetDocumentOnControlExportControlPoints: "Document/Documents/GetDocumentOnControlExportControlPoints",
  apiGetDocumentGetPerformedDocuments: "Document/Documents/GetPerformedDocuments",
  apiGetDocumentGetPerformedControlPoints: "Document/Documents/GetPerformedControlPoints",
  apiGetDocumentGetPerformedFilters: "Document/Documents/GetPerformedFilters",
  apiPostDocumentAddComplaintDocumentRecipient: "Document/Documents/AddComplaintDocumentRecipient",
  apiPostDocumentAddDocumentRecipient: "Document/Documents/AddDocumentRecipient",
  apiPostDocumentAddIncomingDocumentForConsiderationRecipient: "Document/Documents/AddIncomingDocumentForConsiderationRecipient",
  apiPostDocumentAddIncomingDocumentForExecutionRecipient: "Document/Documents/AddIncomingDocumentForExecutionRecipient",
  apiPostDocumentAddInnerDocumentForConsiderationMembers: "Document/Documents/AddInnerDocumentForConsiderationMembers",
  apiPostDocumentAddOutgoingDocumentRecipient: "Document/Documents/AddOutgoingDocumentRecipient",
  apiPostDocumentAnswer: "Document/Documents/Answer",
  apiPostDocumentAttachResolutionAndSign: "Document/Documents/AttachResolutionAndSign",
  apiPostDocumentCheckForRelationalComplaintDocuments: "Document/Documents/CheckForRelationalComplaintDocuments",
  apiPostDocumentCheckIfNotificationActive: "Document/Documents/CheckIfNotificationActive",
  apiPostDocumentCreateExecutionMark: "Document/Documents/CreateExecutionMark",
  apiPostDocumentCreateInnerDocument: "Document/Documents/CreateInnerDocument",
  apiPostDocumentCreateOutgoingDocument: "Document/Documents/CreateOutgoingDocument",
  apiPostDocumentCreateRegulatoryDocument: "Document/Documents/CreateRegulatoryDocument",
  apiPostDocumentDeleteDocumentRelation: "Document/Documents/DeleteDocumentRelation",
  apiPostDocumentDeleteDraftDocument: "Document/Documents/DeleteDraftDocument",
  apiPostDocumentDeleteDraftHrDocument: "Document/Documents/DeleteDraftHrDocument",
  apiPostDocumentGetDocumentRecipients: "Document/Documents/GetDocumentRecipients",
  apiPostDocumentGetHrDocumentForPersonnelBase: "Document/Documents/GetHrDocumentForPersonnelBase",
  apiPostDocumentGetHrOrderJournalDocumentsFilters: "Document/Documents/GetHrOrderJournalDocumentsFilters",
  apiPostDocumentGetIncomingJournalDocumentsFilters: "Document/Documents/GetIncomingJournalDocumentsFilters",
  apiPostDocumentGetInnerJournalDocumentsFilters: "Document/Documents/GetInnerJournalDocumentsFilters",
  apiPostDocumentGetOutgoingJournalDocumentsFilters: "Document/Documents/GetOutgoingJournalDocumentsFilters",
  apiPostDocumentGetResolutionQRCode: "Document/Documents/GetResolutionQRCode",
  apiPostDocumentPostCreateResolutionFile: "Document/Documents/CreateResolutionFile",
  apiPostDocumentRegisterComplaintDocument: "Document/Documents/RegisterComplaintDocument",
  apiPostDocumentRegisterHrOrder: "Document/Documents/RegisterHrOrder",
  apiPostDocumentRegisterIncomingDocument: "Document/Documents/RegisterIncomingDocument",
  apiPostDocumentRegisterIncomingDocumentFromIntegration: "Document/Documents/RegisterIncomingDocumentFromIntegration",
  apiPostDocumentRegisterInnerDocument: "Document/Documents/RegisterInnerDocument",
  apiPostDocumentRegisterOutgoingDocument: "Document/Documents/RegisterOutgoingDocument",
  apiPostDocumentGetDocumentQRCode: "Document/Documents/GetDocumentQRCode",
  apiPostDocumentRejectSignDocument: "Document/Documents/RejectSignDocument",
  apiPostDocumentRevokeAgreement: "Document/Documents/RevokeAgreement",
  apiPostDocumentRevokeComplaintDocumentForConsideration: "Document/Documents/RevokeComplaintDocumentForConsideration",
  apiPostDocumentRevokeForRevisionDocument: "Document/Documents/RevokeForRevisionDocument",
  apiPostDocumentRevokeForSigningDocument: "Document/Documents/RevokeForSigningDocument",
  apiPostDocumentRevokeIncomingDocumentForConsideration: "Document/Documents/RevokeIncomingDocumentForConsideration",
  apiPostDocumentRevokeIncomingDocumentForExecution: "Document/Documents/RevokeIncomingDocumentForExecution",
  apiPostDocumentRevokeInnerDocumentForConsideration: "Document/Documents/RevokeInnerDocumentForConsideration",
  apiPostDocumentRevokeInnerDocumentForExecution: "Document/Documents/RevokeInnerDocumentForExecution",
  apiPostDocumentRevokeOutgoingDocumentForExecution: "Document/Documents/RevokeOutgoingDocumentForExecution",
  apiPostDocumentSaveDocumentControlPointsAndSendForExecution: "Document/Documents/SaveDocumentControlPointsAndSendForExecution",
  apiPostDocumentSaveDocumentControlPointsAsDraft: "Document/Documents/SaveDocumentControlPointsAsDraft",
  apiPostDocumentSaveDocumentRelations: "Document/Documents/SaveDocumentRelations",
  apiPostDocumentSaveInnerDocumentForAgreement: "Document/Documents/SaveInnerDocumentForAgreement",
  apiPostDocumentSaveInnerDocumentForSigning: "Document/Documents/SaveInnerDocumentForSigning",
  apiPostDocumentSaveOutgoingDocumentForAgreement: "Document/Documents/SaveOutgoingDocumentForAgreement",
  apiPostDocumentSaveOutgoingDocumentForSigning: "Document/Documents/SaveOutgoingDocumentForSigning",
  apiPostDocumentSaveRegulatoryDocumentForAgreement: "Document/Documents/SaveRegulatoryDocumentForAgreement",
  apiPostDocumentSaveRegulatoryDocumentForSigning: "Document/Documents/SaveRegulatoryDocumentForSigning",
  apiPostDocumentSendResolutionForSign: "Document/Documents/SendResolutionForSign",
  apiPostDocumentCreateResolution: "Document/Documents/CreateResolution",
  apiPostDocumentUpdateResolution: "Document/Documents/UpdateResolution",
  apiPostDocumentSendAgreement: "Document/Documents/SendAgreement",
  apiPostDocumentSendComplaintDocumentForConsideration: "Document/Documents/SendComplaintDocumentForConsideration",
  apiPostDocumentSendExecutionMarkForRevision: "Document/Documents/SendExecutionMarkForRevision",
  apiPostDocumentSendIncomingDocumentForConsideration: "Document/Documents/SendIncomingDocumentForConsideration",
  apiPostDocumentSendIncomingDocumentForExecution: "Document/Documents/SendIncomingDocumentForExecution",
  apiPostDocumentSendInnerDocumentForApproval: "Document/Documents/SendInnerDocumentForApproval",
  apiPostDocumentSendInnerDocumentForConsideration: "Document/Documents/SendInnerDocumentForConsideration",
  apiPostDocumentSendInnerDocumentForRevision: "Document/Documents/SendInnerDocumentForRevision",
  apiPostDocumentSendOutgoingDocumentForConsideration: "Document/Documents/SendOutgoingDocumentForConsideration",
  apiPostDocumentSendOutgoingDocumentForRevision: "Document/Documents/SendOutgoingDocumentForRevision",
  apiPostDocumentSendRegulatoryDocumentForRevision: "Document/Documents/SendRegulatoryDocumentForRevision",
  apiPostDocumentSendResolutionForRevision: "Document/Documents/SendResolutionForRevision",
  apiPostDocumentSignHrDocument: "Document/Documents/SignHrDocument",
  apiPostDocumentSignInnerDocument: "Document/Documents/SignInnerDocument",
  apiPostDocumentSignOutgoingDocument: "Document/Documents/SignOutgoingDocument",
  apiPostDocumentSignRegulatoryDocument: "Document/Documents/SignRegulatoryDocument",
  apiPostDocumentSignResolution: "Document/Documents/SignResolution",
  apiPostDocumentTakeOffControlPointFromControl: "Document/Documents/TakeOffControlPointFromControl",
  apiPostDocumentUpdateComplaintDocument: "Document/Documents/UpdateComplaintDocument",
  apiPostDocumentUpdateCreatedInnerDocument: "Document/Documents/UpdateCreatedInnerDocument",
  apiPostDocumentUpdateCreatedOutgoingDocument: "Document/Documents/UpdateCreatedOutgoingDocument",
  apiPostDocumentUpdateHrOrder: "Document/Documents/UpdateHrOrder",
  apiPostDocumentUpdateIncomingDocument: "Document/Documents/UpdateIncomingDocument",
  apiPostDocumentUpdateIncomingDocumentForIntegration: "Document/Documents/UpdateIncomingDocumentForIntegration",
  apiPostDocumentUpdateInnerDocument: "Document/Documents/UpdateInnerDocument",
  apiPostDocumentUpdateOutgoingDocument: "Document/Documents/UpdateOutgoingDocument",
  apiPostDocumentUpdateRegisteredInnerDocument: "Document/Documents/UpdateRegistredInnerDocument",
  apiPostDocumentUpdateRegulatoryDocument: "Document/Documents/UpdateRegulatoryDocument",
  apiPostDocumentWriteComment: "Document/Documents/WriteComment",

  // Document/DocumentCounter
  apiGetDocumentGetInnerDocumentStatistics: "Document/DocumentCounter/GetInnerDocumentStatistics",
  apiGetDocumentGetIncomingDocumentStatistics: "Document/DocumentCounter/GetIncomingDocumentStatistics",
  apiGetDocumentGetComplaintDocumentStatistics: "Document/DocumentCounter/GetComplaintDocumentStatistics",
  apiGetDocumentGetRegulatoryDocumentStatistics: "Document/DocumentCounter/GetRegulatoryDocumentStatistics",
  apiGetDocumentGetForConsiderationDocumentsFilters: "Document/DocumentCounter/GetForConsiderationDocumentsFilters",
  apiGetDocumentGetMenuCounters: "Document/DocumentCounter/GetMenuCounters",

  // Document/DocumentTypes
  apiPostDocumentTypeEntityState: "Document/DocumentTypes/UpdateDocumentTypeEntityState",
  apiGetDocumentGetDocumentType: "Document/DocumentTypes/GetDocumentType",
  apiGetDocumentGetDocumentTypeNameByLanguage: "Document/DocumentTypes/GetDocumentTypeNameByLanguage",
  apiGetDocumentGetDocumentTypes: "Document/DocumentTypes/GetDocumentTypes",
  apiGetDocumentGetDocumentTypesForRelation: "Document/DocumentTypes/GetDocumentTypesForRelation",
  apiGetDocumentGetReferencesDocumentTypes: "Document/DocumentTypes/GetReferencesDocumentTypes",
  apiGetDocumentGetDocumentTypesIdentifiers: "Document/DocumentTypes/GetDocumentTypesIdentifiers",
  apiGetDocumentGetTemplateDocumentTypes: "Document/DocumentTypes/GetTemplateDocumentTypes",
  apiGetDocumentRelationGetRelatedDocumentTypes: "Document/DocumentTypes/GetRelatedDocumentTypes",
  apiPostDocumentCreateDocumentType: "Document/DocumentTypes/CreateDocumentType",
  apiPostDocumentDeleteDocumentType: "Document/DocumentTypes/DeleteDocumentType",
  apiPostDocumentUpdateDocumentType: "Document/DocumentTypes/UpdateDocumentType",
  apiPostDocumentUpdateDocumentTypeSequence: "Document/DocumentTypes/UpdateDocumentTypeSequence",

  // Document/DocumentViews
  apiPostDocumentViewEntityState: "Document/DocumentViews/UpdateDocumentViewEntityState",
  apiGetDocumentGetGroupedTemplateDocumentViews: "Document/DocumentViews/GetGroupedTemplateDocumentViews",
  apiGetDocumentGetDocumentView: "Document/DocumentViews/GetDocumentView",
  apiGetDocumentGetDocumentViewByIdentifier: "/Document/DocumentViews/GetDocumentViewByIdentifier",
  apiGetDocumentGetDocumentViewNameByLanguage: "Document/DocumentViews/GetDocumentViewNameByLanguage",
  apiGetDocumentGetDocumentViews: "Document/DocumentViews/GetDocumentViews",
  apiGetDocumentGetDocumentViewsAsPagination: "Document/DocumentViews/GetDocumentViewsAsPagination",
  apiGetDocumentGetDocumentViewsByTypesIdentifiers: "Document/DocumentViews/GetDocumentViewsByTypesIdentifiers",
  apiGetDocumentGetDocumentViewsForRelation: "Document/DocumentViews/GetDocumentViewsForRelation",
  apiGetDocumentGetDocumentViewsIdentifiers: "Document/DocumentViews/GetDocumentViewsIdentifiers",
  apiGetDocumentGetDocumentViewsToCreateTemplate: "Document/DocumentViews/GetDocumentViewsToCreateTemplate",
  apiGetDocumentGetDocumentViewsWithTranslationsAsPagination: "Document/DocumentViews/GetDocumentViewsWithTranslationsAsPagination",
  apiGetDocumentRelationGetRelatedDocumentViews: "Document/DocumentViews/GetRelatedDocumentViews",
  apiPostDocumentCreateDocumentView: "Document/DocumentViews/CreateDocumentView",
  apiPostDocumentDeleteDocumentView: "Document/DocumentViews/DeleteDocumentView",
  apiPostDocumentUpdateDocumentView: "Document/DocumentViews/UpdateDocumentView",
  apiPostDocumentUpdateDocumentViewSequence: "Document/DocumentViews/UpdateDocumentViewSequence",

  // Document/DocumentJournals
  apiPostDocumentJournalEntityState: "Document/DocumentJournals/UpdateDocumentJournalEntityState",
  apiGetDocumentCheckItHasStaffingTable: "Document/DocumentJournals/CheckItHasStaffingTable",
  apiGetDocumentDeleteDocumentJournalRelate: "Document/DocumentJournals/DeleteDocumentJournalRelate",
  apiGetDocumentGetDocumentJournal: "Document/DocumentJournals/GetDocumentJournal",
  apiGetDocumentGetDocumentJournalById: "Document/DocumentJournals/GetDocumentJournalById",
  apiGetDocumentGetDocumentJournalNamesByLanguage: "Document/DocumentJournals/GetDocumentJournalNamesByLanguage",
  apiGetDocumentGetDocumentJournals: "Document/DocumentJournals/GetDocumentJournals",
  apiGetDocumentGetDocumentJournalsByType: "Document/DocumentJournals/GetDocumentJournalsByType",
  apiGetDocumentGetDocumentJournalsForRelation: "Document/DocumentJournals/GetDocumentJournalsForRelation",
  apiGetDocumentRelationGetRelatedDocumentJournals: "Document/DocumentJournals/GetRelatedDocumentJournals",
  apiPostDocumentCreateDocumentJournal: "Document/DocumentJournals/CreateDocumentJournal",
  apiPostDocumentCreateDocumentJournalRelate: "/Document/DocumentJournals/CreateDocumentJournalRelate",
  apiPostDocumentDeleteDocumentJournal: "Document/DocumentJournals/DeleteDocumentJournal",
  apiPostDocumentUpdateDocumentJournal: "Document/DocumentJournals/UpdateDocumentJournal",
  apiPostDocumentUpdateDocumentJournalByProperty: "Document/DocumentJournals/UpdateDocumentJournalByProperty",
  apiPostDocumentUpdateDocumentJournalSequence: "Document/DocumentJournals/UpdateDocumentJournalSequence",

  // Document/BusinessTrip
  apiGetDocumentGetStaffsBusinessTripPeriods: "Document/BusinessTrip/GetStaffsBusinessTripPeriods",
  apiGetDocumentGetOnBusinessTripStatusByPerson: "Document/BusinessTrip/GetOnBusinessTripStatusByPerson",
  apiGetDocumentGetStaffOnBusinessTripsHistory: "Document/BusinessTrip/GetStaffOnBusinessTripsHistory",
  apiGetDocumentGetBusinessTripDocument: "Document/BusinessTrip/GetBusinessTripDocument",
  apiPostDocumentSaveBusinessTripDocumentForAgreement: "Document/BusinessTrip/SaveBusinessTripDocumentForAgreement",
  apiPostDocumentSaveBusinessTripDocumentForSigning: "Document/BusinessTrip/SaveBusinessTripDocumentForSigning",
  apiGetDocumentGetStaffsOnBusinessTrip: "Document/BusinessTrip/GetStaffsOnBusinessTrip",
  apiPostDocumentCreateBusinessTripDocument: "Document/BusinessTrip/CreateBusinessTripDocument",
  apiPostDocumentUpdateBusinessTripDocument: "Document/BusinessTrip/UpdateBusinessTripDocument",

  // Document/DocumentFilter
  apiGetDocumentGetFilter: "Document/DocumentFilter/GetFilter",
  apiGetDocumentGetCardFolders: "Document/DocumentFilter/GetCardFolders",
  apiPostDocumentSaveFilter: "Document/DocumentFilter/SaveFilter",
  apiPostDocumentSaveCardFolder: "Document/DocumentFilter/SaveCardFolder",
  apiPostDocumentDeleteCardFolder: "Document/DocumentFilter/DeleteCardFolder",
  apiPostDocumentDeleteFilter: "Document/DocumentFilter/DeleteFilter",

  // Document/Nomenclatures
  apiGetDocumentGetActiveNomenclature: "Document/Nomenclatures/GetActiveNomenclature",
  apiGetDocumentGetNomenclature: "Document/Nomenclatures/GetNomenclature",
  apiGetDocumentGetNomenclatureDocuments: "Document/Nomenclatures/GetNomenclatureDocuments",
  apiGetDocumentGetNomenclatures: "Document/Nomenclatures/GetNomenclatures",
  apiPostDocumentActivateNomenclature: "Document/Nomenclatures/ActivateNomenclature",
  apiPostDocumentApproveNomenclature: "Document/Nomenclatures/ApproveNomenclature",
  apiPostDocumentDeleteNomenclature: "Document/Nomenclatures/DeleteNomenclature",

  // Document/TransferDocuments
  apiGetDocumentGetStaffsInformationsForTransferDocument: "Document/TransferDocuments/GetStaffsInformationsForTransferDocument",
  apiGetDocumentGetTransferDocuments: "Document/TransferDocuments/GetTransferDocuments",
  apiGetDocumentGetDocumentsForTransfer: "Document/TransferDocuments/GetDocumentsForTransfer",
  apiGetDocumentCheckItHasTransferDocument: "Document/TransferDocuments/CheckItHasTransferDocument",
  apiGetDocumentTransferDocumentsById: "Document/TransferDocuments/GetTransferDocument",
  apiGetDocumentGetTransferedDocuments: "Document/TransferDocuments/GetTransferedDocuments",
  apiPostDocumentCreateTransferDocument: "Document/TransferDocuments/CreateTransferDocument",
  apiPostDocumentUpdateTransferDocument: "Document/TransferDocuments/UpdateTransferDocument",
  apiPostDocumentDeleteTransferDocument: "Document/TransferDocuments/DeleteTransferDocument",
  apiPostDocumentAcceptTransferDocument: "Document/TransferDocuments/AcceptTransferDocument",
  apiPostDocumentRejectTransferDocument: "Document/TransferDocuments/RejectTransferDocument",

  // Document/DocumentFiles
  apiPostDocumentDeleteFile: "Document/DocumentFiles/DeleteFile",

  // Editor/Templates
  apiGetEditorImportTemplates: "Editor/Templates/ImportTemplates",
  apiGetEditorsExportTemplate: "Editor/Templates/ExportTemplate",
  apiGetTemplatesGetAllTemplates: "Editor/Templates/GetAllTemplates",
  apiPostEditorCloneTemplateFile: "Editor/Templates/CloneTemplateFile",
  apiPostEditorCreateTemplate: "Editor/Templates/CreateTemplate",
  apiPostEditorCreateTemplateFileInstance: "Editor/Templates/CreateTemplateFileInstance",
  apiPostEditorGetTemplateById: "Editor/Templates/GetTemplateById",
  apiPostEditorRemoveTemplate: "Editor/Templates/RemoveTemplate",

  // Editor/Editors
  apiGetEditorsGetConfig: "Editor/Editors/GetConfig",
  apiPostEditorCreateEmptyFile: "Editor/Editors/CreateEmptyFile",
  apiPostEditorCreateTemporaryFile: "Editor/Editors/CreateTemporaryFile",

  // Editor/Tags
  apiGetEditorsGetTags: "Editor/Tags/GetTags",

  // Editor/Viewers
  apiGetEditorViewFile: "Editor/Viewers/ViewFile",

  // Environment/Environment
  apiGetEnvironmentGetAllEnvironments: "Environment/Environment/GetAllEnvironments",
  apiGetEnvironmentGetEnvironmentById: "Environment/Environment/GetEnvironmentById",
  apiGetEnvironmentGetEnvironmentIdentifiers: "Environment/Environment/GetEnvironmentIdentifiers",
  apiGetEnvironmentGetEnvironments: "Environment/Environment/GetEnvironments",
  apiPostEnvironmentCreateEnvironment: "Environment/Environment/CreateEnvironment",
  apiPostEnvironmentDeleteEnvironment: "Environment/Environment/DeleteEnvironment",
  apiPostEnvironmentUpdateEnvironment: "Environment/Environment/UpdateEnvironment",
  apiPostEnvironmentUpdateEnvironmentSequence: "Environment/Environment/UpdateEnvironmentSequence",

  // File/Files
  apiGetFileDownloadFile: "File/Files/DownloadFile",
  apiPostFileUploadFile: "File/Files/UploadFile",
  apiGetFileGetFile: "File/Files/GetFile",
  apiGetFileGetFileWithContent: "File/Files/GetFileWithContent",
  apiGetFileViewFile: "File/Files/ViewFile",
  apiPostFileCleanAllFiles: "File/Files/CleanAllFiles",
  apiPostFileCleanFile: "File/Files/CleanFile",
  apiPostFileGetFiles: "File/Files/GetFiles",
  apiPostFileRemoveFile: "File/Files/RemoveFile",
  apiPostFileUploadFileAsPDF: "File/Files/UploadFileAsPDF",

  // Form/Forms
  apiGetFormGetForms: "Form/Forms/GetForms",
  apiPostFormCreateForm: "Form/Forms/CreateForm",

  // Grid/DynamicTableSetting
  apiGetGridGetDynamicTableSettingById: "Grid/DynamicTableSetting/GetDynamicTableSettingById",
  apiGetGridGetSettingIdentifiers: "Grid/DynamicTableSetting/GetSettingIdentifiers",
  apiGetGridGetTables: "Grid/DynamicTableSetting/GetTables",
  apiPostGridCreateTable: "Grid/DynamicTableSetting/CreateTable",
  apiPostGridDeleteDynamicTableSetting: "Grid/DynamicTableSetting/DeleteDynamicTableSetting",

  // Grid/DynamicTableColumn
  apiGetGridGetGetSettingAndColumnsBySettingIdentifier: "Grid/DynamicTableColumn/GetSettingAndColumnsBySettingIdentifier",
  apiPostGridMergeUserColumn: "Grid/DynamicTableColumn/MergeUserColumn",
  apiPostGridResetUserColumn: "Grid/DynamicTableColumn/ResetUserColumn",
  apiPostGridUpdateColumn: "Grid/DynamicTableColumn/UpdateColumn",
  apiPostGridUpdateUserWidth: "Grid/DynamicTableColumn/UpdateUserWidth",

  // License
  apiGetLicenseInformation: "/License/GetLicenseInformation",

  // Module/Modules
  apiGetModuleGetAvailableMenus: "Module/Modules/GetAvailableMenus",
  apiGetModuleGetGetModuleIcon: "Module/Modules/GetModuleIcon?moduleId=",
  apiGetModuleGetModulesWithMenus: "Module/Modules/GetModulesWithMenus",

  // News/News
  apiGetNewsGetNews: "News/News/GetNews",
  apiGetNewsGetNewsById: "News/News/GetNewsById",
  apiPostNewsCreateNews: "News/News/CreateNews",
  apiPostNewsDeleteNews: "News/News/DeleteNews",
  apiPostNewsPrintNews: "News/News/PrintNews",
  apiPostNewsUpdateNews: "News/News/UpdateNews",

  // Notification/NotificationModel
  apiGetNotificationGetAllNotificationModels: "Notification/NotificationModel/GetAllNotificationModels",
  apiGetNotificationGetAllPersonNotification: "Notification/NotificationModel/GetAllPersonNotification",
  apiGetNotificationGetNotificationSubjects: "Notification/NotificationModel/GetNotificationSubjects",
  apiPostNotificationChangeAllSoundPersonNotification: "Notification/NotificationModel/ChangeAllSoundPersonNotification",
  apiPostNotificationSetTurnAllEnabledPersonNotification: "Notification/NotificationModel/SetTurnAllEnabledPersonNotification",
  apiPostNotificationUpdateNotificationSubjectContent: "Notification/NotificationModel/UpdateNotificationSubjectContent",
  apiPostNotificationUpdatePersonNotification: "Notification/NotificationModel/UpdatePersonNotification",

  // Notification/Notifications
  apiGetNotificationGetUserNotificationById: "Notification/Notifications/GetUserNotification",
  apiGetNotificationGetUserNotifications: "Notification/Notifications/GetUserNotifications",
  apiGetNotificationGetNotificationsCount: "Notification/Notifications/GetNotificationsCount",

  //  Organization/Organizations
  apiGetOrganizationCheckOrganizationIdentifierExists: "Organization/Organizations/CheckOrganizationIdentifierExists",
  apiGetOrganizationGetOrganization: "Organization/Organizations/GetOrganization",
  apiGetOrganizationGetOrganizationAsPagination: "Organization/Organizations/GetOrganizationAsPagination",
  apiGetOrganizationGetOrganizations: "Organization/Organizations/GetOrganizations",
  apiGetOrganizationGetOrganizationsDictionary: "Organization/Organizations/GetOrganizationsDictionary",
  apiGetOrganizationGetOrganizationsForRelation: "Organization/Organizations/GetOrganizationsForRelation",
  apiGetOrganizationRelationGetRelatedOrganizations: "Organization/Organizations/GetRelatedOrganizations",
  apiPostOrganizationCreateOrganization: "Organization/Organizations/CreateOrganization",
  apiPostOrganizationCreateOrganizationWithDatabase: "Organization/Organizations/CreateOrganizationWithDatabase",
  apiPostOrganizationDeleteOrganization: "Organization/Organizations/DeleteOrganization",
  apiPostOrganizationGetOrganizationNameTranslations: "Organization/Organizations/GetOrganizationNameTranslations",
  apiPostOrganizationsUpdateOrganizationWithDatabase: "Organization/Organizations/UpdateOrganizationWithDatabase",
  apiPostOrganizationUpdateAdminPassword: "Organization/Organizations/UpdateAdminPassword",
  apiPostOrganizationUpdateOrganization: "Organization/Organizations/UpdateOrganization",
  apiPostOrganizationUpdateOrganizationEntityState: "Organization/Organizations/UpdateOrganizationEntityState",
  apiPostOrganizationUpdateOrganizationSequence: "Organization/Organizations/UpdateOrganizationSequence",
  apiPostOrganizationUpdateShortOrganization: "Organization/Organizations/UpdateShortOrganization",

  // Report/Reports
  apiGetReportCreateReport: "Report/Reports/CreateReport",
  apiGetReportGetColumns: "Report/Reports/GetColumns",
  apiGetReportGetFilters: "Report/Reports/GetFilters",
  apiGetReportGetFolders: "Report/Reports/GetFolders",
  apiGetReportGetHistories: "Report/Reports/GetHistories",

  // Security/DigitalSign
  apiGetSecurityConfirmCertificate: "Security/DigitalSign/ConfirmCertificate",
  apiGetSecurityVerifyFileSigns: "Security/DigitalSign/VerifyFileSigns",
  apiPostSecurityBlockCertificate: "Security/DigitalSign/BlockCertificate",
  apiPostSecurityCreateCertificate: "Security/DigitalSign/CreateCertificate",
  apiPostSecurityGetInformationFromCertificationFile: "Security/DigitalSign/GetInformationFromCertificationFile",
  apiPostSecurityUnBlockCertificate: "Security/DigitalSign/UnBlockCertificate",

  // Security/SecrecyLevels
  apiGetSecurityGetAllSecrecyLevelLevels: "Security/SecrecyLevels/GetAllSecrecyLevelLevels",
  apiGetSecurityGetAllSecrecyLevelsForView: "Security/SecrecyLevels/GetAllSecrecyLevelsForView",
  apiGetSecurityGetAllSecrecyLevelTranslations: "Security/SecrecyLevels/GetAllSecrecyLevelTranslations",
  apiGetSecurityGetSecrecyLevel: "Security/SecrecyLevels/GetSecrecyLevel",
  apiGetSecurityGetSecrecyLevels: "Security/SecrecyLevels/GetSecrecyLevels",
  apiGetSecurityGetSecrecyLevelsAsPagination: "Security/SecrecyLevels/GetSecrecyLevelsAsPagination",
  apiGetSecurityGetSecrecyLevelsEntity: "Security/SecrecyLevels/GetSecrecyLevelsEntity",
  apiGetSecurityGetRelatedSecrecyLevels: "Security/SecrecyLevels/GetRelatedSecrecyLevels",
  apiGetSecurityGetSecrecyLevelsWithTranslationsAsPagination: "Security/SecrecyLevels/GetSecrecyLevelsWithTranslationsAsPagination",
  apiPostSecurityCreateSecrecyLevel: "Security/SecrecyLevels/CreateSecrecyLevel",
  apiPostSecurityUpdateSecrecyLevel: "Security/SecrecyLevels/UpdateSecrecyLevel",

  // StaffManagement/Coups
  apiGetStaffManagementCanculateCoupStep: "StaffManagement/Coups/CanculateCoupStep",
  apiGetStaffManagementGetCoups: "StaffManagement/Coups/GetCoups",
  apiGetStaffManagementGetStaffProjects: "StaffManagement/Coups/GetStaffProjects",
  apiPostStaffManagementFinishMergingCoups: "StaffManagement/Coups/FinishMergingCoups",
  apiPostStaffManagementSendCoupsForSigning: "StaffManagement/Coups/SendCoupsForSigning",
  apiPostStaffManagementUpdateCoups: "StaffManagement/Coups/UpdateCoups",
  apiPostStaffManagementUpdateStaffProjectTransition: "StaffManagement/Coups/UpdateStaffProjectTransition",

  // StaffManagement/Posts
  apiGetStaffManagementCheckPostName: "StaffManagement/Posts/CheckPostName",
  apiGetStaffManagementGetPost: "StaffManagement/Posts/GetPost",
  apiGetStaffManagementGetPosts: "StaffManagement/Posts/GetPosts",
  apiGetStaffManagementGetPostsAsPagination: "StaffManagement/Posts/GetPostsAsPagination",
  apiGetStaffManagementGetPostsForRelation: "StaffManagement/Posts/GetPostsForRelation",
  apiGetStaffManagementGetPostsNameTranslations: "StaffManagement/Posts/GetPostsNameTranslations",
  apiGetStaffManagementGetRelatedPosts: "StaffManagement/Posts/GetRelatedPosts",
  apiPostStaffManagementCreatePost: "StaffManagement/Posts/CreatePost",
  apiPostStaffManagementDeletePost: "StaffManagement/Posts/DeletePost",
  apiPostStaffManagementResetSequence: "StaffManagement/Posts/ResetSequence",
  apiPostStaffManagementUpdatePost: "StaffManagement/Posts/UpdatePost",
  apiPostStaffManagementUpdatePostEntityState: "StaffManagement/Posts/UpdatePostEntityState",
  apiPostStaffManagementUpdatePostSequence: "StaffManagement/Posts/UpdatePostSequence",

  // StaffManagement/Group
  apiGetStaffManagementCreateStaffFavoriteGroup: "StaffManagement/Group/CreateStaffFavoriteGroup",
  apiGetStaffManagementGetAllGroups: "StaffManagement/Group/GetGroups",
  apiGetStaffManagementGetAllGroupsAndFavorities: "StaffManagement/Group/GetAllGroupsAndFavorities",
  apiGetStaffManagementGetAllStaffFavoriteGroups: "StaffManagement/Group/GetAllStaffFavoriteGroups",
  apiGetStaffManagementGetGroup: "StaffManagement/Group/GetGroup",
  apiGetStaffManagementGetGroupMembers: "StaffManagement/Group/GetGroupMembers",
  apiGetStaffManagementGetGroupNameByLanguage: "StaffManagement/Group/GetAllGroupTranslations",
  apiGetStaffManagementGetOwnGroups: "StaffManagement/Group/GetOwnGroups",
  apiGetStaffManagementGetOrganizationsWithFilter: "StaffManagement/Group/GetOrganizationsWithFilter",
  apiGetStaffManagementGetPhysicalPersons: "StaffManagement/Group/GetPhysicalPersons",
  apiGetStaffManagementGetRecipientsList: "StaffManagement/Group/GetRecipientsList",
  apiGetStaffManagementGetRecipientsListWithTranslations: "StaffManagement/Group/GetRecipientsListWithTranslations",
  apiGetStaffManagementGetStaffFavoriteResolutionContents: "StaffManagement/Group/GetStaffFavoriteResolutionContents",
  apiGetStaffManagementGetStaffsAndOrganizations: "StaffManagement/Group/GetStaffsAndOrganizations",
  apiGetStaffManagementGetStaffsAndOutStaffs: "StaffManagement/Group/GetStaffsAndOutStaffs",
  apiGetStaffManagementSaveStaffFavoriteDictionaryItems: "StaffManagement/Group/SaveStaffFavoriteDictionaryItems",
  apiGetStaffManagementSaveStaffFavoriteGroups: "StaffManagement/Group/SaveStaffFavoriteGroups",
  apiGetStaffManagementsGetFavorites: "StaffManagement/Group/GetFavorites",
  apiPostStaffManagementAddFavorite: "StaffManagement/Group/AddFavorite",
  apiPostStaffManagementCreateGroup: "StaffManagement/Group/CreateGroup",
  apiPostStaffManagementDeleteFavorite: "StaffManagement/Group/DeleteFavorite",
  apiPostStaffManagementDeleteGroup: "StaffManagement/Group/DeleteGroup",
  apiPostStaffManagementDeleteStaffFavoriteGroup: "StaffManagement/Group/DeleteStaffFavoriteGroup",
  apiPostStaffManagementUpdateGroup: "StaffManagement/Group/UpdateGroup",

  // StaffManagement/Roles
  apiGetStaffManagementGetAllStaffRoles: "StaffManagement/Roles/GetAllStaffRoles",
  apiGetStaffManagementGetAllStaffRoleStaffs: "StaffManagement/Roles/GetAllStaffRoleStaffs",
  apiGetStaffManagementGetStaffRole: "StaffManagement/Roles/GetStaffRole",
  apiGetStaffManagementGetRoleIdentifiers: "StaffManagement/Roles/GetRoleIdentifiers",
  apiGetStaffManagementGetStaffRoles: "StaffManagement/Roles/GetStaffRoles",
  apiGetStaffManagementRemoveStaffRole: "StaffManagement/Roles/RemoveStaffRole",
  apiPostStaffManagementSaveStaffRole: "StaffManagement/Roles/SaveStaffRole",

  // StaffManagement/Staff
  apiGetStaffManagementGetAllStaffs: "StaffManagement/Staff/GetAllStaffs",
  apiGetStaffManagementGetRecipientsWithBusinessTrips: "StaffManagement/Staff/GetRecipientsWithBusinessTrips",
  apiGetStaffManagementGetAllStaffsByDepartmentId: "StaffManagement/Staff/GetAllStaffsByDepartmentId",
  apiGetStaffManagementGetCandidatesForVacancy: "StaffManagement/Staff/GetCandidatesForVacancy",
  apiGetStaffManagementGetCurrentStaffsByDepartmentId: "StaffManagement/Staff/GetCurrentStaffsByDepartmentId",
  apiGetStaffManagementGetDepartmentsAndPostsForVacancies: "StaffManagement/Staff/GetDepartmentsAndPostsForVacancies",
  apiGetStaffManagementGetDraftStaffingTablePostsByDepartmentIdAndStaffingTableId:
    "StaffManagement/Staff/GetDraftStaffingTablePostsByDepartmentIdAndStaffingTableId",
  apiGetStaffManagementGetHiredStaffs: "StaffManagement/Staff/GetHiredStaffs",
  apiGetStaffManagementGetInControlStaffs: "StaffManagement/Staff/GetInControlStaffs",
  apiGetStaffManagementGetInSynchronizationStaffs: "StaffManagement/Staff/GetInSynchronizationStaffs",
  apiGetStaffManagementGetStaff: "StaffManagement/Staff/GetStaff",
  apiGetStaffManagementGetStaffingTablePerson: "StaffManagement/Staff/GetStaffingTablePerson",
  apiGetStaffManagementGetStaffingTablePost: "StaffManagement/Staff/GetStaffingTablePost",
  apiGetStaffManagementGetStaffingTablePosts: "StaffManagement/Staff/GetStaffingTablePosts",
  apiGetStaffManagementGetStaffingTablePostsByDepartmentIdAndStaffingTableId:
    "StaffManagement/Staff/GetStaffingTablePostsByDepartmentIdAndStaffingTableId",
  apiGetStaffManagementGetStaffRecipients: "StaffManagement/Staff/GetStaffRecipients",
  apiGetStaffManagementGetStaffs: "StaffManagement/Staff/GetStaffs",
  apiGetStaffManagementGetStaffsByDepartmentId: "StaffManagement/Staff/GetStaffsByDepartmentId",
  apiGetStaffManagementGetStaffsByDepartmentIdForControl: "StaffManagement/Staff/GetStaffsByDepartmentIdForControl",
  apiGetStaffManagementGetStaffsByDepartmentIdForSecurityPage: "StaffManagement/Staff/GetStaffsByDepartmentIdForSecurityPage",
  apiGetStaffManagementGetStaffsByFilter: "StaffManagement/Staff/GetStaffsByFilter",
  apiGetStaffManagementGetStaffsBySecrecyLevelIdAsPagination: "StaffManagement/Staff/GetStaffsBySecrecyLevelIdAsPagination",
  apiGetStaffManagementGetStaffsByStaffRoleId: "StaffManagement/Staff/GetStaffsByStaffRoleId",
  apiGetStaffManagementGetVacancies: "StaffManagement/Staff/GetVacancies",
  apiPostStaffManagementSaveStaffsForInControl: "StaffManagement/Staff/SaveStaffsForInControl",
  apiPostStaffManagementSaveStaffsForSynchronization: "StaffManagement/Staff/SaveStaffsForSynchronization",
  apiPostStaffManagementUpdateStaffSequence: "StaffManagement/Staff/UpdateStaffSequence",

  // StaffManagement/StaffingTable
  apiGetStaffManagementGetStaffingTableById: "StaffManagement/StaffingTable/GetStaffingTableById",
  apiGetStaffManagementGetCurrentStaffingTable: "StaffManagement/StaffingTable/GetCurrentStaffingTable",
  apiGetStaffManagementGetDraftStaffingTableById: "StaffManagement/StaffingTable/GetDraftStaffingTableById",
  apiGetStaffManagementGetDraftStaffingTablePostsByStaffingTableId: "StaffManagement/StaffingTable/GetDraftStaffingTablePostsByStaffingTableId",
  apiGetStaffManagementGetDraftStaffingTables: "StaffManagement/StaffingTable/GetDraftStaffingTables",
  apiGetStaffManagementGetStaffingTablePostsByStaffingTableId: "StaffManagement/StaffingTable/GetStaffingTablePostsByStaffingTableId",
  apiGetStaffManagementGetStaffingTables: "StaffManagement/StaffingTable/GetStaffingTables",
  apiGetStaffManagementStaffingTableGetStaffingTablePosts: "StaffManagement/StaffingTable/GetStaffingTablePosts",
  apiPostStaffManagementApproveStaffingTable: "StaffManagement/StaffingTable/ApproveStaffingTable",
  apiPostStaffManagementDeleteProjectStaffingTable: "StaffManagement/StaffingTable/DeleteProjectStaffingTable",
  apiPostStaffManagementCreateDraftStaffingTable: "StaffManagement/StaffingTable/CreateDraftStaffingTable",
  apiPostStaffManagementGetArchiveStaffingTable: "StaffManagement/StaffingTable/GetArchiveStaffingTable",
  apiPostStaffManagementSignCoups: "StaffManagement/StaffingTable/SignCoups",
  apiPostStaffManagementTransitionProjectStaffingTableToProject: "StaffManagement/StaffingTable/TransitionProjectStaffingTableToProject",
  apiPostStaffManagementUpdateDraftStaffingTable: "StaffManagement/StaffingTable/UpdateDraftStaffingTable",

  // StaffManagement/Surcharge
  apiGetStaffManagementGetAllSurcharge: "StaffManagement/Surcharge/GetAllSurcharge",
  apiPostStaffManagementMergeSurcharge: "StaffManagement/Surcharge/MergeSurcharge",

  // StaffManagement/StaffRoles
  apiGetStaffManagementGetAvailableMenus: "StaffManagement/StaffRoles/GetAvailableMenus",
  apiGetStaffManagementGetStaffRoleDepartments: "StaffManagement/StaffRoles/GetStaffRoleDepartments",
  apiPostStaffManagementSaveStaffRoleDepartments: "StaffManagement/StaffRoles/SaveStaffRoleDepartments",
  apiPostStaffManagementSaveStaffStaffRole: "StaffManagement/StaffRoles/SaveStaffStaffRole",

  // StaffManagement/Departments
  apiGetStaffManagementGetCurrentDepartments: "StaffManagement/Departments/GetCurrentDepartments",
  apiGetStaffManagementGetDepartmentBySearchText: "StaffManagement/Departments/GetDepartmentBySearchText",
  apiGetStaffManagementGetDepartmentsByOrganizationStructureId: "StaffManagement/Departments/GetDepartmentsByOrganizationStructureId",
  apiGetStaffManagementGetDepartmentWithFavorites: "StaffManagement/Departments/GetDepartmentWithFavorites",

  // StaffManagement/OrganizationStructure
  apiGetStaffManagementGetCurrentOrganizationStructure: "StaffManagement/OrganizationStructure/GetCurrentOrganizationStructure",
  apiGetStaffManagementGetDraftOrganizationStructure: "StaffManagement/OrganizationStructure/GetDraftOrganizationStructure",
  apiGetStaffManagementGetOrganizationArchives: "StaffManagement/OrganizationStructure/GetOrganizationArchives",
  apiGetStaffManagementGetOrganizationStructures: "StaffManagement/OrganizationStructure/GetOrganizationStructures",
  apiPostStaffManagementApproveOrganizationStructure: "StaffManagement/OrganizationStructure/ApproveOrganizationStructure",
  apiPostStaffManagementDeleteOrganizationStructure: "StaffManagement/OrganizationStructure/DeleteOrganizationStructure",
  apiPostStaffManagementUpdateOrganizationStructure: "StaffManagement/OrganizationStructure/UpdateOrganizationStructure",
  apiPostStaffManagementCreateOrganizationStructure: "StaffManagement/OrganizationStructure/CreateOrganizationStructure",
  apiPostStaffManagementChangeOrganizationStructureProjectStatus: "StaffManagement/OrganizationStructure/ChangeOrganizationStructureProjectStatus",

  // StaffManagement/StaffPersons
  apiGetStaffManagementGetGetVacancies: "StaffManagement/StaffPersons/GetVacancies",
  apiPostStaffManagementFirePerson: "StaffManagement/StaffPersons/FirePerson",
  apiPostStaffManagementCreateDocumentForTransferringStaff: "StaffManagement/StaffPersons/CreateDocumentForTransferringStaff",
  apiPostStaffManagementGetStaffProject: "StaffManagement/StaffPersons/GetStaffProject",
  apiPostStaffManagementHirePerson: "StaffManagement/StaffPersons/HirePerson",
  apiPostStaffManagementTransferPerson: "StaffManagement/StaffPersons/TransferPerson",
  apiPostStaffManagementUpdatePersonPosition: "StaffManagement/StaffPersons/UpdatePersonPosition",

  // StaffManagement/Persons
  apiGetStaffManagementGetOutsidePerson: "StaffManagement/Persons/GetOutsidePerson?",
  apiGetStaffManagementGetPerson: "StaffManagement/Persons/GetPerson",
  apiGetStaffManagementGetPersonCertificates: "StaffManagement/Persons/GetPersonCertificates",
  apiGetStaffManagementGetPersonForCandidate: "StaffManagement/Persons/GetPersonForCandidate",
  apiGetStaffManagementGetPersonForUserPage: "StaffManagement/Persons/GetPersonForUserPage",
  apiGetStaffManagementGetPersonnelBase: "StaffManagement/Persons/GetPersonnelBase",
  apiGetStaffManagementGetPersonsForCandidate: "StaffManagement/Persons/GetPersonsForCandidate",
  apiGetStaffManagementGetPersonTranslation: "StaffManagement/Persons/GetPersonTranslation",
  apiGetStaffManagementGetUsers: "StaffManagement/Persons/GetUsers",
  apiPostStaffManagementDeleteCertificate: "StaffManagement/Persons/DeleteCertificate",
  apiPostStaffManagementAddCertificate: "StaffManagement/Persons/AddCertificate",
  apiPostStaffManagementChangePersonInterfaceTheme: "StaffManagement/Persons/ChangePersonInterfaceTheme",
  apiPostStaffManagementCreatePhysicalPerson: "StaffManagement/Persons/CreatePhysicalPerson",
  apiPostStaffManagementDeletePerson: "StaffManagement/Persons/DeletePerson",
  apiPostStaffManagementPutPersonAvatarFile: "StaffManagement/Persons/PutPersonAvatarFile",
  apiPostStaffManagementSavePerson: "StaffManagement/Persons/SavePerson",
  apiPostStaffManagementUpdatePersonsSecrecyLevel: "StaffManagement/Persons/UpdatePersonsSecrecyLevel",
  apiPostStaffManagementUpdatePhysicalPerson: "StaffManagement/Persons/UpdatePhysicalPerson",

  // StaffManagement/OutStaffs
  apiGetStaffManagementGetOutStaffsAsPagination: "StaffManagement/OutStaffs/GetOutStaffsAsPagination",

  // StaffManagement/StaffRelations
  apiGetStaffManagementGetStaffRelation: "StaffManagement/StaffRelations/GetStaffRelation",
  apiGetStaffManagementGetStaffRelations: "StaffManagement/StaffRelations/GetStaffRelations",
  apiPostStaffManagementCreateStaffRelation: "StaffManagement/StaffRelations/CreateStaffRelation",
  apiPostStaffManagementDeleteStaffRelation: "StaffManagement/StaffRelations/DeleteStaffRelation",
  apiPostStaffManagementUpdateStaffRelation: "StaffManagement/StaffRelations/UpdateStaffRelation",
  apiPostStaffManagementGetStaffRelationsByIds: "StaffManagement/StaffRelations/GetStaffRelationsByIds",

  // StaffManagement/Users
  apiGetStaffManagementGetUser: "StaffManagement/Users/GetUser",
  apiGetStaffManagementGetUserInfo: "StaffManagement/Users/GetUserInfo",
  apiGetStaffManagementUpdateUser: "StaffManagement/Users/UpdateUser",
  apiGetStaffManagementUsersGetUsers: "StaffManagement/Users/GetUsers",
  apiPostStaffManagementCreateUser: "StaffManagement/Users/CreateUser",
  apiPostUserDeleteUser: "StaffManagement/Users/DeleteUser",
  apiPostUserDisableLockout: "StaffManagement/Users/DisableLockout",
  apiPostUserEnableLockout: "StaffManagement/Users/EnableLockout",

  // StaffManagement/Contact
  apiPostStaffManagementMergePersonContact: "StaffManagement/Contact/MergePersonContact",
  apiPostStaffManagementSaveCandidates: "StaffManagement/Contact/SaveCandidates",

  // Task/Comments
  apiGetTaskAddComment: "Task/Comments/AddComment",
  apiGetTaskGetComments: "Task/Comments/GetComments",

  // Task/Tasks
  apiGetTaskDeleteTask: "Task/Tasks/DeleteTask",
  apiGetTaskGetArchivedTasks: "Task/Tasks/GetArchivedTasks",
  apiGetTaskGetRelatedTasks: "Task/Tasks/GetRelatedTasks",
  apiGetTaskGetSubTasks: "Task/Tasks/GetSubTasks",
  apiGetTaskGetTask: "Task/Tasks/GetTask",
  apiGetTaskGetTaskFiles: "Task/Tasks/GetTaskFiles",
  apiGetTaskGetTasks: "Task/Tasks/GetTasks",
  apiGetTaskGetTasksAsTable: "Task/Tasks/GetTasksAsTable",
  apiGetTaskPrintArchivedTasks: "Task/Tasks/PrintArchivedTasks",
  apiGetTaskPrintTasks: "Task/Tasks/PrintTasks",
  apiPostTaskAddRelationTask: "Task/Tasks/AddRelationTask",
  apiPostTaskAddTask: "Task/Tasks/AddTask",
  apiPostTaskAddTaskFile: "Task/Tasks/AddTaskFile",
  apiPostTaskAddTaskToArchive: "Task/Tasks/AddTaskToArchive",
  apiPostTaskDeleteRelatedTask: "Task/Tasks/DeleteRelatedTask",
  apiPostTaskDeleteTaskFile: "Task/Tasks/DeleteTaskFile",
  apiPostTaskRestoreTaskFromArchive: "Task/Tasks/RestoreTaskFromArchive",
  apiPostTaskUpdateTask: "Task/Tasks/UpdateTask",
  apiPostTaskUpdateTaskSequence: "Task/Tasks/UpdateTaskSequence",
  apiPostTaskSetTaskMemberAsRead: "Task/Tasks/SetTaskMemberAsRead",

  // Task/Users
  apiGetTaskGetAvatarFile: "Task/Users/GetAvatarFile",
  apiGetUsersGetUsers: "Task/Users/GetUsers",

  // Task/CheckLists
  apiGetTaskGetCheckLists: "Task/CheckLists/GetCheckLists",
  apiPostTaskDeleteCheckList: "Task/CheckLists/DeleteCheckList",
  apiPostTaskCreateCheckList: "Task/CheckLists/CreateCheckList",
  apiPostTaskUpdateCheckList: "Task/CheckLists/UpdateCheckList",

  // Task/Columns
  apiGetTaskGetColumns: "Task/Columns/GetColumns",
  apiPostTaskAddColumn: "Task/Columns/AddColumn",
  apiPostTaskDeleteColumn: "Task/Columns/DeleteColumn",
  apiPostTaskUpdateColumn: "Task/Columns/UpdateColumn",
  apiPostTaskUpdateColumnSequence: "Task/Columns/UpdateColumnSequence",

  // Task/Priorities
  apiGetTaskGetPriorities: "Task/Priorities/GetPriorities",
  apiPostTaskCreatePriority: "Task/Priorities/CreatePriority",
  apiPostTaskDeletePriority: "Task/Priorities/DeletePriority",
  apiPostTaskUpdatePriority: "Task/Priorities/UpdatePriority",
  apiPostTaskUpdatePrioritySequence: "Task/Priorities/UpdatePrioritySequence",

  // Task/Projects
  apiGetTaskGetProjects: "Task/Projects/GetProjects",
  apiPostTaskCreateProject: "Task/Projects/CreateProject",
  apiPostTaskDeleteProject: "Task/Projects/DeleteProject",
  apiPostTaskUpdateProject: "Task/Projects/UpdateProject",
  apiPostTaskUpdateProjectSequence: "Task/Projects/UpdateProjectSequence",

  // Task/CheckListElements
  apiPostTaskCreateCheckListElement: "Task/CheckListElements/CreateCheckListElement",
  apiPostTaskDeleteCheckListElement: "Task/CheckListElements/DeleteCheckListElement",
  apiPostTaskUpdateCheckListElement: "Task/CheckListElements/UpdateCheckListElement",

  // GermesAgent
  apiGetGermesAgentDecryptFileAndDownload: "GermesAgent/DecryptFileAndDownload",
  apiPostAddAccessToFile: "GermesAgent/AddAccessToFile",
  apiPostGermesAgentEncryptFileAndUpload: "GermesAgent/EncryptFileAndUpload",

  // UserSessions
  apiGetStaffGetOnlineUsers: "UserSessions/GetOnlineUsers",

  // UserManagement
  apiGetUserManagementCheckUsernameExists: "/api/UserManagement/CheckUsernameExists",
  apiPostUserManagementChangePassword: "/api/UserManagement/ChangePassword",
  apiPostUserManagementCheckAdminPassword: "/api/UserManagement/CheckAdminPassword",
  apiPostUserManagementDeleteUser: "/api/UserManagement/DeleteUser",
  apiPostUserManagementDisableLockout: "/api/UserManagement/DisableLockout",
  apiPostUserManagementEnableLockout: "/api/UserManagement/EnableLockout",
  apiPostUserManagementUpdateUserLocalization: "/api/UserManagement/UpdateUserLocalization",
  apiPostUserManagementChangeUserPassword: "/api/UserManagement/ChangeUserPassword",

  // Tokens
  AddInvalidToken: "Tokens/AddInvalidToken",

  /*SignalR hubs url*/
  RemoveNotification: "RemoveNotification",
  SetAsRead: "SetAsRead",
  SetAllNotificationsAsRead: "SetAllNotificationsAsRead",

  /*SignalR connection event names*/
  OnGetNewNotification: "OnGetNewNotification",
  OnNotificationRead: "OnNotificationRead",
  OnNotificationRemove: "OnNotificationRemove",
  OnNotificationsRead: "OnNotificationsRead",
  OnBaikTokenRemoved: "OnBaikTokenRemoved",
};

export default ApiUrl;
