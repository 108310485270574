import { initReactI18next } from "react-i18next";

import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

import translationRu from "../../public/locales/ru_RU/translation.json";
import translationUzCyrl from "../../public/locales/uz_Cyrl_UZ/translation.json";
import translationUzLat from "../../public/locales/uz_UZ/translation.json";
import { LANGUAGE_RU, LANGUAGE_UZ, LANGUAGE_UZ_CYRL } from "../config/settings-config";

const fallbackLng = [LANGUAGE_RU];
const availableLanguages = [LANGUAGE_RU, LANGUAGE_UZ_CYRL, LANGUAGE_UZ];
i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    cache: ["cookie"],
    detection: {
      lookupCookie: "locale",
      lookupLocalStorage: "locale",
      lookupQuerystring: "lng",
      order: ["localStorage"],
    },
    fallbackLng: fallbackLng,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      [LANGUAGE_RU]: { translation: translationRu },
      [LANGUAGE_UZ]: { translation: translationUzLat },
      [LANGUAGE_UZ_CYRL]: { translation: translationUzCyrl },
    },
    supportedLngs: availableLanguages,
  });

export default i18next;
