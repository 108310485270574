import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

import { TConfirmationModal } from "Components/ConfirmationModal";
import { MINIMIZED_MODALS_STORE } from "Constants/common";
import { TMDocumentTypeEnum } from "Models/Enums";
import { TMEnumTableIdentifier } from "Models/NetworkModels/TMGridService";
import { TEnumSigningProgress } from "Types";
import { loadFromLocalStorage } from "Utils/Functions";
import { GermesAgentConnection } from "Utils/GermesAgent";

import { TAllTypeDocumentViewModal, TDocumentStatistics, TEvent, TGlobalState, TMinimizedModal, TMinimizedModalTypeEnum, TTableData } from "./types";

type OptionalTTableData = Partial<Omit<TTableData, "tableIdentifier">> & {
  tableIdentifier: TMEnumTableIdentifier;
};
const agentNotEnabledOrDisconnected = sessionStorage.getItem("agentNotEnabledOrDisconnected");

export const initialStateGlobal: TGlobalState = {
  agentNotEnabledOrDisconnected: agentNotEnabledOrDisconnected === null ? null : agentNotEnabledOrDisconnected === "false" ? false : true,
  agent: null,
  allTypeDocumentViewModal: { documentId: null, documentType: null, visible: false },
  confirmationModal: { visible: false },
  events: [],
  minimizedModalDragging: false,
  minimizedModals: loadFromLocalStorage(MINIMIZED_MODALS_STORE) || [],
  modal: {},
  parentElementKey: 0,
  signingProgressModal: {
    visible: false,
  },
};

export const globalSlice = createSlice({
  initialState: initialStateGlobal,
  name: "global",
  reducers: {
    addMinimizedModal: (state, action: PayloadAction<TMinimizedModal>) => {
      if (action.payload.type === TMinimizedModalTypeEnum.DocumentView) {
        const { documentInfo } = action.payload;
        const existingModalIndex = state.minimizedModals.findIndex(
          (modal) => modal.type === TMinimizedModalTypeEnum.DocumentView && modal.documentInfo.documentId === documentInfo.documentId
        );

        // If the modal with the same documentId already exists, update its data
        if (existingModalIndex !== -1) {
          const updatedModals = state.minimizedModals.slice();
          updatedModals[existingModalIndex] = {
            ...updatedModals[existingModalIndex],
            ...action.payload,
          };

          return {
            ...state,
            minimizedModals: updatedModals,
          };
        }
      }

      // If the modal does not exist, add it to the state
      return {
        ...state,
        minimizedModals: [...state.minimizedModals, action.payload],
      };
    },
    addModalProperties: (
      state,
      action: PayloadAction<{
        key: string;
        objValue: any;
      }>
    ) => {
      return {
        ...state,
        modal: {
          ...state.modal,
          [action?.payload?.key]: action?.payload?.objValue,
        },
      };
    },
    closeConfirmationModal: (state) => {
      return { ...state, confirmationModal: { visible: false } };
    },
    closeSigningProgressModal: (
      state,
      action: PayloadAction<{
        visible: boolean;
      }>
    ) => {
      return { ...state, signingProgressModal: { ...action.payload } };
    },
    removeMinimizedModalByDocumentId: (state, action: PayloadAction<string>) => {
      const documentId = action.payload;
      return {
        ...state,
        minimizedModals: state.minimizedModals.filter(
          (modal) => modal.type !== TMinimizedModalTypeEnum.DocumentView || (modal.documentInfo && modal.documentInfo.documentId !== documentId)
        ),
      };
    },
    removeModalProperties: (state, action: PayloadAction<string>) => {
      delete state?.modal?.[action?.payload];
      return state;
    },
    removeTableDataByTableIdentifier: (state, action: PayloadAction<TMEnumTableIdentifier>) => {
      const newTableData = cloneDeep(state.tableData);
      if (newTableData && newTableData?.[action.payload]) {
        delete newTableData[action.payload];
      }
      return { ...state, tableData: newTableData };
    },

    setAgent: (state, action: PayloadAction<GermesAgentConnection | null>) => {
      return { ...state, agent: action.payload };
    },
    setAllTypeDocumentViewModal: (state, action: PayloadAction<TAllTypeDocumentViewModal>) => {
      return { ...state, allTypeDocumentViewModal: action.payload };
    },
    setAppRegisterId: (state, action: PayloadAction<string>) => {
      return { ...state, appRegisterId: action.payload };
    },
    setConfirmationModal: (state, action: PayloadAction<TConfirmationModal>) => {
      return { ...state, confirmationModal: action.payload };
    },
    setDocumentStatistics: (state, action: PayloadAction<TDocumentStatistics>) => {
      return {
        ...state,
        documentStatistics: action.payload,
      };
    },
    setEvent: (state, action: PayloadAction<TEvent>) => {
      if (action.payload.status === "active") {
        if (!state.events.some((e) => e.eventId === action.payload.eventId)) {
          return {
            ...state,
            events: [...state.events, action.payload],
          };
        } else {
          return {
            ...state,
          };
        }
      }
      if (action.payload.status === "progress") {
        const updateEvents = state.events.map((item) => {
          if (item.eventName === action.payload.eventName && item.eventId === action.payload.eventId) {
            item = action.payload;
          }
          return item;
        });
        return {
          ...state,
          events: updateEvents,
        };
      }
      return {
        ...state,
        events: state.events.filter((item) => item.eventName !== action.payload.eventName && item.eventId !== action.payload.eventId),
      };
    },
    setMinimizedDocuments: (state, action: PayloadAction<TMinimizedModal[]>) => {
      return {
        ...state,
        minimizedModals: action.payload,
      };
    },
    setMinimizedModalDragging: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        minimizedModalDragging: action.payload,
      };
    },
    setParentElementKey: (state) => {
      return { ...state, parentElementKey: state.parentElementKey + 1 };
    },
    setSigningProgressModal: (
      state,
      action: PayloadAction<{
        activeStep?: null | TEnumSigningProgress;
        activeSteps?: TEnumSigningProgress[];
        activeStepState?: boolean;
        visible?: boolean;
      }>
    ) => {
      return { ...state, signingProgressModal: { ...state.signingProgressModal, ...action.payload } };
    },
    setTableData: (state, action: PayloadAction<OptionalTTableData>) => {
      return {
        ...state,
        tableData: {
          ...state.tableData,
          [action.payload.tableIdentifier]: { ...((state.tableData?.[action.payload.tableIdentifier] || {}) as TTableData), ...action.payload },
        },
      };
    },
    updateDocumentStatisticsWithocumentType: (
      state,
      action: PayloadAction<{ data: Array<{ color: string; label: string; value: number }>; documentType: TMDocumentTypeEnum }>
    ) => {
      return {
        ...state,
        documentStatistics: {
          ...(state.documentStatistics || ({} as TDocumentStatistics)),
          [action.payload.documentType]: action.payload.data,
        },
      };
    },
    setAgentNotEnabledOrDisconnected: (state, action: PayloadAction<boolean>) => {
      sessionStorage.setItem("agentNotEnabledOrDisconnected", String(action.payload));
      return {
        ...state,
        agentNotEnabledOrDisconnected: action.payload,
      };
    },
  },
});

export const {
  addMinimizedModal,
  addModalProperties,
  closeConfirmationModal,
  closeSigningProgressModal,
  removeMinimizedModalByDocumentId,
  removeModalProperties,
  removeTableDataByTableIdentifier,
  setAgent,
  setAllTypeDocumentViewModal,
  setAppRegisterId,
  setConfirmationModal,
  setDocumentStatistics,
  setEvent,
  setMinimizedDocuments,
  setMinimizedModalDragging,
  setParentElementKey,
  setSigningProgressModal,
  setTableData,
  updateDocumentStatisticsWithocumentType,
  setAgentNotEnabledOrDisconnected,
} = globalSlice.actions;

export default globalSlice.reducer;
