import { combineReducers } from "redux";

import globalReducer from "./global/globalSlice";
import globalDocumentReducer from "./globalDocument/globalDocumentSlice";
import notificationsReducer from "./notifications/notificationsSlice";
import settingsReducer from "./settings/settingsSlice";
import userReducer from "./user/userSlice";

const createRootReducer = combineReducers({
  global: globalReducer,
  globalDocument: globalDocumentReducer,
  notifications: notificationsReducer,
  settings: settingsReducer,
  user: userReducer,
});

export default createRootReducer;
