import { User, UserManager, UserManagerSettings, WebStorageStateStore } from "oidc-client-ts";

import { store } from "Redux/store";
import { setUserProfile } from "Redux/user/userSlice";

import { sendTokenToInvalidTokensList, serviceWorkerPostMessage } from "./Functions";

export interface IAuthService {
  getUser(): Promise<null | User>;

  login(): Promise<void>;

  logout(): Promise<void>;

  renewToken(): void;

  signinCallback(): Promise<User>;

  signinSilentCallback(): Promise<User | void>;

  signinSilentCallback(): Promise<undefined | User | void>;

  startSilentRenew(): void;
}

class AuthService implements IAuthService {
  public userManager: UserManager;

  constructor() {
    const config = JSON.parse(localStorage.getItem("config") || JSON.stringify(""));
    const settings: UserManagerSettings = {
      authority: config.identityUrl + "/",
      automaticSilentRenew: false,
      client_id: "GermesWeb",
      loadUserInfo: true,
      post_logout_redirect_uri: config.appUrl ? config.appUrl : window.location.origin,
      redirect_uri: `${config.appUrl ? config.appUrl : window.location.origin}/callback`,
      response_type: "code",
      scope: "openid profile email offline_access phone address " + config.identityScopes,
      silent_redirect_uri: `${config.appUrl ? config.appUrl : window.location.origin}/silent-renew`,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    };
    this.userManager = new UserManager(settings);
    this.userManager.events.addUserLoaded(() => console.log("User loaded"));
    this.userManager.events.addUserUnloaded(() => console.log("User unloaded"));
    this.userManager.events.addAccessTokenExpiring(() => console.log("User token expiring"));
    this.userManager.events.addAccessTokenExpired(() => {
      this.renewToken();
    });
    this.userManager.events.addUserSignedOut(() => console.log("User signed out"));
  }

  public getUser(): Promise<null | User> {
    return this.userManager.getUser();
  }

  public login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  public logout(): Promise<void> {
    sessionStorage.removeItem("agentNotEnabledOrDisconnected");
    if (store.getState().settings.config.agentConnectToApiHub) {
      return sendTokenToInvalidTokensList()
        .then(() => {})
        .catch((err) => console.log(`${err} AddInvalidToken`))
        .finally(() => this.userManager.signoutRedirect());
    } else {
      return this.userManager.signoutRedirect();
    }
  }

  public renewToken(): void {
    const that = this;
    const permission = void this.userManager
      .signinSilent()
      .then(function (user) {
        if (user) {
          store.dispatch(setUserProfile(user));
          if (store.getState().settings.config.notificationWithServiceWorker) {
            serviceWorkerPostMessage({ payload: user?.access_token, type: "SetToken", userId: user.profile.sub });
          }
        } else {
          return false;
        }
      })
      .catch(function (e) {
        console.error("Renew Token Failed.", e);
        that.logout();
      });
    if (permission === false) {
      this.logout();
    }
  }

  public signinCallback(): Promise<User> {
    return this.userManager.signinRedirectCallback();
  }

  public signinSilentCallback(): Promise<undefined | User | void> {
    return this.userManager.signinSilentCallback().catch((error) => {
      console.error(error);
    });
  }

  public startSilentRenew(): void {
    this.userManager.startSilentRenew();
  }
}

export default AuthService;
